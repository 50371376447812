import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";

import VoucherErrorCodes from './VoucherErrorCodes';
import L from '../util/L10n';
import DetectLocale from '../util/DetectLocale';

const useStyles = makeStyles({
    p: {
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        textAlign: 'justify',
        hyphens: 'auto',
        maxWidth: 500,
    },
    pn: {
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        textAlign: 'justify',
        hyphens: 'auto',
        maxWidth: 500,
        padding: 10,
    },
    pwarn: {
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        textAlign: 'justify',
        hyphens: 'auto',
        maxWidth: 500,
        backgroundColor: '#B3002D',
        color: '#ffffff',
        fontWeight: 500,
        padding: 10,
    },
    table: {
        border: 'solid 1px black',
        borderCollapse: 'collapse',
    },
    tableRow: {

    },
    tableHeader: {
        border: 'solid 1px black',
        textAlign: 'left',
        padding: '0 6px',
    },
    tableData: {
        border: 'solid 1px black',
        textAlign: 'right',
        padding: '0 6px',
    },
});

class VoucherInfo extends PureComponent {
    render() {
        const { classes, userLang, action, info } = this.props;
        if (info && info.state === 'success' && info.info &&
            info.info.voucherEndDate) {
            let {
                voucherEndDate, countMax, countLeft, voucherLeft, voucherUsed,
            } = info.info;
            if (voucherEndDate) {
                const locale = userLang || DetectLocale.getPreferredLocale();
                const dateOptions = {
                    year: 'numeric', month: 'long', day: 'numeric',
                };
                const timeOptions = { hour: 'numeric', minute: 'numeric' };
                const date = new Date(voucherEndDate)
                voucherEndDate = date.toLocaleDateString(locale, dateOptions) +
                    ' ' + date.toLocaleTimeString(locale, timeOptions);
            } else {
                voucherEndDate = L.t('qrInfoVoucherEndDateNotSet', userLang);
            }
            if (!countMax && countMax !== 0) {
                countMax = L.t('qrInfoCountMaxNotSet', userLang);
            }
            if (!countLeft && countLeft !== 0) {
                countLeft = L.t('qrInfoCountMaxNotSet', userLang);
            }
            return (
                <table className={classes.table}>
                    <tbody>
                        <tr className={classes.tableRow}>
                            <Typography
                                variant='body1'
                                component='th'
                                className={classes.tableHeader}
                            >
                                {L.t('qrInfoVoucherAction')}
                            </Typography>
                            <Typography
                                variant='body1'
                                component='td'
                                className={classes.tableData}
                            >
                                {action}
                            </Typography>
                        </tr>
                        <tr className={classes.tableRow}>
                            <Typography
                                variant='body1'
                                component='th'
                                className={classes.tableHeader}
                            >
                                {L.t('qrInfoVoucherEndDate')}
                            </Typography>
                            <Typography
                                variant='body1'
                                component='td'
                                className={classes.tableData}
                            >
                                {voucherEndDate}
                            </Typography>
                        </tr>
                        <tr className={classes.tableRow}>
                            <Typography
                                variant='body1'
                                component='th'
                                className={classes.tableHeader}
                            >
                                {L.t('qrInfoCountMax')}
                            </Typography>
                            <Typography
                                variant='body1'
                                component='td'
                                className={classes.tableData}
                            >
                                {countMax}
                            </Typography>
                        </tr>
                        <tr className={classes.tableRow}>
                            <Typography
                                variant='body1'
                                component='th'
                                className={classes.tableHeader}
                            >
                                {L.t('qrInfoCountLeft')}
                            </Typography>
                            <Typography
                                variant='body1'
                                component='td'
                                className={classes.tableData}
                            >
                                {countLeft}
                            </Typography>
                        </tr>
                        <tr className={classes.tableRow}>
                            <Typography
                                variant='body1'
                                component='th'
                                className={classes.tableHeader}
                            >
                                {L.t('qrInfoVoucherLeft')}
                            </Typography>
                            <Typography
                                variant='body1'
                                component='td'
                                className={classes.tableData}
                            >
                                {voucherLeft}
                            </Typography>
                        </tr>
                        <tr className={classes.tableRow}>
                            <Typography
                                variant='body1'
                                component='th'
                                className={classes.tableHeader}
                            >
                                {L.t('qrInfoVoucherUsed')}
                            </Typography>
                            <Typography
                                variant='body1'
                                component='td'
                                className={classes.tableData}
                            >
                                {voucherUsed}
                            </Typography>
                        </tr>
                    </tbody>
                </table>

            );
        } else {
            const errorLabel = info ?
                VoucherErrorCodes.getLabel(info.errormessage) :
                'qrErrorUnknownError';
            const errorMessage = L.t(errorLabel, userLang);
            const className = errorLabel === 'qrErrorCodeUsed' ||
                errorLabel === 'qrErrorCodeNotFound' ||
                errorLabel === 'qrErrorActionEnded' ?
                classes.pwarn : classes.pn;
            return (
                <Typography variant='body1' component='p' className={className}>
                    {errorMessage}
                </Typography>
            );
        }
    }
}

VoucherInfo.propTypes = {
    userLang: PropTypes.string,
    action: PropTypes.string.isRequired,
    info: PropTypes.object.isRequired,
};

export default function _VoucherInfo(props) {
    const classes = useStyles();
    return (
        <VoucherInfo
            {...props}
            classes={classes}
        />
    );
}
