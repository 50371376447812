export default class DefaultImage {
    static get(imageType) {
        const inlineImage = 'data:image/jpeg;base64,';
        const imageData720x540 = inlineImage +
            'iVBORw0KGgoAAAANSUhEUgAAAtAAAAIcAQMAAADlswsQAAAABGdBTUEAALGPC/xh' +
            'BQAAAAFzUkdCAK7OHOkAAAADUExURdDQ0C0YHyEAAABGSURBVHja7cExAQAAAMKg' +
            '9U9tDB+gAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA' +
            'AAAAAADgY7/0AAEUT5NEAAAAAElFTkSuQmCC';
        const imageData720x114 = inlineImage +
            'iVBORw0KGgoAAAANSUhEUgAAAtAAAAByAQMAAABA0nQTAAAABGdBTUEAALGPC/xh' +
            'BQAAAAFzUkdCAK7OHOkAAAADUExURdDQ0C0YHyEAAAAhSURBVGje7cExAQAAAMKg' +
            '9U9tB2+gAAAAAAAAAAAAAB4DKIYAAZfGE+MAAAAASUVORK5CYII=';
        return ('banner' === imageType ? imageData720x114 : imageData720x540);
    }

    static getBanner() {
        const inlineImage = 'data:image/png;base64,';
        return inlineImage +
            'iVBORw0KGgoAAAANSUhEUgAABQAAAAPAAQMAAABQEkY6AAAABlBMVEVHcEzQ0NAV' +
            'SKaqAAAAAXRSTlMAQObYZgAAAQ9JREFUeNrtzjERAAAIAKHvX1pbeA6QgAIAAAAA' +
            'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA' +
            'AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA' +
            'AAAAAAAAAAAAAAAAAAAAAACgmucEBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUF' +
            'BQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUF' +
            'BQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUF' +
            'BY8tbEbJFJXeJugAAAAASUVORK5CYII=';
    }

    static getWH(imageType, width, height) {
        if (width && height) {
            if (width * 3 === height * 4) {
                return this.get('nobanner');
            } else if (width * 55 === height * 347) {
                return this.get('banner');
            }
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            const context = canvas.getContext('2d');
            context.fillStyle = '#d0d0d0';
            context.fillRect(0, 0, canvas.width, canvas.height);
            return canvas.toDataURL('image/png');
        } else {
            return this.get(imageType);
        }
    }
}
