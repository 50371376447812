import React from "react";

import Grid from "@material-ui/core/Grid";

export default class FullBuilder {
    static build(cards, pos, userLang) {
        let grids = [];
        let cardno = 0;
        for (let card of cards) {
            const single =
                <Grid
                    item xs={12}
                    key={'gridpos' + pos + 'grids' + cardno + userLang}>
                    {card}
                </Grid>;
            const double =
                <Grid
                    item xs={6}
                    key={'gridpos' + pos + 'gridd' + cardno + userLang}>
                    {card}
                </Grid>;
            grids.push([single, double])
            cardno++;
        }
        return grids;
    }
}
