import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ButtonBase from '@material-ui/core/ButtonBase';

export default class MaybeLink extends PureComponent {
    render() {
        const {
            children, onClick, externalLink, className, to, target,
            externalTracking, onFocusVisible, onFocus, onBlur, tabIndex,
        } = this.props;
        if (onClick) {
            return (
                <ButtonBase
                    component='div'
                    onClick={onClick}
                    className={className}
                    onFocusVisible={onFocusVisible}
                    onFocus={onFocus}
                    onBlur={onBlur}
                >
                    {children}
                </ButtonBase>
            );
        } else if (externalLink) {
            return (
                <ButtonBase
                    component='a'
                    href={externalLink}
                    className={className}
                    target={target}
                    rel='noopener noreferrer'
                    onClick={externalTracking}
                    onFocusVisible={onFocusVisible}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    tabIndex={tabIndex}
                >
                    {children}
                </ButtonBase>
            );
        } else if (to) {
            return (
                <ButtonBase
                    component={Link}
                    to={to}
                    className={className}
                    onFocusVisible={onFocusVisible}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    tabIndex={tabIndex}
                >
                    {children}
                </ButtonBase>
            );
        } else {
            return (
                <Fragment>
                    {children}
                </Fragment>
            );
        }
    }
}

MaybeLink.propTypes = {
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    externalLink: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    target: PropTypes.string,
    externalTracking: PropTypes.func,
    onFocusVisible: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    tabIndex: PropTypes.number,
};

MaybeLink.defaultProps = {
    target: '_blank',
};
