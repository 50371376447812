import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';

import InfosCardItem from './InfosCardItem';
import L from "../util/L10n";
import TextUtil from '../util/TextUtil';
import PressReleaseItem from './PressReleaseItem';

const useStyles = makeStyles((theme) => ({
    infosCard: {},
    title: {
        color: '#000000',
        marginBottom: 3,
    },
    divider: {
        height: 2,
        backgroundColor: '#f3f1f3',
        marginBottom: 8,
    },
    list: {
        marginBottom: 6,
    },
}));

class InfosCard extends PureComponent {
    render() {
        const { classes, data, userLang, pressReleases } = this.props;
        const pressReleaseItems = pressReleases ?
            pressReleases.map((item, index) =>
                <PressReleaseItem
                    key={item.title}
                    index={index}
                    pressRelease={item}
                    userLang={userLang}
                />
            ) : null;
        const title = TextUtil.isEmptyText(data.title) ?
            L.t('infosTitle', userLang) : data.title;
        return (
            <div className={classes.quoteCard}>
                <Typography
                    variant='subtitle2'
                    component='h2'
                    classes={{ root: classes.title }}
                >
                    {title}
                </Typography>
                <Divider
                    classes={{ root: classes.divider }}
                />
                <List disablePadding={true} className={classes.list}>
                    {pressReleaseItems}
                    <InfosCardItem
                        title={data.label1}
                        url={data.url1}
                        type={data.url1Type}
                        userLang={userLang}
                    />
                    <InfosCardItem
                        title={data.label2}
                        url={data.url2}
                        type={data.url2Type}
                        userLang={userLang}
                    />
                    <InfosCardItem
                        title={data.label3}
                        url={data.url3}
                        type={data.url3Type}
                        userLang={userLang}
                    />
                    <InfosCardItem
                        title={data.label4}
                        url={data.url4}
                        type={data.url4Type}
                        userLang={userLang}
                    />
                    <InfosCardItem
                        title={data.label5}
                        url={data.url5}
                        type={data.url5Type}
                        userLang={userLang}
                    />
                </List>
            </div>
        );
    }
}

InfosCard.propTypes = {
    data: PropTypes.object.isRequired,
    userLang: PropTypes.string,
    pressReleases: PropTypes.array,
};

export default function _InfosCard(props) {
    const classes = useStyles();
    return <InfosCard {...props} classes={classes} />;
}
