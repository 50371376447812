import React, { Fragment } from "react";

import Grid from "@material-ui/core/Grid";

import ImageSlider from '../carousel/ImageSlider';

export default class MediaSliderBuilder {
    static build(cards, pos, userLang, manager, title, fullscreenVideo,
        duplicates, maxElements) {
        const single =
            <Fragment key={'fgridpos' + pos + 'single'}>
                <Grid item xs={12} key={'gridpos' + pos + 'singleTitle'}>
                    {title}
                </Grid>
                <Grid item xs={12} key={'gridpos' + pos + 'single'}>
                    <ImageSlider
                        cards={cards}
                        duplicates={duplicates}
                        autoPlay={false}
                        entryPage={true}
                        userLang={userLang}
                        manager={manager}
                        fullscreenVideo={fullscreenVideo}
                        maxElements={maxElements}
                        isMediaSlider={true}
                    />
                </Grid>
            </Fragment>;
        const double =
            <Fragment key={'fgridpos' + pos + 'double'}>
                <Grid item xs={3} />
                <Grid item xs={6} key={'gridpos' + pos + 'doubleTitle'}>
                    {title}
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={6} key={'gridpos' + pos + 'double'}>
                    <ImageSlider
                        cards={cards}
                        duplicates={duplicates}
                        autoPlay={false}
                        entryPage={true}
                        userLang={userLang}
                        manager={manager}
                        fullscreenVideo={fullscreenVideo}
                        maxElements={maxElements}
                        isMediaSlider={true}
                    />
                </Grid>
                <Grid item xs={3} />
            </Fragment>;
        return [single, double];
    }

    static buildTitle(pos, title) {
        const single =
            <Fragment key={'fgridpos' + pos + 'single'}>
                <Grid item xs={12} key={'gridpos' + pos + 'singleTitle'}>
                    {title}
                </Grid>
            </Fragment>;
        const double =
            <Fragment key={'fgridpos' + pos + 'double'}>
                <Grid item xs={3} />
                <Grid item xs={6} key={'gridpos' + pos + 'doubleTitle'}>
                    {title}
                </Grid>
                <Grid item xs={3} />
            </Fragment>;
        return [single, double];
    }
}
