import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import L from '../util/L10n';

const useStyles = makeStyles((theme) => ({
    stickToBottom: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
    },
}));

class BottomTabBar extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: 'other',
        }
    }

    config() {
        return [
            {
                value: 'today',
                path: '/',
                icon: RestoreIcon,
                label: 'bottomNavigationBarToday',
            },
            {
                value: 'line',
                path: '/linie',
                icon: FavoriteIcon,
                label: 'bottomNavigationBarLine',
            },
            {
                value: 'preview',
                path: '/vorschau',
                icon: LocationOnIcon,
                label: 'bottomNavigationBarPreview',
            },
        ];
    }

    valueByPath(path) {
        let newValue = 'other';
        for (let conf of this.config()) {
            if (path === conf.path) {
                newValue = conf.value;
                break;
            }
        }
        if (newValue !== this.state.value) {
            this.setState({ value: newValue, });
        }
    }

    componentDidMount() {
        this._didUpdateOrMount();
    }

    componentDidUpdate() {
        this._didUpdateOrMount();
    }

    _didUpdateOrMount() {
        this.valueByPath(this.props.location.pathname);
    }

    render() {
        if (this.props.appEmbedded)
            return null;

        return (
            <BottomNavigation
                value={this.state.value}
                onChange={this.props.onChange}
                showLabels={this.props.showLabels}
                className={this.props.classes.stickToBottom}
            >
                {this.createActions()}
            </BottomNavigation>
        );
    }

    createActions() {
        const actions = [];
        for (let conf of this.config()) {
            const Icon = conf.icon;
            const link = {
                pathname: conf.path,
                search: this.props.location.search,
            }
            actions.push(
                <BottomNavigationAction
                    value={conf.value}
                    to={link}
                    component={Link}
                    icon={<Icon />}
                    label={L.t(conf.label, this.props.userLang)}
                    key={conf.value}
                />
            );
        }
        return actions;
    }
}

BottomTabBar.propTypes = {
    onChange: PropTypes.func,
    showLabels: PropTypes.bool,
    appEmbedded: PropTypes.bool,
    userLang: PropTypes.string,
};

BottomTabBar.defaultProps = {
    showLabels: true,
}

export default withRouter((props) => {
    const classes = useStyles();
    return <BottomTabBar {...props} classes={classes} />
});
