import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import AudioPlayer from '../audio/AudioPlayer';
import AltText from '../util/AltText';

class AudioCardContent extends PureComponent {
    altText(data) {
        const text = new AltText();
        text.append(data.subheader);
        text.append(data.header);
        text.append(data.text);
        return text.toString();
    }

    render() {
        const { data, userLang, onFocus, onBlur } = this.props;
        const url = data && data.mediaURL;
        const altText = this.altText(data);
        if (url) {
            return (
                <AudioPlayer
                    url={url}
                    altText={altText}
                    userLang={userLang}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    // manager={manager}
                    // sliderItem={sliderItem}
                />
            );
        } else {
            return null;
        }
    }
}

AudioCardContent.prototypes = {
    data: PropTypes.object.isRequired,
    userLang: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    // manager: PropTypes.object,
    // sliderItem: PropTypes.number,
};

export default function _AudioCardContent(props) {
    return <AudioCardContent {...props} />;
}
