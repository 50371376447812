import DetectLocale from './DetectLocale';

export default class DateUtil {
    static today() {
        const date = new Date();
        const now = Date.now() - 60000 * date.getTimezoneOffset();
        return new Date(now).toISOString().substr(0, 10);
    }

    static daysAgo(n, userLang) {
        const locale = userLang ? userLang : DetectLocale.getPreferredLocale();
        const dateOptions =
            { year: 'numeric', month: '2-digit', day: '2-digit', };
        const date = new Date();
        const now = Date.now() - 86400000 * n;
        const nowIso = now - 60000 * date.getTimezoneOffset();
        const label = new Date(now).toLocaleDateString(locale, dateOptions);
        const value = new Date(nowIso).toISOString().substr(0, 10);
        return { label, value };
    }
}
