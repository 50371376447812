import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import ImageCardContent from './ImageCardContent';

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: 8,
        transform: 'translateZ(0)',
    },
    focusedCard: {
        borderRadius: 8,
        transform: 'translateZ(0)',
        outline: '6px solid #888888',
    },
    textOnlyCard: {
        borderRadius: 0,
        transform: 'translateZ(0)',
        outline: '1px solid #888888',
    },
    cardContent: {
        padding: 0,
    },
}));

class ImageCard extends PureComponent {
    constructor(props) {
        super(props);
        const focused = false;
        this.state = { focused };
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    onFocus() {
        const focused = true;
        this.setState({ focused });
    }

    onBlur() {
        const focused = false;
        this.setState({ focused });
    }

    render() {
        const {
            classes, data, parentEntry, link, externalLink, imageType,
            deviceSize, textOnly,textOnlyHeadLevelIncrement,
        } = this.props;
        const { focused } = this.state;
        return (
            <Card
                className={focused ? classes.focusedCard :
                    (textOnly ? classes.textOnlyCard : classes.card)}
                elevation={textOnly ? 0 : 11}
            >
                <CardContent className={classes.cardContent}>
                    <ImageCardContent
                        data={data}
                        parentEntry={parentEntry}
                        link={link}
                        externalLink={externalLink}
                        imageType={imageType}
                        deviceSize={deviceSize}
                        onFocusVisible={this.onFocus}
                        onBlur={this.onBlur}
                        textOnly={textOnly}
                        textOnlyHeadLevelIncrement={textOnlyHeadLevelIncrement}
                    />
                </CardContent>
            </Card >
        );
    }
}

ImageCard.propTypes = {
    data: PropTypes.object.isRequired,
    parentEntry: PropTypes.object,
    link: PropTypes.string,
    externalLink: PropTypes.string,
    imageType: PropTypes.string,
    deviceSize: PropTypes.string,
    textOnly: PropTypes.bool,
    textOnlyHeadLevelIncrement: PropTypes.number,
}

export default withRouter((props) => {
    const classes = useStyles();
    return <ImageCard {...props} classes={classes} />;
});
