import React, { Fragment, PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CallMadeIcon from '@material-ui/icons/CallMade';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Divider from '@material-ui/core/Divider';

import L from '../util/L10n';
import InvisibleImg from '../a11y/InvisibleImg';
import MaybeLink from '../util/MaybeLink';
import TextUtil from '../util/TextUtil';

const useStyles = makeStyles((theme) => ({
    item: {
        padding: 0,
        marginTop: 16,
        marginBottom: 16,
    },
    icon: {
        minWidth: 32,
        color: '#249dc5',
    },
    text: {
        fontWeight: 500,
        fontSize: 14,
        color: '#249dc5',
        '&:hover': {
            textDecoration: 'underline',
        },
        marginTop: 0,
    },
    link: {
        '&:focus-visible': {
            outline: '2px solid #888888',
            borderRadius: 6,
        },
    },
    divider: {
        height: 2,
        backgroundColor: '#f3f1f3',
        marginBottom: 8,
    },
}));

class InfosCardItem extends PureComponent {
    render() {
        const { classes, title, url, type, userLang } = this.props;
        const icon = 'pdf' === type ?
            <PictureAsPdfIcon fontSize='small' /> :
            <CallMadeIcon fontSize='small' />;
        const alt = 'pdf' === type ?
            L.t('infosPdfItem', userLang) : L.t('infosOtherItem', userLang);
        if (TextUtil.isEmptyText(title)) {
            return null;
        } else {
            return (
                <Fragment>
                    <ListItem
                        disableGutters={true}
                        classes={{ root: classes.item, }}>
                        <MaybeLink
                            externalLink={url}
                            className={classes.link}
                        >
                            <ListItemIcon classes={{ root: classes.icon, }}>
                                {icon}
                                <InvisibleImg alt={alt} />
                            </ListItemIcon>
                            <ListItemText className={classes.text}>
                                {title}
                            </ListItemText>
                        </MaybeLink>
                    </ListItem>
                    <Divider
                        classes={{ root: classes.divider }}
                    />
                </Fragment>
            );
        }
    }
}

InfosCardItem.propTypes = {
    title: PropTypes.string,
    url: PropTypes.string,
    type: PropTypes.string,
    userLang: PropTypes.string,
};

export default function _InfosCardItem(props) {
    const classes = useStyles();
    return <InfosCardItem {...props} classes={classes} />;
}
