export default class SlugifyUtil {
    static slugify(base, title) {
        if (!base || base === '') {
            base = '/';
        }
        const slugged = this._slugify(title);
        return slugged === '' ? base : base + '-' + slugged;
    }

    static _slugify(string) {
        if (!string) {
            return '';
        }
        const punctuation = string.toLowerCase().replaceAll(
            /[\u0020-\u002f\u003a-\u0040\u005b-\u0060\u007b-\u007e]/g,
            '-');
        const umlauteEszett = punctuation.replaceAll(
            /\u00e4/g, 'ae').replaceAll(
                /\u00f6/g, 'oe').replaceAll(
                    /\u00fc/g, 'ue').replaceAll(
                        /\u00df/g, 'ss');
        const decomposed = umlauteEszett.normalize('NFD');
        let cleaned = '';
        for (let i = 0; i < decomposed.length; i++) {
            const code = decomposed.charCodeAt(i);
            if (code >= 32 && code < 127) {
                cleaned += decomposed.charAt(i);
            }
        }
        return cleaned.replaceAll(
            /--+/g, '-').replace(
                /^-/, '').replace(
                    /-$/, '');
    }
}
