import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from "@material-ui/core/Typography";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { useMatomo } from '@datapunt/matomo-tracker-react';

import L from './util/L10n';
import PageHelmet from './util/PageHelmet';
import CanonicalUrl from './util/CanonicalUrl';

const useStyles = makeStyles({
    title: {
        fontWeight: 500,
        letterSpacing: '-1px',
        marginBlockStart: '0.83em',
        marginBlockEnd: '0.83em',
    },
    subtitle: {
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
    },
});

const CustomSwitch = withStyles({
    switchBase: {
        color: '#28a8ca',
        '&$checked': {
            color: '#28a8ca',
        },
        '&$checked + $track': {
            backgroundColor: '#28a8ca',
        },
    },
    checked: {},
    track: {},
})(Switch);

class SettingsPage extends PureComponent {
    componentDidMount() {
        try {
            window.scrollTo(0, 0);
        } catch (e) {
            // Ignore.
        }
    }

    buildMetaData() {
        const canonicalUrl = CanonicalUrl.get('/einstellungen');
        const ogLocale = 'de_DE';
        const ogType = 'website';
        return { canonicalUrl, ogLocale, ogType };
    }

    render() {
        const {
            classes, userLang, documentTitle, textOnly, setTextOnly,
        } = this.props;
        const {
            canonicalUrl, ogLocale, ogType,
        } = this.buildMetaData();
        const pageHelmet =
            <PageHelmet
                documentTitle={documentTitle}
                canonicalUrl={canonicalUrl}
                ogTitle={documentTitle}
                ogUrl={canonicalUrl}
                ogLocale={ogLocale}
                ogType={ogType}
            />;
        return (
            <Box>
                {pageHelmet}
                <Typography
                    variant='h4'
                    component='h2'
                    className={classes.title}
                >
                    {L.t('settingsPageTitle', userLang)}
                </Typography>
                <Typography
                    variant='h5'
                    component='h3'
                    className={classes.subtitle}
                >
                    {L.t('settingsA11yHeadline', userLang)}
                </Typography>
                <FormControlLabel
                    control={
                        <CustomSwitch
                            checked={textOnly}
                            onChange={() => {
                                setTextOnly(!textOnly);
                            }}
                        />
                    }
                    label={L.t('settingsTextOnlyLabel', userLang)}
                />
            </Box>
        );
    }
}

SettingsPage.propTypes = {
    userLang: PropTypes.string,
    documentTitle: PropTypes.string,
    textOnly: PropTypes.bool,
    setTextOnly: PropTypes.func.isRequired,
};

export default function _SettingsPage(props) {
    const classes = useStyles();
    const documentTitle = L.t('settingsPageTitle', props.userLang) + ' - ' +
        L.t('appTitle', props.userLang);
    const { trackPageView, } = useMatomo();
    React.useEffect(() => {
        trackPageView({ documentTitle, });
    }, [trackPageView, documentTitle]);
    return (
        <SettingsPage
            {...props}
            classes={classes}
            documentTitle={documentTitle}
        />
    );
}
