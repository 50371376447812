import React, { PureComponent, Fragment } from "react";
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';

import ImageCard from './cards/ImageCard';
import Header from './archive/Header';
import DateSelect from './archive/DateSelect';
import SearchInput from './archive/SearchInput';
import GridItem from "./archive/GridItem";
import { getArchiveDataDate, getArchiveDataSearch } from "./data/ArchiveReader";
import L from "./util/L10n";
import DateUtil from './util/DateUtil';
import Config from './config/ui_settings.json';
import PageHelmet from './util/PageHelmet';
import CanonicalUrl from "./util/CanonicalUrl";

const useStyles = makeStyles((theme) => ({
}));

class ArchivePage extends PureComponent {
    constructor(props) {
        super(props);
        const historyState = props.location.state || {};
        const { selectedDate, selectedTerm, selectedData } = historyState;
        const date = selectedDate || DateUtil.today();
        const term = selectedTerm || '';
        const data = selectedData || null;
        this.state = {
            error: null,
            data,
            userLang: props.userLang,
            date,
            term,
            twoColumnLayout: props.twoColumnLayout,
            layoutLocked: false,
        };
        this.onDataReceived = this._onDataReceived.bind(this);
        this.onSelectedDateChanged = this._onSelectedDateChanged.bind(this);
        this.onSearchSubmit = this._onSearchSubmit.bind(this);
        this.lockLayout = this._lockLayout.bind(this);
    }

    componentDidMount() {
        try {
            window.scrollTo(0, 0);
        } catch (e) {
            // Ignore.
        }
        this.load();
    }

    componentDidUpdate() {
        if (this.state.userLang !== this.props.userLang) {
            this.setState({ userLang: this.props.userLang, });
            this.load();
        }
        if (!this.state.layoutLocked) {
            const { twoColumnLayout } = this.props;
            if (twoColumnLayout !== this.state.twoColumnLayout) {
                this.setState({ twoColumnLayout });
            }
        }
    }

    _lockLayout(locked) {
        if (!!locked !== this.state.layoutLocked) {
            this.setState({ layoutLocked: locked, });
        }
    }

    load() {
        const { name } = this.props;
        const { data, date, term } = this.state;
        const lang = 'de';
        if (!data) {
            if (name === 'archive') {
                getArchiveDataDate(date, lang, this.onDataReceived
                ).catch((error) => {
                    this.setState({ error: error })
                });
            } else if (name === 'archivesearch') {
                if (term && term.trim().length > 0) {
                    getArchiveDataSearch(term, lang, this.onDataReceived
                    ).catch((error) => {
                        this.setState({ error: error })
                    });
                }
            }
        }
    }

    _onDataReceived(data) {
        const { history, location } = this.props;
        const state = location.state || {};
        state.selectedData = data;
        history.replace({ state });
        this.setState({ data: data, error: null })
    }

    _onSelectedDateChanged(event) {
        const date = event.target.value;
        const instance = this;
        const { history, location } = this.props;
        const state = location.state || {};
        state.selectedDate = date;
        history.replace({ state });
        this.setState({ date, data: undefined }, () => {
            instance.load();
        });
    }

    _onSearchSubmit(term) {
        const instance = this;
        const { history, location } = this.props;
        const state = location.state || {};
        state.selectedTerm = term;
        history.replace({ state });
        const newState = term && term.trim().length > 0 ?
            { term, data: undefined } : { term };
        this.setState(newState, () => {
            instance.load();
        })
    }

    renderContent(data) {
        const { name, userLang, deviceSize } = this.props;
        const { twoColumnLayout } = this.state;
        const column = name === 'archive' ? [] :
            [<GridItem
                twoColumnLayout={twoColumnLayout}
                key={'archiveSearchResutltsText'}
            >
                <Box>
                    <Typography variant='subtitle1' component='p'>
                        {L.t('archiveSearchResutltsText', userLang)}
                    </Typography>
                </Box>
            </GridItem>];
        let pos = 0;
        let count = 0;
        for (let key of Object.keys(data)) {
            const cards = this.buildCards(data[key], pos, userLang, deviceSize);
            this.buildColumn(column, cards, twoColumnLayout);
            count += cards.length;
            pos++;
        }
        if (count === 0) {
            const text = name === 'archive' ? 'archiveDateNoResultsText' :
                'archiveSearchNoResutltsText';
            column.push(
                <GridItem
                    twoColumnLayout={twoColumnLayout}
                    key={text}
                >
                    <Box>
                        <Typography variant='subtitle1' component='p'>
                            {L.t(text, userLang)}
                        </Typography>
                    </Box>
                </GridItem>
            );
        }
        return column;
    }

    buildCards(entries, pos, userLang, deviceSize) {
        let cards = [];
        let cardno = 0;
        for (let entry of entries) {
            const externalLink = entry.external_link;
            let found = false;
            for (let kachel of entry.kacheln) {
                if (!found && "uestra/altkachel" === kachel.type &&
                    'big' === kachel.teaser && kachel.mediaURL) {
                    found = true;
                    cards.push(
                        <ImageCard
                            key={'gridpos' + pos + 'entry' + entry.id +
                                'card' + cardno + userLang}
                            data={kachel}
                            parentEntry={entry}
                            externalLink={externalLink}
                            imageType={'big'}
                            deviceSize={deviceSize}
                        />
                    );
                    cardno++;
                }
            }
        }
        return cards;
    }

    buildColumn(column, cards, twoColumnLayout) {
        for (let card of cards) {
            column.push(
                <GridItem
                    twoColumnLayout={twoColumnLayout}
                    key={'gi' + card.key}
                >
                    {card}
                </GridItem>
            );
        }
    }

    buildMetaData() {
        const { name, description } = this.props;
        const canonicalUrl = CanonicalUrl.get(name === 'archive' ?
            '/archiv' : '/archivsuche');
        const ogLocale = 'de_DE';
        const ogType = 'website';
        return { description, canonicalUrl, ogLocale, ogType };
    }

    render() {
        const { name, userLang, documentTitle } = this.props;
        const { twoColumnLayout } = this.state;
        const { error, data, date, term } = this.state;
        const header =
            <Header
                name={name}
                twoColumnLayout={twoColumnLayout}
                userLang={userLang}
            />;
        const inputElement = name === 'archive' ?
            <GridItem twoColumnLayout={twoColumnLayout}>
                <DateSelect
                    userLang={userLang}
                    onChange={this.onSelectedDateChanged}
                    date={date}
                />
            </GridItem> :
            <GridItem twoColumnLayout={twoColumnLayout}>
                <SearchInput
                    label={L.t('archiveSearchInputLabel', userLang)}
                    ariaLabel={L.t('archiveSearchInputAriaLabel', userLang)}
                    submitLabel={L.t('archiveSearchSubmitLabel', userLang)}
                    onSubmit={this.onSearchSubmit}
                    value={term}
                    lockLayout={this.lockLayout}
                />
            </GridItem>;
        let content = name === 'archive' || (term && term.trim().length > 0) ?
            <GridItem twoColumnLayout={twoColumnLayout}>
                <Box>
                    <Typography variant='subtitle1' component='p'>
                        {L.t('msgTeaserPageLoading', userLang)}
                    </Typography>
                </Box>
            </GridItem> : null;
        if (error) {
            content =
                <GridItem twoColumnLayout={twoColumnLayout}>
                    <Box>
                        <Typography variant='subtitle1' component='p'>
                            {L.t('msgTeaserPageFailed', userLang)}
                        </Typography>
                    </Box>
                </GridItem>;
        } else if (data) {
            content = this.renderContent(data);
        }
        const {
            description, canonicalUrl, ogLocale, ogType,
        } = this.buildMetaData();
        return (
            <Fragment>
                <PageHelmet
                    documentTitle={documentTitle}
                    metaDescription={description}
                    canonicalUrl={canonicalUrl}
                    ogTitle={documentTitle}
                    ogDescription={description}
                    ogUrl={canonicalUrl}
                    ogLocale={ogLocale}
                    ogType={ogType}
                />
                <Grid
                    container
                    direction='row'
                    justify='center'
                    alignItems='center'
                    spacing={Config.gridSpacing}
                >
                    {header}
                    {inputElement}
                    {content}
                </Grid>
            </Fragment>
        );
    }
}

ArchivePage.propTypes = {
    classes: PropTypes.object,
    name: PropTypes.string.isRequired,
    twoColumnLayout: PropTypes.bool,
    userLang: PropTypes.string,
    documentTitle: PropTypes.string,
    description: PropTypes.string,
    deviceSize: PropTypes.string,
}

export default withRouter((props) => {
    const classes = useStyles();
    const theme = useTheme();
    const isLandscape = useMediaQuery('@media (orientation: landscape)');
    const isWideScreen =
        useMediaQuery(theme.breakpoints.up(Config.teaserWidescreenMinWidth));
    const label = props.name === 'archive' ?
        'archiveTeaserPage' : 'archivesearchTeaserPage';
    const documentTitle = L.t(label + 'Title', props.userLang) + ' - ' +
        L.t('appTitle', props.userLang);
    const description = L.t(label + 'Description', props.userLang);
    const twoColumnLayout = isLandscape || isWideScreen;

    return <ArchivePage
        {...props}
        classes={classes}
        twoColumnLayout={twoColumnLayout}
        documentTitle={documentTitle}
        description={description}
    />;
});
