import React, { Fragment } from "react";

import Grid from "@material-ui/core/Grid";

import ImageSlider from '../carousel/ImageSlider';

export default class SectionSliderBuilder {
    static build(cards, pos, userLang, manager, title, duplicates, maxElements) {
        const single =
            <Fragment key={'fgridpos' + pos + 'single' + userLang}>
                <Grid
                    item xs={12}
                    key={'gridpos' + pos + 'singleTitle' + userLang}
                >
                    {title}
                </Grid>
                <Grid
                    item xs={12}
                    key={'gridpos' + pos + 'single' + userLang}
                >
                    <ImageSlider
                        cards={cards}
                        duplicates={duplicates}
                        autoPlay={true}
                        userLang={userLang}
                        manager={manager}
                        key={'gridposslider' + pos + 'single' + userLang}
                        maxElements={maxElements}
                    />
                </Grid>
            </Fragment>;
        const double =
            <Fragment key={'fgridpos' + pos + 'double' + userLang}>
                <Grid
                    item xs={12}
                    key={'gridpos' + pos + 'doubleTitle' + userLang}
                >
                    {title}
                </Grid>
                <Grid
                    item xs={6}
                    key={'gridpos' + pos + 'double' + userLang}
                >
                    <ImageSlider
                        cards={cards}
                        duplicates={duplicates}
                        autoPlay={true}
                        userLang={userLang}
                        manager={manager}
                        key={'gridposslider' + pos + 'double' + userLang}
                        maxElements={maxElements}
                    />
                </Grid>
            </Fragment>;
        return [single, double];
    }
}
