import React from "react";

import CardBox from './CardBox';
import FillBox from './FillBox';

export default class Columns {
    constructor() {
        this.arraySingle = [];
        this.arrayDouble = [];
        this.currentLeft = true;
        this.cardBox = new CardBox();
        this.fillBoxCount = 0;
    }

    addSlider(slider) {
        this.finishCardBox();
        this.addToBoth(slider[0], slider[1]);
        this.currentLeft = !this.currentLeft;
    }

    addSectionSlider(slider) {
        this.finishRow();
        this.addToBoth(slider[0], slider[1]);
        this.currentLeft = !this.currentLeft;
    }

    addSection(section) {
        const title = section[0];
        const items = section[1];
        this.finishRow();
        this.addToBoth(title[0], title[1]);
        for (let item of items) {
            this.addToBoth(item[0], item[1]);
            this.currentLeft = !this.currentLeft;
        }
    }

    addFull(items) {
        this.finishCardBox()
        for (let item of items) {
            this.addToBoth(item[0], item[1]);
            this.currentLeft = !this.currentLeft;
        }
    }

    addSmall(items) {
        for (let item of items) {
            this.addToSingle(item[0]);
            this.addSmallToCardBox(item[1]);
        }
    }

    addBanner(items) {
        for (let item of items) {
            this.addToSingle(item[0]);
            this.addBannerToCardBox(item[1]);
        }
    }

    finish() {
        this.finishCardBox();
    }

    getSingle() {
        return this.arraySingle;
    }

    getDouble() {
        return this.arrayDouble;
    }

    // end of interface.


    addToBoth(itemSingle, itemDouble) {
        this.addToSingle(itemSingle);
        this.addToDouble(itemDouble);
    }

    addToSingle(item) {
        this.arraySingle.push(item);
    }

    addToDouble(item) {
        this.arrayDouble.push(item);
    }

    addSmallToCardBox(item) {
        if (!this.cardBox.canAddSmall()) {
            this.addToDouble(this.cardBox.finish());
            this.currentLeft = !this.currentLeft;
        }
        this.cardBox.addSmall(item);
    }

    addBannerToCardBox(item) {
        if (!this.cardBox.canAddBanner()) {
            this.addToDouble(this.cardBox.finish());
            this.currentLeft = !this.currentLeft;
        }
        this.cardBox.addBanner(item);
    }

    finishRow() {
        this.finishCardBox();
        if (!this.currentLeft) {
            this.addToDouble(<FillBox key={'fillbox' + this.fillBoxCount} />);
            this.currentLeft = true;
            this.fillBoxCount++;
        }
    }

    finishCardBox() {
        if (!this.cardBox.isEmpty()) {
            this.addToDouble(this.cardBox.finish());
            this.currentLeft = !this.currentLeft;
        }
    }
}

