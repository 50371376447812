import React, { PureComponent } from 'react';
import PropTypes from "prop-types";
import { withRouter, Link } from 'react-router-dom';

import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import ArchiveRoundedIcon from '@material-ui/icons/ArchiveRounded';

const color = '#ffffff';
const backgroundColor = '#28a8ca';

const useStyles = makeStyles((theme) => ({
    button: {
        borderRadius: 6,
        color: color,
        backgroundColor: backgroundColor,
        border: '2px solid ' + backgroundColor,
        boxShadow: '0px 6px 7px -4px rgba(0,0,0,0.2), 0px 11px 15px 1px rgba(0,0,0,0.14), 0px 4px 20px 3px rgba(0,0,0,0.12)',
        textTransform: 'none',
        letterSpacing: 0.5,
        transition: '0.2s ease',
        '&:hover': {
            backgroundColor: backgroundColor,
            filter: 'brightness(120%)',
        },
        '&:active': {
            backgroundColor: backgroundColor,
            filter: 'brightness(120%)',
        },
        '&:focus': {
            backgroundColor: backgroundColor,
            filter: 'brightness(120%)',
        },
    },
    label: {
        justifyContent: 'left',
        paddingBottom: '0.2em',
    },
    icon: {
        paddingRight: 16,
        paddingTop: 4,
    },
}));


class SearchButton extends PureComponent {
    render() {
        const { classes, content, to, icon, location, history } = this.props;
        const iconElement = icon === 'search' ?
            <SearchIcon className={classes.icon} /> :
            <ArchiveRoundedIcon className={classes.icon} />;
        let Component = undefined;
        let link = undefined;
        let onClick = undefined;
        if (icon === 'archive' && location.state && location.state.back) {
            onClick = () => { history.goBack(); };
        } else {
            const state = {
                back: location.pathname,
            }
            Component = Link;
            link = {
                pathname: to,
                search: location.search,
                state,
            }
        }
        return (
            <Button
                variant='contained'
                fullWidth={true}
                component={Component}
                to={link}
                onClick={onClick}
                classes={{
                    root: classes.button,
                    label: classes.label,
                }}
            >
                {iconElement}
                {content}
            </Button>
        );
    }
}

SearchButton.propTypes = {
    classes: PropTypes.object.isRequired,
    content: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
};

export default withRouter((props) => {
    const classes = useStyles();
    return <SearchButton {...props} classes={classes} />;
});
