export default class CookieAccess {
    static hasMatomoCookie() {
        const cookies = document.cookie;
        return !!cookies.match(/(^|;)\s*mtm_consent\s*=\s*([^;]+)/) ||
            !!cookies.match(/(^|;)\s*mtm_consent_removed\s*=\s*([^;]+)/);
    }

    static hasConsent() {
        const cookies = document.cookie;
        return !!cookies.match(/(^|;)\s*mtm_consent\s*=\s*([^;]+)/);
    }

    static deleteMatomoCookie() {
        document.cookie = 'mtm_consent_removed=; ' +
            'expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    }

    static passwordCookieName(key) {
        return 'fgf_voucher_' + encodeURIComponent(key);
    }

    static setPasswordCookie(key, password) {
        const date = new Date(Date.now() + 86400000);
        document.cookie = this.passwordCookieName(key) + '=' +
            encodeURIComponent(password) + '; expires=' + date.toUTCString() +
            '; path=/;';
    }

    static deletePasswordCookie(key) {
        document.cookie = this.passwordCookieName(key) + '=; ' +
            'expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    }

    static getPasswordCookie(key) {
        const cookies = document.cookie;
        const name = this.passwordCookieName(key);
        const regexp = '(^|;)\\s*' + name + '\\s*=\\s*([^;]+)';
        const result = cookies.match(new RegExp(regexp));
        if (result) {
            return result[2];
        } else {
            return null;
        }
    }

    static getCookieName(surveyId) {
        return "survey:" + surveyId;
    }

    static setSurveyCookie(surveyId, endDateStr) {
        let expireDate = new Date(Date.now() + 24 * 60 * 60 * 1000);
        if (endDateStr) {
            let tmpDate = new Date(endDateStr.replace(' ', 'T'));
            if (tmpDate.getTime() !== 0) {
                expireDate = tmpDate;
            }

        }
        document.cookie = CookieAccess.getCookieName(surveyId) + '=true;expires=' + expireDate.toUTCString() +
            '; path=/;';
    }

    static hasSurveyCookie(surveyId) {
        return document.cookie.split(";").some((item) => item.trim().startsWith(CookieAccess.getCookieName(surveyId) + "="))
    }

    static deleteAllSurveyCookies() {
        return document.cookie.split(";").forEach(function (item) {
            if (item.trim().startsWith("survey:")) {
                let parts = item.split("=");
                if (parts.length > 0) {
                    document.cookie = parts[0] + '=; ' +
                        'expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
                }
            }
        });

    }


}
