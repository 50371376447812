import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import YoutubeCardContent from './YoutubeCardContent';

const useStylesByAspectRatio = (aspectRatio) => {
    return makeStyles((theme) => ({
        card: {
            borderRadius: '8px',
            transform: 'translateZ(0)',
        },
        cardFullscreen: {
            borderRadius: '8px',
        },
        cardContent: {
            padding: 0,
            aspectRatio,
            overflow: 'hidden',
            width: '100%',
            backgroundColor: '#ffffff',
        },
    }))
};

class YoutubeCard extends PureComponent {
    render() {
        const { classes, data, aspectRatio } = this.props;
        return (
            <Card
                className={classes.card}
                elevation={11}
            >
                <CardContent className={classes.cardContent}>
                    <YoutubeCardContent
                        data={data}
                        aspectRatio={aspectRatio}
                    />
                </CardContent>
            </Card>
        );
    }
}

YoutubeCard.propTypes = {
    data: PropTypes.object.isRequired,
    aspectRatio: PropTypes.string.isRequired,
};

function getAspectRatio(props) {
    const arRegex = /^\d+\s*\/\s*\d+$/;
    if (props && props.data && props.data.aspectRatio &&
        props.data.aspectRatio.trim().match(arRegex)) {
        return props.data.aspectRatio.trim();
    } else {
        return '16 / 9';
    }
}

export default function _YoutubeCard(props) {
    const aspectRatio = getAspectRatio(props);
    const useStyles = useStylesByAspectRatio(aspectRatio);
    const classes = useStyles();
    return (
        <YoutubeCard
            {...props}
            classes={classes}
            aspectRatio={aspectRatio}
        />
    );
}
