import React, { Fragment } from "react";

import Grid from "@material-ui/core/Grid";

import ImageSlider from '../carousel/ImageSlider';

export default class SliderBuilder {
    static build(cards, pos, userLang, manager, fullscreenVideo, duplicates,
        maxElements) {
        const single =
            <Grid item xs={12} key={'gridpos' + pos + 'single'}>
                <ImageSlider
                    cards={cards}
                    duplicates={duplicates}
                    autoPlay={false}
                    entryPage={true}
                    userLang={userLang}
                    manager={manager}
                    fullscreenVideo={fullscreenVideo}
                    maxElements={maxElements}
                />
            </Grid>;
        const double =
            <Fragment key={'fgridpos' + pos + 'double'}>
                <Grid item xs={3} />
                <Grid item xs={6} key={'gridpos' + pos + 'double'}>
                    <ImageSlider
                        cards={cards}
                        duplicates={duplicates}
                        autoPlay={false}
                        entryPage={true}
                        userLang={userLang}
                        manager={manager}
                        fullscreenVideo={fullscreenVideo}
                        maxElements={maxElements}
                    />
                </Grid>
                <Grid item xs={3} />
            </Fragment>;
        return [single, double];
    }
}
