import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import AudioCardContent from './AudioCardContent';

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: '8px',
        transform: 'translateZ(0)',
    },
    focusedCard: {
        borderRadius: '8px',
        transform: 'translateZ(0)',
        outline: '6px solid #888888',
    },
    cardContent: {
        padding: 0,
        backgroundColor: '#000000',
    },
}));

class AudioCard extends PureComponent {
    constructor(props) {
        super(props);
        const focused = false;
        this.state = { focused };
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    onFocus() {
        const focused = true;
        this.setState({ focused });
    }

    onBlur() {
        const focused = false;
        this.setState({ focused });
    }

    render() {
        const { classes, data, userLang } = this.props;
        const { focused } = this.state;
        return (
            <Card
            className={focused ? classes.focusedCard : classes.card}
            elevation={11}
            >
                <CardContent className={classes.cardContent}>
                    <AudioCardContent
                        data={data}
                        userLang={userLang}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                    />
                </CardContent>
            </Card>
        );
    }
}

AudioCard.propTypes = {
    data: PropTypes.object.isRequired,
    userLang: PropTypes.string,
};

export default function _AudioCard(props) {
    const classes = useStyles();
    return <AudioCard {...props} classes={classes} />;
}
