import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import ColorButton from '../forms/ColorButton';

const useStyles = makeStyles((theme) => ({
}));

class DeeplinkButton extends PureComponent {
    render() {
        const { data, } = this.props;
        return (
            <ColorButton
                content={data.title}
                url={data.url}
                backgroundColor={data.color}
                inverted={'inverse' === data.style}
            />
        );
    }
}

DeeplinkButton.propTypes = {
    data: PropTypes.object.isRequired,
};

export default function _DeeplinkButton(props) {
    const classes = useStyles();
    return <DeeplinkButton {...props} classes={classes} />;
}
