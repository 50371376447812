import React, { PureComponent } from 'react';
import PropTypes from "prop-types";
import { withRouter, Link } from 'react-router-dom';

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';

import L from '../util/L10n';
import Config from '../config/ui_settings.json';

const useStyles = makeStyles((theme) => ({
    footer: {
        padding: '7px 0px',
        borderTop: '1px solid #dddddd',
        position: 'absolute',
        bottom: 0,
        width: '100%',
    },
    item: {
        flexBasis: '32%',
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            flexBasis: '24%',
        },
        [theme.breakpoints.up('md')]: {
            flexBasis: '16%',
        },
    },
    button: {
        fontWeight: 500,
        fontSize: 12,
        textTransform: 'none',
        color: '#9e9e9e',
        '& :hover': {
            textDecoration: 'underline',
        },
        '& :active': {
            textDecoration: 'underline',
        },
        '& :focus': {
            textDecoration: 'underline',
        },
    },
    langButton: {
        fontWeight: 500,
        fontSize: 12,
        textTransform: 'none',
        color: '#28a8ca',
        '& :hover': {
            textDecoration: 'underline',
        },
        '& :active': {
            textDecoration: 'underline',
        },
        '& :focus': {
            textDecoration: 'underline',
        },
    },
    divider: {
        fontSize: 16,
        color: '#dddddd',
    },
}));

class Footer extends PureComponent {
    render() {
        const { classes, userLang, location } = this.props;
        return (
            <footer className={classes.footer}>
                <Container
                    maxWidth={Config.containerMaxWidth}
                >
                    <Grid container direction='row'
                        justify='center'
                        alignItems='center'
                        spacing={0}

                    >
                        <Grid item xs={4} sm={3} md={2} className={classes.item}>
                            <Button
                                component={Link}
                                to={{
                                    pathname: '/barrierefreiheit',
                                    search: location.search,
                                    state: {
                                        back: location.pathname,
                                    },
                                }}
                                className={classes.button}
                            >
                                {L.t('footerButtonA11y_text', userLang)}
                            </Button>
                        </Grid>
                        <div className={classes.divider}>|</div>
                        <Grid item xs={4} sm={3} md={2} className={classes.item}>
                            <Button
                                component={Link}
                                to={{
                                    pathname: '/impressum',
                                    search: location.search,
                                    state: {
                                        back: location.pathname,
                                    },
                                }}
                                className={classes.button}
                            >
                                {L.t('footerButtonImprint_text', userLang)}
                            </Button>
                        </Grid>
                        <div className={classes.divider}>|</div>
                        <Grid item xs={4} sm={3} md={2} className={classes.item}>
                            <Button
                                component={Link}
                                to={{
                                    pathname: '/datenschutz',
                                    search: location.search,
                                    state: {
                                        back: location.pathname,
                                    },
                                }}
                                className={classes.button}
                            >
                                {L.t('footerButtonDataprotection_text', userLang)}
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </footer>
        );
    }
}

Footer.propTypes = {
    userLang: PropTypes.string,
    updateUserLang: PropTypes.func.isRequired,
};

export default withRouter((props) => {
    const classes = useStyles();
    return <Footer {...props} classes={classes} />;
});
