import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

export default class PageHelmet extends PureComponent {
    render() {
        const {
            documentTitle, metaDescription, canonicalUrl, ogTitle,
            ogDescription, ogImage, ogLocale, ogType, ogUrl,
            ogArticlePublishedTime, ogArticleExpirationTime, noIndex,
        } = this.props;
        const descriptionEm = metaDescription ?
            <meta name='description' content={metaDescription} /> : null;
        const canonicalEm = canonicalUrl ?
            <link rel='canonical' href={canonicalUrl} /> : null;
        const ogTitleEm = ogTitle ?
            <meta property='og:title' content={ogTitle} /> : null;
        const ogDescriptionEm = ogDescription ?
            <meta property='og:description' content={ogDescription} /> : null;
        const ogImageEm = ogImage ?
            <meta property='og:image' content={ogImage} /> : null;
        const ogLocaleEm = ogLocale ?
            <meta property='og:locale' content={ogLocale} /> : null;
        const ogTypeEm = ogType ?
            <meta property='og:type' content={ogType} /> : null;
        const ogUrlEm = ogUrl ?
            <meta property='og:url' content={ogUrl} /> : null;
        const ogArticlePublishedTimeEm = ogArticlePublishedTime ?
            <meta
                property='article:published_time'
                content={ogArticlePublishedTime}
            /> : null;
        const ogArticleExpirationTimeEm = ogArticleExpirationTime ?
            <meta
                property='article:expiration_time'
                content={ogArticleExpirationTime}
            /> : null;
        const twTitleEm = ogTitle ?
            <meta name='twitter:title' content={ogTitle} /> : null;
        const twDescriptionEm = ogDescription ?
            <meta name='twitter:description' content={ogDescription} /> : null;
        const twUrlEm = ogUrl ?
            <meta property='twitter:url' content={ogUrl} /> : null;
        const twImageEm = ogImage ?
            <meta name='twitter:image' content={ogImage} /> : null;
        const noIndexEm = noIndex ? <meta name="robots" content="noindex"/> : null;
        return (
            <Helmet>
                <title>{documentTitle}</title>
                {descriptionEm}
                {canonicalEm}
                {ogTitleEm}
                {ogDescriptionEm}
                {ogImageEm}
                {ogLocaleEm}
                {ogTypeEm}
                {ogArticlePublishedTimeEm}
                {ogArticleExpirationTimeEm}
                {ogUrlEm}
                <meta name="twitter:card" content="summary_large_image" />
                {twTitleEm}
                {twDescriptionEm}
                {twUrlEm}
                {twImageEm}
                {noIndexEm}
            </Helmet>
        );
    }
}

PageHelmet.propTypes = {
    documentTitle: PropTypes.string.isRequired,
    metaDescription: PropTypes.string,
    canonicalUrl: PropTypes.string,
    ogTitle: PropTypes.string,
    ogDescription: PropTypes.string,
    ogImage: PropTypes.string,
    ogLocale: PropTypes.string,
    ogType: PropTypes.string,
    ogArticlePublishedTime: PropTypes.string,
    ogArticleExpirationTime: PropTypes.string,
    ogUrl: PropTypes.string,
    noIndex: PropTypes.bool,
};
