export default class TextUtil {
    static isEmptyText(content) {
        if (content) {
            const div = document.createElement('div');
            div.innerHTML = content;
            return '' === div.textContent.trim();
        } else {
            return true;
        }
    }
}
