import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import CardMedia from '@material-ui/core/CardMedia';

import MaybeLink from '../util/MaybeLink';
import AltText from '../util/AltText';
import DefaultImage from '../components/DefaultImage';
import ImageResizerUrl from '../util/ImageResizerUrl';
import EntryUrl from '../util/EntryUrl';
import L from '../util/L10n';

const useStyles = makeStyles((theme) => ({
    cardMediaStyle: {
        height: 'auto',
    },
}));

class CarouselImageCardContent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            newMediaURL: ImageResizerUrl.getResizeURL(props.data.mediaURL,
                props.imageType, props.deviceSize),
            mediaURL: DefaultImage.get(props.imageType),
            imageLoaded: false,
            tabIndexEnabled: !props.duplicate && props.sliderItem === 0,
        }
        this.onImageLoaded = this._onImageLoaded.bind(this);
        this.loadImage = this._loadImage.bind(this);
        this.setTabIndex = this._setTabIndex.bind(this);
        this.setFocusHandler = this.setFocusHandler.bind(this);
        this.setBlurHandler = this.setBlurHandler.bind(this);
    }

    componentDidMount() {
        const { manager, sliderItem, } = this.props;
        if (manager) {
            manager.addItem(this, sliderItem);
        }
        if (sliderItem === 0 || sliderItem === 1) {
            this.loadImage();
        }
    }

    componentDidUpdate() {
        const { manager, sliderItem, } = this.props;
        if (manager) {
            manager.addItem(this, sliderItem);
        }
        if (sliderItem === 0 || sliderItem === 1) {
            this.loadImage();
        }
    }

    _loadImage() {
        if (!this.state.imageLoaded) {
            const { mediaWidth, mediaHeight, } = this.props.data;
            if (mediaWidth && mediaHeight &&
                mediaWidth * 3 === mediaHeight * 4) {
                this.setState({
                    mediaURL: this.state.newMediaURL,
                    imageLoaded: true,
                });
            } else {
                this.image = new Image();
                this.image.crossOrigin = 'anonymous';
                this.image.onload = this.onImageLoaded;
                this.image.src = this.state.newMediaURL;
            }
        }
    }

    _onImageLoaded() {
        const canvas = document.createElement('canvas');
        canvas.width = 1280;
        canvas.height = 960;
        const context = canvas.getContext('2d');
        context.fillStyle = '#000000';
        context.fillRect(0, 0, canvas.width, canvas.height);
        let width = canvas.width;
        let height = canvas.height;
        let x = 0;
        let y = 0;
        if (this.image.width * 3 < this.image.height * 4) {
            width = this.image.width * canvas.height / this.image.height;
            x = (canvas.width - width) / 2;
        } else if (this.image.width * 3 > this.image.height * 4) {
            height = this.image.height * canvas.width / this.image.width;
            y = (canvas.height - height) / 2;
        }
        context.drawImage(this.image, x, y, width, height);
        const imageDataUrl = canvas.toDataURL('image/png');
        this.setState({ mediaURL: imageDataUrl, imageLoaded: true, });
    }

    _setTabIndex(enabled) {
        this.setState({ tabIndexEnabled: enabled });
    }

    setFocusHandler(onFocus) {
        this.setState({ onFocus });
    }

    setBlurHandler(onBlur) {
        this.setState({ onBlur });
    }

    linkUrl() {
        let url = null;

        const { parentEntry, location, link, externalLink} = this.props;

        if (parentEntry) {
            let pathname;
            if(externalLink && externalLink.startsWith("level://")) {
                pathname = location.pathname;
                if(pathname === "/") {
                    pathname = "/heute"
                }
                pathname = pathname + externalLink.substring(8);
            } else {
                pathname = EntryUrl.getPath(parentEntry);
            }

            const state = {
                data: parentEntry,
                back: location.pathname,
            }
            url = {
                pathname: pathname,
                search: location.search,
                state: state,
            }
        } else if (link) {
            url = link;
        }
        return url;
    }

    altText() {
        const { data, sliderItem } = this.props;
        const text = new AltText();

        //TODO: Hier fehlt die userLang, replace undefined!!
        const number = '' + (sliderItem + 1);
        text.append(L.t('sliderElementIntro', undefined, { number }));
        text.append(data.subheader);
        text.append(data.header);
        text.append(data.text);
        return text.toString();
    }

    _onClickImageCard() {
        if (window.FgfChannel) {
            const { parentEntry } = this.props;
            window.FgfChannel.postMessage("onPathChanged:/beitrag/" + parentEntry.id);
        }
    }

    render() {
        const { classes, externalLink } = this.props;
        const { mediaURL, tabIndexEnabled } = this.state;
        const tabIndex = tabIndexEnabled ? 0 : -1;
        const { onFocus, onBlur } = this.state;
        let checkedExternalLink;
        if(externalLink && !externalLink.startsWith("level://")) {
            checkedExternalLink = externalLink;
        }
        return (
            <MaybeLink
                onClick={window.FgfChannel && !checkedExternalLink ? this._onClickImageCard.bind(this) : null}
                to={this.linkUrl()}
                externalLink={checkedExternalLink}
                tabIndex={tabIndex}
                onFocusVisible={onFocus}
                onBlur={onBlur}
            >
                <CardMedia
                    className={classes.cardMediaStyle}
                    image={mediaURL}
                    component='img'
                    alt={this.altText()}
                    width={1280}
                    height={960}
                />
            </MaybeLink>
        );
    }
}

CarouselImageCardContent.prototypes = {
    data: PropTypes.object.isRequired,
    parentEntry: PropTypes.object,
    link: PropTypes.string,
    externalLink: PropTypes.string,
    imageType: PropTypes.string,
    deviceSize: PropTypes.string,
    manager: PropTypes.object,
    sliderItem: PropTypes.number,
    duplicate: PropTypes.bool,
};

CarouselImageCardContent.defaultProps = {
    imageType: 'big',
};

export default withRouter((props) => {
    const classes = useStyles();
    return <CarouselImageCardContent {...props} classes={classes} />
});
