import React, {useState} from "react";
//import L from "../util/L10n";
import useResizeObserver from '@react-hook/resize-observer'
import TextUtil from "../../util/TextUtil";
import ButtonBase from "@material-ui/core/ButtonBase";
import "./styles.css";
import config from "../../config/api.json";
import axios from "axios";
import CookieAccess from "../../util/CookieAccess";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";

function useSize(target) {
    const [size, setSize] = React.useState();

    React.useLayoutEffect(() => {
        target && setSize(target.getBoundingClientRect());
    }, [target]);

    // Where the magic happens
    useResizeObserver(target, (entry) => setSize(entry.contentRect));
    return size;
}

function useZoom(target) {
    const [zoom, setZoom] = React.useState(1);

    React.useLayoutEffect(() => {
        if (target) {
            let zoom_w = ((window.outerWidth)
                / window.innerWidth);
            let zoom_h = ((window.outerHeight)
                / window.innerHeight);
            setZoom(zoom_w > zoom_h ? zoom_h : zoom_w);
        }
    }, [target]);

    // Where the magic happens
    useResizeObserver(target, (entry) => {
        let zoom_w = ((window.outerWidth)
            / window.innerWidth);
        let zoom_h = ((window.outerHeight)
            / window.innerHeight);
        setZoom(zoom_w > zoom_h ? zoom_h : zoom_w)
    })
    return zoom;
}

function isEnded(endDateStr) {
    if (!endDateStr) return false;
    let endDate = new Date(endDateStr.replace(' ', 'T'));
    if (endDate.getTime() === 0) {
        return false;
    }
    return new Date().getTime() > endDate.getTime();
}


export default function Survey({
                                   /*classes,
                                   userLang,*/
                                   parentEntry,
                                   location,
                                   surveyId,
                                   question,
                                   answer_1,
                                   answer_2,
                                   answer_3,
                                   answer_4,
                                   color,
                                   onFontSizeChanged,
                                   isResult,
                                   endDate,
                               }) {

    const keyPrefix = "fahrgastfernsehen/city/survey/";

    const [target, setTarget] = React.useState();
    const size = useSize(target);
    const zoom = useZoom(target);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [result, setResult] = useState(isResult || CookieAccess.hasSurveyCookie(surveyId) || isEnded(endDate));
    const [result1, setResult1] = useState(null);
    const [result2, setResult2] = useState(null);
    const [result3, setResult3] = useState(null);
    const [result4, setResult4] = useState(null);
    const [resultText, setResultText] = useState('Nicht repräsentatives Stimmungsbild.');
    const [showErrorDlg, setShowErrorDlg] = useState(false);


    React.useEffect(() => {
        if (result) {
            getSurveyInfo(true);
        }
    }, [result])


    let height = 0;
    let fontSize = 1;
    let fontUnit = "px";
    if (size) {
        height = size.height;
        //console.log("height: ", height);
        /*if(height < 120) {
            fontSize = height * 0.030 ;
        } else if(height < 124) {
            fontSize = height * 0.034 ;
        } else if(height < 138) {
            fontSize = height * 0.038 ;
        } else*/
        if (height < 220) {
            fontSize = height * 0.042 - (148 / (height) * 0.1);
            //console.log("height < 186: ", fontSize)


        } else if (height < 300) {
            fontSize = height * 0.055;
        } else if (height < 427) {
            fontSize = height * 0.055;
        } else {
            fontSize = height * 0.065 * zoom;
        }
        //fontSize = height * 0.06 * height/260 ;//((height-80)  / 7)  * 0.55;
    }
    if (onFontSizeChanged) {
        onFontSizeChanged(fontSize);
    }


    let backgroundStyle = {
        backgroundImage:
            'url(' + process.env.PUBLIC_URL + '/images/background_survey.png)',
        backgroundSize: 'cover',
        backgroundColor: color,
        aspectRatio: '4/3',
        overflow: 'hidden',
    }


    let myStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        color: 'rgb(255, 255, 255)',
        fontSize: fontSize,
        aspectRatio: '4/3',
        display: 'flex',
        flexDirection: 'column'
    };


    function renderAnswer(id, text, selected, result) {

        let answerStyle = {
            position: "relative",
            flexGrow: 1,
            paddingTop: 3 / zoom,
            paddingBottom: 3 / zoom,
            paddingLeft: 16 / zoom,
            paddingRight: 16 / zoom,
            fontSize: '' + fontSize + fontUnit,
            color: 'rgb(255, 255, 255)',
            borderRadius: 8 / zoom,
            borderColor: selected ? 'rgb(255, 255, 255)' : "transparent",
            borderWidth: 1,
            borderStyle: 'solid',
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
            width: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            fontFamily: 'DinforPBR'


        };

        let answerStyle1 = {
            position: "relative",
            flexGrow: 1,
            paddingTop: 0,
            paddingBottom: 1 / zoom,
            paddingLeft: 16 / zoom,
            paddingRight: 16 / zoom,
            fontSize: '' + fontSize + fontUnit,
            color: 'rgb(255, 255, 255)',
            borderRadius: 8 / zoom,
            borderColor: selected ? 'rgb(255, 255, 255)' : "transparent",
            borderWidth: 1,
            borderStyle: 'solid',
            //backgroundColor: 'rgb(88, 136, 73)',
            width: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
            fontFamily: 'DinforPBR',
        };


        let answer;
        if (result) {
            let rightRadius = 0;
            if (result > 98) {
                rightRadius = 8;
            }
            let inner;
            if (result) {
                inner = <div style={{
                    display: "flex",
                    justifyContent: "space-between"
                }}>
                    <div>{text}</div>
                    <div>{result + "\u2009%"}</div>
                </div>

            } else {
                inner = <div>text</div>;
            }
            answer =
                <div style={{
                    position: 'relative', flexGrow: 1,
                    paddingBottom: 3,
                }}>
                    <div style={{
                        position: "absolute",
                        top: '0px',
                        height: '100%',
                        width: '100%',
                        borderRadius: 8 / zoom,
                        backgroundColor: 'rgba(255, 255, 255, 0.3)'
                    }}></div>
                    <div className="survey-bar" style={{
                        position: "absolute",
                        top: '0px',
                        height: '100%',
                        width: '' + result + '%',
                        //backgroundColor: 'rgba(100,100,100, 0.5)'
                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8,
                        borderTopRightRadius: rightRadius,
                        borderBottomRightRadius: rightRadius,


                    }}></div>

                    <div style={answerStyle1} onClick={function () {
                        if (!result) {
                            setSelectedAnswer(text);
                        }
                    }}>
                        {inner}
                    </div>


                </div>
        } else {
            answer = <div style={answerStyle} onClick={function () {
                setSelectedAnswer(text);
            }}>
                <div>
                    {text}
                </div>
            </div>
        }

        return (
            <div key={id} style={{
                paddingTop: 3 / zoom,
                paddingBottom: 3 / zoom,
                paddingLeft: 16 / zoom,
                paddingRight: 16 / zoom,
                flexGrow: 1,
                display: "flex",
                flexDirection: "column"
            }}>
                {answer}
            </div>
        )
            ;
    }


    let answers = []
    if (!TextUtil.isEmptyText(answer_1)) answers.push(renderAnswer(1, answer_1, selectedAnswer === answer_1 && !result, result1))
    if (!TextUtil.isEmptyText(answer_2)) answers.push(renderAnswer(2, answer_2, selectedAnswer === answer_2 && !result, result2))
    if (!TextUtil.isEmptyText(answer_3)) answers.push(renderAnswer(3, answer_3, selectedAnswer === answer_3 && !result, result3))
    if (!TextUtil.isEmptyText(answer_4)) answers.push(renderAnswer(4, answer_4, selectedAnswer === answer_4 && !result, result4))

    let title =
        <div style={{
            fontSize: '' + fontSize + fontUnit,
            fontWeight: 600,
            paddingTop: 16 / zoom,
            paddingBottom: 12 / zoom,
            paddingLeft: 16 / zoom,
            paddingRight: 16 / zoom,
            fontFamily: 'DinforPBR',
            lineHeight: 1.2,
            height: '2.4em',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
        }}
        >
            {question}
        </div>;

    function setSurveyInfo(data) {
        let result = JSON.parse(data);
        let count1 = 0;
        let count2 = 0;
        let count3 = 0;
        let count4 = 0;

        if (result['answer1'] && Number.isInteger(result['answer1'])) count1 = result['answer1'];
        if (result['answer2'] && Number.isInteger(result['answer2'])) count2 = result['answer2'];
        if (result['answer3'] && Number.isInteger(result['answer3'])) count3 = result['answer3'];
        if (result['answer4'] && Number.isInteger(result['answer4'])) count4 = result['answer4'];

        let count = count1 + count2 + count3 + count4;

        setResult(true);
        setResult1((count1 / count * 100).toFixed(1));
        setResult2((count2 / count * 100).toFixed(1));
        setResult3((count3 / count * 100).toFixed(1));
        setResult4((count4 / count * 100).toFixed(1));
        if (count > 500) {
            setResultText('Nicht repräsentatives Stimmungsbild, ' + count + ' Teilnehmner.');
        } else {
            setResultText('Nicht repräsentatives Stimmungsbild.');
        }
    }

    async function getSurveyInfo(useCache) {
        const key = keyPrefix + '/' + parentEntry.id + surveyId;
        if (useCache) {
            let data = sessionStorage.getItem(key);
            if (!isResult && data != null) {
                setSurveyInfo(data);
                return;
            }
        }
        const url = config.api_base + config.survey_info_path + '/' + parentEntry.id + '/' + surveyId;
        const response = await axios.get(url, {
            transformResponse: [(data) => {
                return data
            }],
        });
        if (response && 200 === response.status && response.data) {
            setSurveyInfo(response.data);
            sessionStorage.setItem(key, response.data);
        }

    }

    function getAnswer(answer) {
        if (!TextUtil.isEmptyText(answer) && selectedAnswer === answer) return true;
        return null;
    }

    function setParticipated(survey_id) {
        CookieAccess.setSurveyCookie(survey_id, endDate);
    }


    async function submitAnswer() {
        const survey_id = '' + surveyId;
        const entry_id = '' + parentEntry.id;
        const answer1 = getAnswer(answer_1);
        const answer2 = getAnswer(answer_2);
        const answer3 = getAnswer(answer_3);
        const answer4 = getAnswer(answer_4);
        const data = {survey_id, entry_id, answer1, answer2, answer3, answer4};
        const url = config.api_base + config.survey_path;
        const response = await axios.post(url, data, {
            transformResponse: [(data) => data],
        });
        if (response && 200 === response.status && response.data) {
            let json = JSON.parse(response.data);
            if (json && json.state === "success") {
                setParticipated(survey_id);
                return true;
            } else {
                if (json && json.state === "error" && json.errormessage === "ENDED ALREADY") {
                    setShowErrorDlg(true);
                    return true;
                }
            }
        }
        return false;
    }

    async function getResult() {
        if (await submitAnswer()) {
            await getSurveyInfo(false)
        }
    }


    let button;
    if (result) {
        button = <div key="comment"
                      style={{
                          paddingTop: 7 / zoom,
                          paddingBottom: "7/zoom",
                          fontSize: '' + fontSize / 1.5 + fontUnit,
                          fontFamily: 'DinforPBR',
                          color: "rgba(255,255,255, 0.6)"
                      }}>
            <center>{resultText}</center>
        </div>

    } else if (selectedAnswer) {
        button = <ButtonBase style={{
            paddingTop: 12 / zoom,
            paddingBottom: 9 / zoom,
            paddingLeft: 16 / zoom,
            paddingRight: 16 / zoom,
            fontSize: '' + fontSize + fontUnit,
            fontWeight: 600,
            fontFamily: 'DinforPBR',
            color: color,
            borderRadius: 8 / zoom,
            backgroundColor: 'rgb(255, 255, 255)',
            width: '100%',

        }} onClick={getResult}>
            <center style={{paddingBottom: 0,}}>
                abstimmen
            </center>
        </ButtonBase>
    } else {
        button = <div key="button" style={{
            paddingTop: 7 / zoom,
            paddingBottom: 7 / zoom,
            paddingLeft: 16 / zoom,
            paddingRight: 16 / zoom,
            fontSize: '' + fontSize + fontUnit,
            fontWeight: 600,
            color: 'rgb(163, 163, 163)',
            borderRadius: 8 / zoom,
            backgroundColor: 'rgb(224, 224, 224)',
            width: 'auto',
            fontFamily: 'DinforPBR',
        }}>
            <center style={{paddingBottom: 2 / zoom,}}>
                abstimmen
            </center>
        </div>

    }

    function handleClose() {
        setShowErrorDlg(false);
    }


    return (
        <div key={"survey_"} ref={setTarget} style={backgroundStyle}>
            <Dialog
                open={showErrorDlg}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Umfrage beendet
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        An der Umfrage kann nicht mehr teilgenommen werden.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <div style={myStyle}>
                {title}
                <div key="question" style={{
                    flexGrow: 1,
                    height: "auto",
                    display: "flex",
                    flexDirection: "column"
                }}>
                    {answers}
                </div>
                <div key="answers" style={{
                    paddingTop: 8 / zoom,
                    paddingBottom: 16 / zoom,
                    paddingLeft: 16 / zoom,
                    paddingRight: 16 / zoom,
                }}>
                    {button}
                </div>
            </div>
        </div>
    );
}
