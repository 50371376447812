import axios from 'axios';

import config from '../config/api.json';
import getViewData from "./ViewReader";

export default async function headViewData(viewName, lang, onDataReceived, port,
                                          token, date, lastModified) {
    const headers = token ? { 'X-WP-Nonce': token, } : {};
    const withCredentials = !!token;
    const datePath = date && token ? '/' + date : '';
    const langPath = lang ? '/' + lang : '';
    let url = config.api_base + config.entry_path + viewName +
        langPath + datePath;
    if (port && port !== 0) {
        url = "http://127.0.0.1:" + port + "/api" + config.entry_path + viewName;
        //console.log(url);
    }
    const response = await axios.head(url, {
        headers: headers,
        withCredentials: withCredentials,
    });
    if (response && 200 === response.status ) {
        const lastModifiedHeader = response.headers['last-modified'];
        if(lastModifiedHeader !== lastModified) {
            getViewData(viewName, lang, onDataReceived,
                port, token,
                date).catch((error) => {
                //console.log(error)
            });
        }


    }
}
