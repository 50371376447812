import React, { PureComponent } from 'react';
import PropTypes from "prop-types";
import ReactSwipe from '../react-swipe/ReactSwipe';

import { makeStyles, } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import CarouselIndicators from './CarouselIndicators';
import Config from '../config/ui_settings.json';

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: '8px',
        transform: 'translateZ(0)',
    },
    focusedCard: {
        borderRadius: '8px',
        transform: 'translateZ(0)',
        outline: '6px solid #888888',
    },
    cardFullscreen: {
        borderRadius: '8px',
    },
    cardContent: {
        padding: 0,
    },
}));

class Carousel extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            active: 0,
            focused: false,
        };
        this.onSelect = this._onSelect.bind(this);
        this.onNext = this._onNext.bind(this);
        this.onPrev = this._onPrev.bind(this);
        this.onChange = this._onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        props.manager.setFocusHandler(this.onFocus);
        props.manager.setBlurHandler(this.onBlur);
    }

    componentDidMount() {
        if (this.swipeHandle) {
            this.swipeHandle.swipe.setup();
        }
    }

    componentDidUpdate() {
        if (this.swipeHandle) {
            this.swipeHandle.swipe.setup();
        }
    }

    _onSelect(index) {
        if (this.swipeHandle && (index !== this.state.active)) {
            this.swipeHandle.slide(index,
                Config.carouselTransitionSpeedMillis);
            this.setState({ active: this.getActive(), })
        }
    }

    _onNext() {
        if (this.swipeHandle) {
            this.swipeHandle.next();
            this.setState({ active: this.getActive(), })
        }
    }

    _onPrev() {
        if (this.swipeHandle) {
            this.swipeHandle.prev();
            this.setState({ active: this.getActive(), })
        }
    }

    _onChange() {
        if (this.swipeHandle) {
            const mgr = this.props.manager;
            const pos = this.getActive();
            if (pos !== this.state.active) {
                if (mgr) {
                    mgr.change(pos);
                }
                this.setState({ active: pos, })
            }
        }
    }

    onFocus() {
        const focused = true;
        this.setState({ focused });
    }

    onBlur() {
        const focused = false;
        this.setState({ focused });
    }

    getActive() {
        let pos = 0;
        if (this.swipeHandle) {
            pos = this.swipeHandle.getPos();
            const length = this.props.children.length;
            if (pos >= length) {
                pos -= length;
            }
        }
        return pos;
    }

    render() {
        const {
            classes, children, autoPlay, userLang, fullscreenVideo,
            duplicates, isMediaSlider, bgUrl,
        } = this.props;
        const { focused } = this.state;
        const classNameCard = fullscreenVideo ?
            classes.cardFullscreen :
            (focused ? classes.focusedCard : classes.card);
        const cardContentStyle = bgUrl ?
            {
                backgroundImage: 'url(' + bgUrl + ')',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            } : null;
        if (children.length > 1) {
            const swipeOptions = {
                continuous: true,
                callback: this.onChange,
                speed: Config.carouselTransitionSpeedMillis,
                auto: autoPlay ? Config.carouselAutplayIntervalMillis : undefined,
            };
            const indicators =
                <CarouselIndicators
                    count={children.length}
                    active={this.state.active}
                    onSelect={this.onSelect}
                    onNext={this.onNext}
                    onPrev={this.onPrev}
                    userLang={userLang}
                    isMediaSlider={isMediaSlider}
                />;
            return (
                <Box>
                    <Card
                        className={classNameCard}
                        elevation={Config.cardElevation}
                    >
                        <CardContent
                            className={classes.cardContent}
                            style={cardContentStyle}
                        >
                            <ReactSwipe
                                swipeOptions={swipeOptions}
                                ref={hdl => (this.swipeHandle = hdl)}
                            >
                                {children}
                                {children.length === 2 ? duplicates : null}
                            </ReactSwipe>
                        </CardContent>
                    </Card>
                    {indicators}
                </Box>
            );
        } else if (children.length === 1) {
            return (
                <Box>
                    <Card
                        className={classNameCard}
                        elevation={Config.cardElevation}
                    >
                        <CardContent
                            className={classes.cardContent}
                            style={cardContentStyle}
                        >
                            {children[0]}
                        </CardContent>
                    </Card>
                </Box>
            );
        } else {
            return null;
        }
    }
}

Carousel.prototypes = {
    autoPlay: PropTypes.bool,
    fixedWith: PropTypes.number,
    entryPage: PropTypes.bool,
    userLang: PropTypes.string,
    manager: PropTypes.object,
    fullscreenVideo: PropTypes.bool,
    isMediaSlider: PropTypes.bool,
    bgUrl: PropTypes.string,
};

Carousel.defaultProps = {
    entryPage: false,
    isMediaSlider: false,
}

export default function _Carousel(props) {
    const classes = useStyles();
    return <Carousel {...props} classes={classes} />
}
