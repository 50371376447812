import React, {useLayoutEffect, useState} from 'react'
import ScrambledBox from "./ScrambledBox";
import ScrambledDraggable from "./ScrambledDraggable";


export default function ScrambledSource({cWidth, text, observable}) {

    const [charVisibleAt, setCharVisibleAt] = useState([]);
    const [restoreIndex, setRestorIndex] = useState(null);
    const myText = text.replace(/\s/g, "");

    useLayoutEffect(() => {
        observable.subscribe(onRestore)
        const visibleAt = [];
        for (let i = 0; i < myText.length; i++) {
            visibleAt.push(true);
        }
        setCharVisibleAt(visibleAt);
        return () => {
            observable.unsubscribe(onRestore)
        }
    }, [myText.length, observable]);


    function onRestore(index) {
        setRestorIndex(index)
    }

    function onDropped(index, isCopy) {
        const visibleAt = [];
        for (let i = 0; i < myText.length; i++) {
            if (isCopy) {
                visibleAt.push(index === i ? false : charVisibleAt[i]);
                continue;
            }
            visibleAt.push(charVisibleAt[i]);
        }
        setCharVisibleAt(visibleAt);
    }

    function renderSquare(i, size, character, visible) {
        if (visible) {
            return (
                <div key={i} style={{
                    margin: 4,
                    width: size,
                    minHeight: size,
                    backgroundColor: 'rgb(235, 235, 235)',
                    borderColor: 'rgb(220, 220, 220)',
                    borderRadius: '20%',
                    borderStyle: 'solid',
                    borderWidth: 1
                }}>
                    <ScrambledBox>
                        <ScrambledDraggable width={size} character={character} position={i} isSource={true}
                                            onDropped={onDropped} observable={observable}></ScrambledDraggable>
                    </ScrambledBox>
                </div>
            )
        } else {
            return (
                <div key={i} style={{
                    margin: 4, width: size, minHeight: size,
                    backgroundColor: 'rgb(235, 235, 235)',
                    borderColor: 'rgb(220, 220, 220)',
                    borderRadius: '20%',
                    borderStyle: 'solid',
                    borderWidth: 1
                }}>
                    <ScrambledBox>
                    </ScrambledBox>
                </div>
            )
        }
    }

    if (restoreIndex !== null) {
        const visibleAt = [];
        for (let i = 0; i < myText.length; i++) {
            visibleAt.push(restoreIndex === i ? true : charVisibleAt[i]);
        }
        setRestorIndex(null);
        setCharVisibleAt(visibleAt);
    }

    const squares = []
    const size = cWidth / 8 - 1;

    if (charVisibleAt.length !== 0) {
        for (let i = 0; i < myText.length; i++) {
            squares.push(renderSquare(i, size - 8 -2, myText.charAt(i), charVisibleAt[i]))
        }
    }


    return (
        <div>
            <div style={{
                width: {cWidth},
                height: {size},
                minHeight: {size},
            }}>
                <div
                    style={{
                        width: {cWidth},
                        height: {size},
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center'
                    }}
                >
                    {squares}
                </div>
            </div>
        </div>
    )
}
