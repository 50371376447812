import React, { PureComponent } from 'react';
import { Switch as RouterSwitch, Route, Link, withRouter } from 'react-router-dom';
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import WifiIcon from '@material-ui/icons/Wifi';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import { useMatomo } from '@datapunt/matomo-tracker-react'

import L from '../util/L10n';
import InvisibleImg from '../a11y/InvisibleImg';
import TopTabBar from './TopTabBar';
import wifiConfig from '../config/wifi.json';
import logo from '../images/logo.svg';
import Config from '../config/ui_settings.json';

const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: theme.spacing(2),
        color: '#000000',
    },
    loginButton: {
        marginLeft: theme.spacing(2),
        marginRight: '-12px',
        color: '#9d9d9d',
    },
    placeboButton: {
        marginLeft: theme.spacing(2),
        marginRight: '-12px',
        paddingLeft: '48px',
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        margin: 0,
        alignItems: 'center',
        justifyContent: 'center',
    },
    logo: {
        height: '42px',
        //        width: '100%',
        //        textAlign: 'center',
    },
    stickToTop: {
        width: '100%',
        position: 'fixed',
        top: 0,
        backgroundColor: '#ffffff',
        borderBottom: '1px solid #d8d8d8',
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
}));

class TopAppBar extends PureComponent {
    render() {
        if (this.props.appEmbedded)
            return null;

        const {
            classes, location, history, onLoginButtonClick, showWifiLogin,
            onMenuButtonClick, userLang, externalTracking, scrollableTabs,
            previewToken, previewDate,
        } = this.props;
        const loginButton =
            <a
                href={wifiConfig.loginPageUrl}
                className={classes.link}
                onClick={externalTracking}
                tabIndex={-1}
            >
                <IconButton
                    edge='end'
                    className={classes.loginButton}
                    color="inherit"
                    onClick={onLoginButtonClick}
                >
                    <WifiIcon />
                    <InvisibleImg
                        alt={L.t('appBarLoginButton_ariaLabel', userLang)}
                    />
                </IconButton>
            </a>;
        const placeboButton = <div className={classes.placeboButton} />;
        const loginButtonItem = showWifiLogin ? loginButton : placeboButton;
        let onClick = undefined;
        let Component = undefined;
        let backLink = undefined;
        if (location.state && location.state.back) {
            onClick = () => { history.goBack(); }
        } else {
            Component = Link;
            backLink = {
                pathname: '/',
                search: location.search,
            }
        }
        const backArrowAltText = L.t('appBarBackButton_ariaLabel', userLang);
        const menuAltText = L.t('appBarMenuButton_ariaLabel', userLang);
        const homeLink = {
            pathname: '/',
            search: location.search,
        };
        return (
            <AppBar
                position="static"
                className={classes.stickToTop}
                elevation={0}
            >
                <Container maxWidth={Config.containerMaxWidth}>
                    <Toolbar disableGutters={true}>
                        <RouterSwitch>
                            <Route
                                path={[
                                    '/entry', '/beitrag', '/imprint',
                                    '/impressum', '/dataprotection',
                                    '/datenschutz', '/einstellungen',
                                    '/settings', '/barrierefreiheit',
                                    '/accessibility', '/ueber-uns', '/about-us',
                                    '/heute/:level','/today/:level',
                                    '/line/:level', '/linie/:level‘,',
                                    '/preview/:level', '/vorschau/:level',
                                    '/aktion/:level', '/specials/:level',
                                    '/gewinne/:level', '/prizes/:level',
                                    '/deal/:level'
                                ]}
                            >
                                <IconButton
                                    edge="start"
                                    className={classes.menuButton}
                                    color="inherit"
                                    component={Component}
                                    to={backLink}
                                    onClick={onClick}
                                >
                                    <ArrowBackIcon />
                                    <InvisibleImg alt={backArrowAltText} />
                                </IconButton>
                            </Route>
                            <Route>
                                <IconButton
                                    edge="start"
                                    className={classes.menuButton}
                                    color="inherit"
                                    onClick={onMenuButtonClick}
                                >
                                    <MenuIcon />
                                    <InvisibleImg alt={menuAltText} />
                                </IconButton>
                            </Route>
                        </RouterSwitch>
                        <Box
                            className={classes.title}
                            component='h1'
                        >
                            <Link
                                to={homeLink}
                            >
                                <img
                                    className={classes.logo}
                                    src={logo}
                                    alt={L.t('appBarDefaultTitle_text',
                                        userLang)} />
                            </Link>
                        </Box>
                        {loginButtonItem}
                    </Toolbar>
                    <TopTabBar
                        userLang={userLang}
                        variant={scrollableTabs ? 'scrollable' : 'fullWidth'}
                        previewToken={previewToken}
                        previewDate={previewDate}
                    //variant={'standard'}
                    />
                </Container>
            </AppBar>
        );
    }
}

TopAppBar.propTypes = {
    appEmbedded: PropTypes.bool,
    showWifiLogin: PropTypes.bool,
    onMenuButtonClick: PropTypes.func,
    onLoginButtonClick: PropTypes.func,
    userLang: PropTypes.string,
    scrollableTabs: PropTypes.bool,
    externalTracking: PropTypes.func,
    previewToken: PropTypes.string,
    previewDate: PropTypes.string,
};

export default withRouter((props) => {
    const classes = useStyles();
    const { trackEvent } = useMatomo();
    const _onClickWeb = () => {
        trackEvent({
            category: 'wifi',
            action: 'click',
            name: 'button',
        });
        return true;
    };
    return <TopAppBar
        {...props}
        classes={classes}
        externalTracking={_onClickWeb}
    />;
});
