import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from "@material-ui/core/Typography";
import EventBusyIcon from '@material-ui/icons/EventBusy';

const useStyles = makeStyles((theme) => ({
    box: {
        display: 'flex',
        color: '#616161',
        backgroundColor: '#ededed',
        borderRadius: '4px',
        width: '100%',
        height: '48px',
        fontSize: '14px',
        fontWeight: 'bold',
    },
    card: {
        color: '#616161',
        backgroundColor: '#ededed',
        borderRadius: 4,
        width: '100%',
        minHeight: 48,
    },
    cardContent: {
        display: 'flex',
    },
    icon: {
        padding: '12px 16px 0',
    },
    text: {
        fontSize: '14px',
        fontWeight: 500,
        textAlign: 'left',
        padding: '11px 4px 10px 0',
    },
}));

class CompetitionCardExpiredBox extends PureComponent {
    render() {
        const { classes, text, } = this.props;
        return (
            <Card className={classes.card} elevation={11}>
                <CardContent className={classes.cardContent}>
                    <div>
                        <EventBusyIcon className={classes.icon} />
                    </div>
                    <Typography
                        variant='body1'
                        component='div'
                        className={classes.text}
                    >
                        {text}
                    </Typography>
                </CardContent>
            </Card>
        );
    }
}

CompetitionCardExpiredBox.propTypes = {
    text: PropTypes.string.isRequired,
};

export default function _CompetitionCardExpiredBox(props) {
    const classes = useStyles();
    return <CompetitionCardExpiredBox {...props} classes={classes} />;
}
