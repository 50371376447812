import React, { PureComponent } from 'react';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import localeDe from 'date-fns/locale/de';
import format from "date-fns/format";

export default class DatePickerSetup extends PureComponent {
    render() {
        return (
            <MuiPickersUtilsProvider
                utils={LocalizedUtils}
                locale={localeDe}
            >
                {this.props.children}
            </MuiPickersUtilsProvider>
        );
    }
}

class LocalizedUtils extends DateFnsUtils {
    getCalendarHeaderText(date) {
        return format(date, "MMMM yyyy", { locale: this.locale });
    }

    getDateTimePickerHeaderText(date) {
        return format(date, "dd.MM.", { locale: this.locale });
    }
}
