import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { useMatomo } from '@datapunt/matomo-tracker-react'

import DealExpiredBox from './DealExpiredBox';
import ColorButton from '../forms/ColorButton';
import L from "../util/L10n";
import incrementDealCounter from '../data/DealWriter';

class DealButtonContent extends PureComponent {
    dealStarted() {
        const { data } = this.props;
        const { startDate } = data;
        if (startDate) {
            const now = new Date();
            const start = new Date(startDate);
            return start <= now;
        } else {
            return true;
        }
    }

    dealEnded() {
        const { data } = this.props;
        const { endDate } = data;
        if (endDate) {
            const now = new Date();
            const end = new Date(endDate);
            return end <= now;
        } else {
            return false;
        }
    }

    getOnClickHandler() {
        const { data, entry, trackDealClicked, recheckMaxCalls } = this.props;
        if (!!data.maxCalls) {
            return (() => {
                trackDealClicked();
                const deal_id = '' + data.id;
                const entry_id = '' + entry.id;
                const submission = { deal_id, entry_id };
                const handleDealIncrement = () => {
                    recheckMaxCalls();
                };
                incrementDealCounter('save', submission,
                    handleDealIncrement, handleDealIncrement);
            });
        } else {
            return trackDealClicked;
        }
    }

    render() {
        const { data, userLang } = this.props;
        const {
            id, buttonText, buttonColor, buttonStyle, email, emailText, subject,
        } = data;
        const onClick = this.getOnClickHandler();
        if (id && buttonText && email) {
            if (!this.dealStarted() || this.dealEnded()) {
                const text = L.t(this.dealEnded() ?
                    'dealDurationEnded' : 'dealDurationNotStarted', userLang);
                return (
                    <DealExpiredBox text={text} />
                );
            } else {
                let url = 'mailto:' + encodeURIComponent(email);
                let headers = '';
                if (subject) {
                    headers += 'subject=' + encodeURIComponent(subject);
                }
                if (emailText) {
                    headers += (headers ? '&' : '') + 'body=' +
                        encodeURIComponent(emailText);
                }
                if (headers) {
                    url += '?' + headers;
                }
                return (
                    <ColorButton
                        content={buttonText}
                        url={url}
                        backgroundColor={buttonColor}
                        inverted={'inverse' === buttonStyle}
                        onClick={onClick}
                    />
                );
            }
        } else {
            return null;
        }
    }
}

DealButtonContent.propTypes = {
    data: PropTypes.object.isRequired,
    entry: PropTypes.object.isRequired,
    userLang: PropTypes.string,
    trackDealClicked: PropTypes.func.isRequired,
    recheckMaxCalls: PropTypes.func,
};

export default function _DealButtonContent(props) {
    const { trackEvent } = useMatomo();
    const name = props.data && props.data.id;
    const dealClicked = () => {
        trackEvent({
            category: 'deal',
            action: 'click',
            name,
        });
        return true;
    };
    return (
        <DealButtonContent
            {...props}
            trackDealClicked={dealClicked}
        />
    );
}
