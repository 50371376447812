import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from "@material-ui/core/Typography";

import { useMatomo } from '@datapunt/matomo-tracker-react';

import L from '../util/L10n';
import PageHelmet from '../util/PageHelmet';

const useStyles = makeStyles({
    h2: {
        marginBlockStart: '0.83em',
        marginBlockEnd: '0.83em',
    },
    h3: {
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
    },
    h4Inset: {
        marginTop: '1em',
        marginLeft: '2rem',
    },
    p: {
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        textAlign: 'left',
        hyphens: 'auto',
    },
    pb: {
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        textAlign: 'justify',
        hyphens: 'auto',
    },
    pInset: {
        marginLeft: '2rem',
    },
});

class OptInResultPage extends PureComponent {
    componentDidMount() {
        try {
            window.scrollTo(0, 0);
        } catch (e) {
            // Ignore.
        }
    }

    render() {
        const { classes, documentTitle, success, userLang } = this.props;
        if (success) {
            return (
                <Box>
                    <PageHelmet documentTitle={documentTitle} />
                    <Typography variant='h4' component='h2' className={classes.h2}>
                        {L.t('optinSuccessPageTitle', userLang)}
                    </Typography>
                    <Typography variant='body1' component='p' className={classes.p}>
                        Vielen Dank für die Bestätigung Ihrer Teilnahme. Sie nehmen nun an unserer Aktion teil.
                    </Typography>
                </Box>
            );
        } else {
            return (
                <Box>
                    <PageHelmet documentTitle={documentTitle} />
                    <Typography variant='h4' component='h2' className={classes.h2}>
                        {L.t('optinFailedPageTitle', userLang)}
                    </Typography>
                    <Typography variant='body1' component='p' className={classes.p}>
                        Der Bestätigungs-Link, dem Sie gefolgt sind, ist leider nicht (mehr) gültig.
                    </Typography>
                </Box>
            );
        }
    }
}

OptInResultPage.propTypes = {
    userLang: PropTypes.string,
    documentTitle: PropTypes.string,
    success: PropTypes.bool,
};

export default function _OptInResultPage(props) {
    const classes = useStyles();
    const label = props.success ?
        'optinSuccessPageTitle' : 'optinFailedPageTitle';
    const documentTitle = L.t(label, props.userLang) + ' - ' +
        L.t('appTitle', props.userLang);
    const { trackPageView, } = useMatomo();
    React.useEffect(() => {
        trackPageView({ documentTitle, });
    }, [trackPageView, documentTitle]);
    return (
        <OptInResultPage
            {...props}
            classes={classes}
            documentTitle={documentTitle}
        />
    );
}
