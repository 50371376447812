import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from '@material-ui/core/styles';

import { useMatomo } from '@datapunt/matomo-tracker-react'

import '../../node_modules/video-react/dist/video-react.css';
import { Player, BigPlayButton, ControlBar } from 'video-react';

import HLSSource from './HLSSource';
import QualityButton from './QualityButton';
import L from "../util/L10n";

const useStyles = makeStyles((theme) => ({
    controlbar: {
        fontFamily: 'DinforPBR',
    },
}));

class VideoPlayer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            levels: [
                { label: L.t('videoPlayerLQ', props.userLang), width: 640, },
                { label: L.t('videoPlayerMQ', props.userLang), width: 960, },
                { label: L.t('videoPlayerHQ', props.userLang), width: 1280, },
            ],
            hasStarted: false,
            resetting: false,
            isFullscreen: false,
        };
        this.pause = this._pause.bind(this);
        this.setQuality = this._setQuality.bind(this);
        this.handleStateChg = this._handleStateChg.bind(this);
        this.reset = this._reset.bind(this);
    }

    componentDidMount() {
        const { manager, } = this.props;
        if (manager) {
            manager.addPlayer(this);
        }
        if (this.player) {
            this.player.subscribeToStateChange(this.handleStateChg);
        }
    }

    componentDidUpdate() {
        const { manager, } = this.props;
        if (manager) {
            manager.addPlayer(this);
        }
    }

    _pause() {
        if (this.player) {
            this.player.pause();
        }
    }

    _setQuality(width) {
        if (this.source) {
            this.source.setMaxWidth(width);
        }
        this.props.videoQualityCallback(width);
    }

    _handleStateChg(state, prevState) {
        if (state && state.hasStarted) {
            if (!this.state.hasStarted && !this.state.resetting) {
                this.setState({ hasStarted: true, });
                if (window._fgf_player) {
                    if (this !== window._fgf_player) {
                        window._fgf_player.reset();
                        window._fgf_player = this;
                    }
                } else {
                    window._fgf_player = this;
                }
                this.props.trackVideoStart();
            }
        }
        if (state && !state.hasStarted) {
            if (this.state.resetting) {
                this.setState({ hasStarted: false, resetting: false, });
            }
        }
        if (state && state.isFullscreen !== this.state.isFullscreen) {
            this.props.fullscreenVideoCallback(state.isFullscreen);
            this.setState({ isFullscreen: state.isFullscreen, });
        }
    }

    _reset() {
        this.setState({ resetting: true, });
        if (this.player) {
            this.player.pause();
        }
        if (this.source) {
            this.source.reset();
        }
        if (this.player) {
            this.player.load();
        }
    }

    componentWillUnmount() {
        if (this === window._fgf_player) {
            window._fgf_player = null;
        }
    }

    render() {
        const {
            classes, url, preview, aspectRatio, videoQuality, playsInline,
        } = this.props;
        // TODO Handle altText.
        return (
            <Player
                poster={preview}
                aspectRatio={aspectRatio}
                playsInline={playsInline}
                ref={player => {
                    this.player = player;
                }}
            >
                <BigPlayButton position="center" />
                <ControlBar className={classes.controlbar}>
                    <QualityButton
                        order={7}
                        levels={this.state.levels}
                        videoQuality={videoQuality}
                        qualityCallback={this.setQuality}
                        visible={!this.state.isFullscreen}
                    />
                </ControlBar>
                <HLSSource
                    isVideoChild
                    src={url}
                    videoQuality={videoQuality}
                    ref={src => {
                        this.source = src;
                    }}
                />
            </Player>
        );
    }
}

VideoPlayer.propTypes = {
    url: PropTypes.string,
    preview: PropTypes.string,
    altText: PropTypes.string,
    aspectRatio: PropTypes.string,
    manager: PropTypes.object,
    sliderItem: PropTypes.number,
    videoQuality: PropTypes.number,
    videoQualityCallback: PropTypes.func,
    fullscreenVideoCallback: PropTypes.func,
    playsInline: PropTypes.bool,
    trackVideoStart: PropTypes.func.isRequired,
    userLang: PropTypes.string,
};

VideoPlayer.defaultProps = {
    aspectRatio: '4:3',
};

export default function _VideoPlayer(props) {
    const classes = useStyles();
    const { trackEvent } = useMatomo();
    const _onVideoStart = () => {
        trackEvent({
            category: 'video',
            action: 'play',
            name: props.url,
        });
        return true;
    };
    return (
        <VideoPlayer
            {...props}
            classes={classes}
            trackVideoStart={_onVideoStart}
        />
    );
}
