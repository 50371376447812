import axios from 'axios';

import config from '../config/api.json';

export async function getArchiveDataDate(date, lang, onDataReceived) {
    const datePath = date === 'all' ? '/all' : '/d/' + (date ? date : '');
    const url = config.api_base + config.entry_path + 'archive/' +
        lang + datePath;
    getArchiveData(url, onDataReceived);
}

export async function getArchiveDataSearch(term, lang, onDataReceived) {
    const searchPath = '/s/' + encodeURIComponent(term);
    const url = config.api_base + config.entry_path + 'archive/' +
        lang + searchPath;
    getArchiveData(url, onDataReceived);
}

async function getArchiveData(url, onDataReceived) {
    const response = await axios.get(url, {
        transformResponse: [(data) => {
            return data
        }],
    });
    if (response && 200 === response.status && response.data) {
        console.log(url)
        console.log(response.data)
        onDataReceived(JSON.parse(response.data));
    } else {
        throw new Error('request failed', response);
    }
}
