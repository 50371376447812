import React, {Fragment, lazy, PureComponent, Suspense} from 'react';
import PropTypes from "prop-types";

import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import L from "../util/L10n";
import TextUtil from '../util/TextUtil';
import ImagePreview from '../forms/ImagePreview';
import ActionCardDurationBox from '../forms/ActionCardDurationBox';
import ActionCardNotOpenBox from '../forms/ActionCardNotOpenBox';
import {ReactComponent as Info} from '../images/info.svg';
import AudioRecorderController from '../forms/AudioRecorderController';
import TextField from "@material-ui/core/TextField";

const ActionCardForm = lazy(() => import('../forms/ActionCardForm'));
const ImageSelectButton = lazy(() => import('../forms/ImageSelectButton'));
const AudioRecorder = lazy(() => import('../forms/AudioRecorder'));

const useStyles = makeStyles((theme) => ({
    actionCard: {},
    imageSelector: {
        margin: '12px 0',
    },
    mainTitle: {
        color: '#000000',
        marginBottom: 3,
        fontSize: 16,
    },
    title: {
        color: '#000000',
        marginBottom: 3,
    },
    divider: {
        height: 2,
        backgroundColor: '#f3f1f3',
        marginBottom: 8,
    },
    formFallback: {
        width: '100%',
        height: '48px',
        textAlign: 'center',
    },
    actionRandom: {
        width: '100%',
        height: '48px',
        textAlign: 'start',
    },
    infoText: {
        margin: '24px 0',
    },
    infoTextSvgIcon: {},
    infoTextIconGridItem: {
        marginLeft: -12,
    },
    infoTextBody: {
        textAlign: 'justify',
        hyphens: 'auto',
    },
}));

const maxImages = 5;

class ActionCardContent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            images: [],
            imageMessages: [],
            image: null,
            imageMessage: null,
            audioUrl: null,
        };
        this.setTip1RequiredMessage = this._setTip1RequiredMessage.bind(this);
        this.setTip2RequiredMessage = this._setTip2RequiredMessage.bind(this);
        this.storageImage = this._storageImages.bind(this);
        this.setImageMessage = this._setImageMessage.bind(this);
        this.storeAddImages = this.storeAddImages.bind(this);
        this.storeDeleteImage = this.storeDeleteImage.bind(this);
        this.storeMarkSubmitted = this.storeMarkSubmitted.bind(this);
        this.setAudioUrl = this._setAudioUrl.bind(this);
        this.audioRecorderController = new AudioRecorderController();
    }

    async _setAudioUrl(url) {
        if (url !== null) {
            const files = [];
            let blob = await fetch(url).then(r => r.blob());
            files.push(blob);
            this.storeAddImages(files, 1);

        } else {
            const images = [];
            this.setState({images});
        }
    }

    _storageImages(evt) {
        const files = Array.from(evt.target.files);
        if (files.length > 0) {
            const currentLength = this.state.images.length;
            const addLength = files.length < maxImages - currentLength ?
                files.length : maxImages - currentLength;
            const siht = this;
            const storeAddImagesRest = () => {
                siht.storeAddImages(files, addLength);
            };
            if (files.length > addLength) {
                this.storeAddImageMessage('actionImageTooManyImages',
                    {}, storeAddImagesRest, true);
            } else {
                this.storeClearImageMessages(storeAddImagesRest);
            }
        }
        evt.target.value = ''
    }

    isVideoUpload() {
        const {data,} = this.props;
        return data.uploadmedium === "video";
    }

    isAudioUpload() {
        const {data,} = this.props;
        return data.uploadmedium === "audio";
    }

    storeAddImages(files, length) {
        if (files.length > 0 && length > 0) {
            const file = files[0];
            const params = {filename: file.name};
            const siht = this;
            const storeAddImagesRest = () => {
                siht.storeAddImages(files.slice(1), length - 1);
            };
            const storeAddImagesRejected = () => {
                siht.storeAddImages(files.slice(1), length);
            };
            if (this.isVideoUpload()) {
                if (file.size && file.size > 50 * 1024 * 1024) {
                    this.storeAddImageMessage('actionVideoTooLarge',
                        params, storeAddImagesRejected);
                    return;
                }
                if (file.type && file.type.indexOf('video/mp4') !== 0 &&
                    file.type.indexOf('video/quicktime') !== 0 &&
                    file.type.indexOf('video/webm') !== 0 &&
                    file.type.indexOf('video/3gp') !== 0 &&
                    file.type.indexOf('video/3gpp') !== 0 &&
                    file.type.indexOf('video/x-matroska') !== 0
                ) {
                    this.storeAddImageMessage('actionVideoWrongType',
                        params, storeAddImagesRejected);
                    return;
                }
            } else if (this.isAudioUpload()) {
            } else {
                if (file.size && file.size > 10 * 1024 * 1024) {
                    this.storeAddImageMessage('actionImageTooLarge',
                        params, storeAddImagesRejected);
                    return;
                }
                if (file.type && file.type.indexOf('image/png') !== 0
                    && file.type.indexOf('image/jpeg') !== 0) {
                    this.storeAddImageMessage('actionImageWrongType',
                        params, storeAddImagesRejected);
                    return;
                }
            }
            const reader = new FileReader();
            reader.onload = (data) => {
                const image = data.target.result;
                const {images} = siht.state;
                let duplicate = false;
                for (let img of images) {
                    if (img.data === image) {
                        duplicate = true;
                        break;
                    }
                }
                if (image) {
                    if (this.isVideoUpload()) {
                        if (image.indexOf('data:video/mp4;base64,') !== 0 &&
                            image.indexOf('data:video/quicktime;base64,') !== 0 &&
                            image.indexOf('data:video/webm;base64,') !== 0 &&
                            image.indexOf('data:video/3gp;base64,') !== 0 &&
                            image.indexOf('data:video/3gpp;base64,') !== 0 &&
                            image.indexOf('data:video/x-matroska;base64,') !== 0
                        ) {
                            siht.storeAddImageMessage('actionVideoWrongType',
                                params, storeAddImagesRejected);
                        } else if (image.length > 50 * 1024 * 1024 * 4 / 3) {
                            siht.storeAddImageMessage('actionVideoTooLarge',
                                params, storeAddImagesRejected);
                        } else {
                            const video = document.createElement('video');
                            video.preload = 'metadata';
                            video.onloadedmetadata = function () {
                                window.URL.revokeObjectURL(video.src);
                                if (video.duration > 60) {
                                    siht.storeAddImageMessage('actionVideoTooLong',
                                        params, storeAddImagesRejected);
                                    return;
                                }
                                images.length = 0;
                                siht.storeAddImage(image, params, storeAddImagesRest);
                            }
                            video.src = URL.createObjectURL(file);
                        }
                    } else if (this.isAudioUpload()) {
                        if (image.indexOf('data:audio/') !== 0) {
                            siht.storeAddImageMessage('actionImageWrongType',
                                params, storeAddImagesRejected);
                        } else {
                            siht.storeAddImage(image, params, storeAddImagesRest);
                        }
                    } else {
                        if (image.indexOf('data:image/png;base64,') !== 0 &&
                            image.indexOf('data:image/jpeg;base64,') !== 0) {
                            siht.storeAddImageMessage('actionImageWrongType',
                                params, storeAddImagesRejected);
                        } else if (image.length > 10 * 1024 * 1024 * 4 / 3) {
                            siht.storeAddImageMessage('actionImageTooLarge',
                                params, storeAddImagesRejected);
                        } else if (duplicate) {
                            siht.storeAddImageMessage('actionImageDuplicate',
                                params, storeAddImagesRejected);
                        } else {
                            siht.storeAddImage(image, params, storeAddImagesRest);
                        }
                    }
                }
            }
            reader.readAsDataURL(file);
        }
    }

    storeAddImage(data, params, afterStateUpdate) {
        const images = [];
        images.push(...this.state.images);
        images.push({data, ...params});
        this.setState({images}, afterStateUpdate);
    }

    storeAddImageMessage(msg, params, afterStateUpdate, clear) {
        const {userLang} = this.props;
        const text = L.t(msg, userLang, params);
        const imageMessages = [];
        if (!clear) {
            imageMessages.push(...this.state.imageMessages);
        }
        imageMessages.push(text);
        this.setState({imageMessages}, afterStateUpdate);
    }

    storeAddDebuagMessage(text, afterStateUpdate, clear) {
        const imageMessages = [];
        if (!clear) {
            imageMessages.push(...this.state.imageMessages);
        }
        imageMessages.push(text);
        this.setState({imageMessages}, afterStateUpdate);
    }

    storeDeleteImage(del) {
        const {images} = this.state;
        const imageMessages = [];
        const newImages = [];
        for (let index = 0; index < del; index += 1) {
            newImages.push(images[index]);
        }
        for (let index = del + 1; index < images.length; index += 1) {
            newImages.push(images[index]);
        }
        this.setState({images: newImages, imageMessages});
    }

    storeMarkSubmitted(submitted) {
        if (submitted && submitted.length > 0) {
            const {userLang} = this.props;
            const {images} = this.state;
            const newImages = [];
            const imageMessages = [];
            let countSubmitted = 0;
            for (let index = 0; index < images.length; index += 1) {
                const image = images[index];
                image.submitted = submitted[index];
                if (submitted[index]) {
                    countSubmitted += 1;
                }
                newImages.push(image);
            }
            if (countSubmitted > 0 && countSubmitted < images.length) {
                const text = L.t('actionImagesPartiallyTransmitted', userLang);
                imageMessages.push(text);
            }
            this.setState({images: newImages, imageMessages});
        }
    }

    storeClearImageMessages(afterStateUpdate) {
        const imageMessages = [];
        this.setState({imageMessages}, afterStateUpdate);
    }

    _storageImage(evt) {
        if (evt.target.files.length > 0) {
            const reader = new FileReader();
            reader.onload = (data) => {
                const image = data.target.result;
                const imageMessage = null;
                if (image) {
                    if (image.indexOf('data:image/png;base64,') !== 0 &&
                        image.indexOf('data:image/jpeg;base64,') !== 0) {
                        this.setImageMessage('actionImageWrongType');
                    } else if (image.length > 13981014) {
                        this.setImageMessage('actionImageTooLarge');
                    } else {
                        this.setState({image, imageMessage});
                    }
                }
            };
            reader.readAsDataURL(evt.target.files[0]);
        }
    }

    _setTip1RequiredMessage(msg) {
        this.setState({tip1msg: msg});
    }

    _setTip2RequiredMessage(msg) {
        this.setState({tip2msg: msg});
    }

    _setImageMessage(msg) {
        const {userLang} = this.props;
        const images = [];
        const imageMessages = [];
        if (msg) {
            let text = L.t(msg, userLang);
            if (msg === 'actionImageEmpty') {
                if (this.isVideoUpload()) {
                    text = L.t('actionVideoEmpty', userLang);
                } else if (this.isAudioUpload()) {
                    text = L.t('actionAudioEmpty', userLang);
                }
            }
            imageMessages.push(text);

        }
        this.setState({images, imageMessages});
    }


    render() {
        const {
            classes,
            data,
            userLang,
            lockLayout,
            entry,
            token,
            recheckMaxCalls,
            isActionOpen,
            isActionEnded,
            startDate,
            endDate,
        } = this.props;
        let imageSelectLabel = TextUtil.isEmptyText(data.uploadbuttonText) ?
            L.t('actionUploadButtonLabel', userLang) : data.uploadbuttonText;
        const title = TextUtil.isEmptyText(data.title) ? null : data.title;
        const formTitle = L.t('actionTitle', userLang);
        const formComponent = TextUtil.isEmptyText(data.title) ? 'h2' : 'h3';
        const requestName = 'optional' === data.name ||
            'mandatory' === data.name;
        const requiredName = 'mandatory' === data.name;
        const requestAddress = 'optional' === data.address ||
            'mandatory' === data.address;
        const requiredAddress = 'mandatory' === data.address;
        const requestEmail = 'optional' === data.email ||
            'mandatory' === data.email;
        const requiredEmail = 'mandatory' === data.email;
        const requestPhone = 'optional' === data.phone ||
            'mandatory' === data.phone;
        const requiredPhone = 'mandatory' === data.phone;
        const requestFreetext = 'optional' === data.freetext ||
            'mandatory' === data.freetext;
        const requiredFreetext = 'mandatory' === data.freetext;
        const cb1Requested = !TextUtil.isEmptyText(data.cb1Title) &&
            ('optional' === data.cb1 || 'mandatory' === data.cb1);
        const cb1Required = !TextUtil.isEmptyText(data.cb1Title) &&
            'mandatory' === data.cb1;
        const cb2Requested = !TextUtil.isEmptyText(data.cb2Title) &&
            ('optional' === data.cb2 || 'mandatory' === data.cb2);
        const cb2Required = !TextUtil.isEmptyText(data.cb2Title) &&
            'mandatory' === data.cb2;
        const cb3Requested = !TextUtil.isEmptyText(data.cb3Title) &&
            ('optional' === data.cb3 || 'mandatory' === data.cb3);
        const cb3Required = !TextUtil.isEmptyText(data.cb3Title) &&
            'mandatory' === data.cb3;
        const cb4Requested = !TextUtil.isEmptyText(data.cb4Title) &&
            ('optional' === data.cb4 || 'mandatory' === data.cb4);
        const cb4Required = !TextUtil.isEmptyText(data.cb4Title) &&
            'mandatory' === data.cb4;

        const {tip1, tip2, tip1msg, tip2msg} = this.state;


        const {images, imageMessages} = this.state;
        const requestImageUpload = 'optional' === data.imageupload ||
            'mandatory' === data.imageupload;
        const requiredImageUpload = 'mandatory' === data.imageupload;
        if (requiredImageUpload) {
            imageSelectLabel += '*';
        }
        const showDates = data.showParticipationTimespan;

        const entryId = entry.id;
        const infoText = TextUtil.isEmptyText(data.infoText) ?
            null : data.infoText;
        const tippspielHeader = TextUtil.isEmptyText(data.tippspielHeader) ?
            null : data.tippspielHeader;
        const tippspielDaten = TextUtil.isEmptyText(data.tippspielDaten) ?
            null : data.tippspielDaten;

        const tippRequired = tippspielHeader && tippspielDaten;

        function _buildTeam(mediaURL, name) {
            return <div style={{flex: "0 0 28%", width: "auto", paddingTop: 8}}>
                <center><img style={{aspectRatio: "54 / 31", height: "auto", width: "60%" }}
                             src={mediaURL}></img></center>
                <center><Typography
                    variant='button'
                    component={formComponent}
                    classes={{root: classes.title}}
                >
                    <div style={{textTransform: "none", hyphens: "auto", lineHeight:1.2, fontWeight:550}}>{name}</div>
                </Typography></center>
            </div>
        }


        function _buildTipInput(value, setValue, errorMsg) {

            return <div style={{flexGrow: 1, width: "auto", maxWidth: 60}}>
                <TextField
                    className={classes.textField}
                    type={"number"}
                    InputProps={{
                        inputProps: {
                            style: { textAlign: "center", background: "rgb(255, 255, 255)" },
                        }
                    }}
                    multiline={false}
                    variant='outlined'
                    error={errorMsg}
                    onChange={(event) => {
                        let value = event.target.value;
                        if(value < 100) {
                            setValue(event.target.value);
                        }
                    }}
                    value={value}
                />
            </div>

        }

        console.log("tippRequired: ", tippRequired)
        const tippspielElement = tippRequired ?
            < div style={{paddingBottom: 16}}>
                <Typography
                    variant='subtitle2'
                    component={formComponent}
                    classes={{root: classes.title}}
                >
                    {tippspielHeader.toUpperCase()}
                </Typography>
                <Divider
                    classes={{root: classes.divider}}
                />
                <center><Typography
                    variant='subtitle2'
                    component={formComponent}
                    classes={{root: classes.title}}
                    style={{paddingBottom: 16}}

                >
                    {tippspielDaten}
                </Typography></center>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    {_buildTeam(data.tippspielTeam1MediaURL, data.tippspielTeam1Name)}
                    <div style={{flex: "0 0 44%", display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center"}}>
                        {_buildTipInput(
                            tip1,
                            (value)=>{this.setState({tip1: value, tip1msg:null});},
                            tip1msg)}
                        <center><div style={{flexGrow: 0, paddingTop:0}}>
                            <Typography
                                variant='button'
                                component={formComponent}
                                classes={{root: classes.title}}
                            >
                                <div style={{textTransform: "none", fontWeight:550}}>:</div>
                            </Typography>

                        </div></center>
                        {_buildTipInput(
                            tip2,
                            (value)=>{this.setState({tip2: value,tip2msg: null});},
                            tip2msg)}
                    </div>
                    {_buildTeam(data.tippspielTeam2MediaURL, data.tippspielTeam2Name)}
                </div>
            </div> : null;


        const infoTextElement = infoText ?
            <div className={classes.infoText}>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    wrap='nowrap'
                    spacing={2}
                >
                    <Grid item className={classes.infoTextIconGridItem}>
                        <Info
                            className={classes.infoTextSvgIcon}
                            transform='scale(0.5)'
                        />
                    </Grid>
                    <Grid item xs zeroMinWidth>
                        <Typography
                            variant='body1'
                            component='div'
                            classes={{root: classes.infoTextBody}}
                        >
                            {infoText}
                        </Typography>
                    </Grid>
                </Grid>
            </div> : null;
        const titleElement = title ?
            <Fragment>
                <Typography
                    variant='subtitle2'
                    component='h2'
                    classes={{root: classes.mainTitle}}
                >
                    {title}
                </Typography>
                <Divider
                    classes={{root: classes.divider}}
                />
            </Fragment> : null;
        const imageSelector = this.isAudioUpload() ?
            <div>
                <Suspense fallback={
                    <div className={classes.formFallback}>...</div>
                }><AudioRecorder
                    color={data.buttonColor}
                    onChange={this.setAudioUrl}
                    audioText={data.audioText}
                    audioDescriptionText={data.audioDescription}
                    audioWhileText={data.audioWhile}
                    audioAfterText={data.audioAfter}
                    imageMessages={imageMessages}
                    audioRecorderController={this.audioRecorderController}
                    userLang={userLang}
                /> </Suspense>
            </div> :
            (requestImageUpload ?
                <div className={classes.imageSelector}>
                    <Suspense fallback={
                        <div className={classes.formFallback}>...</div>
                    }>
                        <ImageSelectButton
                            content={imageSelectLabel}
                            backgroundColor={data.uploadbuttonColor}
                            inverted={'inverse' === data.uploadbuttonStyle}
                            onChange={this.storageImage}
                            disabled={images.length >= maxImages}
                            isVideoUpload={this.isVideoUpload()}
                        />
                    </Suspense>
                    <ImagePreview
                        images={images}
                        imageMessages={imageMessages}
                        onDeleteImage={this.storeDeleteImage}
                        isVideoUpload={this.isVideoUpload()}
                    />
                </div> : null);
        if (isActionOpen) {
            return (
                <div className={classes.actionCard}>
                    <ActionCardDurationBox
                        showDates={showDates}
                        startDate={startDate}
                        endDate={endDate}
                        labelDuration={L.t('actionDurationLabelDuration',
                            userLang)}
                        labelFrom={L.t('actionDurationLabelFrom',
                            userLang)}
                        labelTo={L.t('actionDurationLabelTo', userLang)}
                        userLang={userLang}
                    />
                    {tippspielElement}
                    {titleElement}
                    {imageSelector}
                    {infoTextElement}
                    <Typography
                        variant='subtitle2'
                        component={formComponent}
                        classes={{root: classes.title}}
                    >
                        {formTitle}
                    </Typography>
                    <Divider
                        classes={{root: classes.divider}}
                    />

                    <Suspense fallback={
                        <div className={classes.formFallback}>...</div>
                    }>
                        <ActionCardForm
                            userLang={userLang}
                            firstnameRequested={requestName}
                            firstnameRequired={requiredName}
                            lastnameRequested={requestName}
                            lastnameRequired={requiredName}
                            streetRequested={requestAddress}
                            streetRequired={requiredAddress}
                            streetnrRequested={requestAddress}
                            streetnrRequired={requiredAddress}
                            zipRequested={requestAddress}
                            zipRequired={requiredAddress}
                            cityRequested={requestAddress}
                            cityRequired={requiredAddress}
                            extraRequested={requestAddress}
                            extraRequired={false}
                            emailRequested={requestEmail}
                            emailRequired={requiredEmail}
                            phoneRequested={requestPhone}
                            phoneRequired={requiredPhone}
                            freetextRequested={requestFreetext}
                            freetextRequired={requiredFreetext}
                            freetextLabel={data.freetextLabel}
                            cb1Requested={cb1Requested}
                            cb1Required={cb1Required}
                            cb1Title={data.cb1Title}
                            cb1LinkText={data.cb1LinkText}
                            cb1LinkUrl={data.cb1LinkUrl}
                            cb2Requested={cb2Requested}
                            cb2Required={cb2Required}
                            cb2Title={data.cb2Title}
                            cb2LinkText={data.cb2LinkText}
                            cb2LinkUrl={data.cb2LinkUrl}
                            cb3Requested={cb3Requested}
                            cb3Required={cb3Required}
                            cb3Title={data.cb3Title}
                            cb3LinkText={data.cb3LinkText}
                            cb3LinkUrl={data.cb3LinkUrl}
                            cb4Requested={cb4Requested}
                            cb4Required={cb4Required}
                            cb4Title={data.cb4Title}
                            tippRequired={tippRequired}
                            tip1={tip1}
                            tip2={tip2}
                            cb4LinkText={data.cb4LinkText}
                            cb4LinkUrl={data.cb4LinkUrl}
                            buttonText={data.buttonText}
                            buttonColor={data.buttonColor}
                            buttonStyle={data.buttonStyle}
                            requestImageUpload={requestImageUpload}
                            requiredImageUpload={requiredImageUpload}
                            action_id={data.id}
                            entry_id={'' + entryId}
                            token={token}
                            lockLayout={lockLayout}
                            images={images}
                            setImageUploadMessage={this.setImageMessage}
                            setTip1RequiredMessage={this.setTip1RequiredMessage}
                            setTip2RequiredMessage={this.setTip2RequiredMessage}
                            recheckMaxCalls={recheckMaxCalls}
                            markSubmitted={this.storeMarkSubmitted}
                            autoresponse={data.autoresponse}
                            popupTitle={data.popupTitle}
                            popupText={data.popupText}
                            audioRecorderController={this.audioRecorderController}
                        />
                    </Suspense>
                </div>
            );
        } else {
            const ended = isActionEnded;
            const text = L.t(ended ?
                    'actionDurationEnded' : 'actionDurationNotStarted',
                userLang);
            return (
                <div className={classes.actionCard}>
                    <ActionCardNotOpenBox
                        text={text}
                        ended={ended}
                    />
                </div>
            );
        }
    }
}

ActionCardContent.propTypes = {
    data: PropTypes.object.isRequired,
    entry: PropTypes.object.isRequired,
    userLang: PropTypes.string,
    lockLayout: PropTypes.func,
    token: PropTypes.string,
    isActionOpen: PropTypes.bool,
    isActionEnded: PropTypes.bool,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    recheckMaxCalls: PropTypes.func,
};

export default function _ActionCardContent(props) {
    const classes = useStyles();
    return <ActionCardContent {...props} classes={classes}/>;
}
