import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import Hls from 'hls.js';

export default class HLSSource extends PureComponent {
    constructor(props, context) {
        super(props, context);
        this._setupHls();
        this.levels = null;
        this.videoQuality = props.videoQuality;
        this.load = this._load.bind(this);
        this.getLevels = this._getLevels.bind(this);
        this.setMaxWidth = this._setMaxWidth.bind(this);
    }

    _setupHls() {
        const config = {
            autoStartLoad: false,
            maxMaxBufferLength: 10, // This defaults to 10 minutes, rendering level selection after start virtually useless.
            //capLevelToPlayerSize: true, // Cap to player size, would result in bad quality
            //startLevel: 1, // select start level, but level will increase automatically unless captoplayer eg. enabled.
        }
        this.hls = new Hls(config);
    }

    _initHls() {
        const { src, video, videoQuality, } = this.props;
        if (Hls.isSupported()) {
            this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
                this.levels = this.hls.levels;
                this.setMaxWidth(videoQuality);
            });
            this.hls.loadSource(src);
            this.hls.attachMedia(video);
            video.addEventListener('play', this.load);
        }
    }

    componentDidMount() {
        this._initHls();
    }

    reset() {
        this._setupHls();
        this._initHls();
    }

    componentDidUpdate() {
        const { videoQuality, } = this.props;
        if (this.videoQuality !== videoQuality) {
            this.setMaxWidth(videoQuality);
            this.videoQuality = videoQuality;
        }
    }

    _load() {
        if (this.hls)
            this.hls.startLoad();
    }

    _getLevels() {
        return this.levels;
    }

    _setMaxWidth(maxWidth) {
        if (this.levels && this.levels.length > 0) {
            const levels = this.hls.levels
            let selectedLevel = 0;
            let selectedWidth = levels[0].width;
            for (let i = 1; i < levels.length; i++) {
                if (maxWidth >= levels[i].width &&
                    selectedWidth < levels[i].width) {
                    selectedLevel = i;
                    selectedWidth = levels[i].width;
                }
            }
            this.hls.autoLevelCapping = selectedLevel;
        }
    }

    componentWillUnmount() {
        if (this.hls) {
            this.hls.destroy();
        }
    }

    render() {
        const { src, type, } = this.props;
        return (
            <source src={src} type={type} />
        );
    }
}

HLSSource.propTypes = {
    src: PropTypes.string,
    type: PropTypes.string,
    videoQuality: PropTypes.number,
};

HLSSource.defaultProps = {
    type: 'application/x-mpegURL',
}
