import React, { Fragment, PureComponent } from 'react';
import PropTypes from "prop-types";
import DetectLocale from '../util/DetectLocale';


// We rely on TextCard.css being imported by TextCard.js for
// some extra styles.

import { makeStyles } from "@material-ui/core/styles";

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';

import L from '../util/L10n';
import InvisibleImg from '../a11y/InvisibleImg';

const useStyles = makeStyles((theme) => ({
    item: {
        padding: 0,
        marginBottom: 8,
    },
    icon: {
        minWidth: 32,
        color: '#249dc5',
        paddingTop: 2,
    },
    text: {
        fontWeight: 500,
        // fontSize: '1rem',
        color: '#249dc5',
        // '&:hover': {
        //     textDecoration: 'underline',
        // },
        marginTop: 0,
    },
    accordion: {
        width: '100%',
        backgroundColor: 'transparent',
    },
    accordionSummary: {
        padding: 0,
    },
    expandIcon: {
        color: '#249dc5',
    },
    accordionDetailsRoot: {
        padding: '8px 0 16px',
        display: 'flex',
        flexDirection: 'column',
    },
    accordionDetailsLogo: {
        margin: '8px 0',
        textAlign: 'right',
    },
    accordionDetailsLogoImage: {
        maxWidth: '50%',
        maxHeight: 64,
    },
    accordionDetailsAttrDate: {
        color: '#707070',
    },
    accordionDetailsAttrHead: {
        color: '#707070',
    },
    accordionDetailsAttrName: {
        color: '#707070',
    },
    accordionDetailsSubheader: {
        margin: '16px 0 -8px',
    },
    accordionDetailsHeader: {
        fontWeight: 500,
        margin: '8px 0 16px',
    },
    accordionDetailsText: {
        width: '100%',
        hyphens: 'auto',
        '& strong': {
            fontWeight: 500,
        },
        '& em': {
            fontStyle: 'italic',
        },
        '& s': {
            textDecoration: 'line-through',
        },
        '& u': {
            textDecoration: 'underline',
        },
        '& sup': {
            verticalAlign: 'super',
            fontSize: 'smaller',
        },
        '& sub': {
            verticalAlign: 'sub',
            fontSize: 'smaller',
        },
        '& p': {
            margin: '6px 0',
        },
    },
    accordionDetailsCopyrightStars: {
        color: '#707070',
        margin: '22px 0 0',
        textAlign: 'center',
        letterSpacing: '0.5rem',
    },
    accordionDetailsCopyright: {
        color: '#707070',
        margin: '12px 0 8px',
    },
    divider: {
        height: 2,
        backgroundColor: '#f3f1f3',
        marginBottom: 8,
    },
}));

class PressReleaseItem extends PureComponent {
    render() {
        const { pressRelease, index, classes, userLang } = this.props;
        const {
            title, logoURL, senderDate, textfeld, senderSign, subheader, header,
            text, copyright,
        } = pressRelease;
        let senderDateStr = null;
        if (senderDate) {
            const locale = userLang ? userLang :
                DetectLocale.getPreferredLocale();
            const dateOptions =
                { month: 'long', day: 'numeric', year: 'numeric' };
            const dateValue = new Date(senderDate);
            senderDateStr = dateValue.toLocaleDateString(locale, dateOptions);
        }
        const senderHead = textfeld;
        const textContent = { __html: text };
        const alt = L.t('infosPressReleaseItem', userLang);
        const expandIcon =
            <ExpandMoreIcon
                className={classes.expandIcon}
            />;
        const logoElem = logoURL ?
            <div
                className={classes.accordionDetailsLogo}
            >
                <img
                    className={classes.accordionDetailsLogoImage}
                    src={logoURL}
                    alt={senderSign || ''}
                />
            </div> : null;
        const attrDateElem = senderDateStr ?
            <Typography
                component='div'
                variant='body1'
                className={classes.accordionDetailsAttrDate}
            >
                {senderDateStr}
            </Typography> : null;
        const attrHeadElem = senderSign && senderHead ?
            <Typography
                component='div'
                variant='body1'
                className={classes.accordionDetailsAttrHead}
            >
                {senderHead}
            </Typography> : null;
        const attrNameElem = senderSign ?
            <Typography
                component='div'
                variant='body1'
                className={classes.accordionDetailsAttrName}
            >
                {senderSign}
            </Typography> : null;
        const subheaderElem = subheader ?
            <Typography
                component='div'
                className={classes.accordionDetailsSubheader}
            >
                {subheader}
            </Typography> : null;
        const headerElem = header ?
            <Typography
                component='div'
                variant='h6'
                className={classes.accordionDetailsHeader}
            >
                {header}
            </Typography> : null;
        const copyrightElem = copyright ?
            <Fragment>
                <Typography
                    component='div'
                    variant='body1'
                    className={classes.accordionDetailsCopyrightStars}
                >
                    ***
                </Typography>
                <Typography
                    component='div'
                    variant='body1'
                    className={classes.accordionDetailsCopyright}
                >
                    {copyright}
                </Typography>
            </Fragment> : null;
        return (
            <Fragment>
                <ListItem
                    disableGutters={true}
                    classes={{ root: classes.item, }}
                >
                    <Accordion
                        className={classes.accordion}
                        elevation={0}
                    >
                        <AccordionSummary
                            className={classes.accordionSummary}
                            id={'panel-' + index + '-header'}
                            aria-controls={'panel-' + index + '-content'}
                            expandIcon={expandIcon}
                        >
                            <ListItemIcon classes={{ root: classes.icon, }}>
                                <DescriptionOutlinedIcon fontSize='small' />
                                <InvisibleImg alt={alt} />
                            </ListItemIcon>
                            <Typography
                                className={classes.text}
                                component='h3'
                                variant='body1'
                            >
                                {title}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            id={'panel-' + index + '-content'}
                            classes={{ root: classes.accordionDetailsRoot }}
                        >
                            {logoElem}
                            {attrDateElem}
                            {attrHeadElem}
                            {attrNameElem}
                            {subheaderElem}
                            {headerElem}
                            <Typography
                                component='div'
                                variant='body1'
                                className={classes.accordionDetailsText}
                                dangerouslySetInnerHTML={textContent}
                            />
                            {copyrightElem}
                        </AccordionDetails>
                    </Accordion>
                </ListItem>
                <Divider
                    classes={{ root: classes.divider }}
                />
            </Fragment>
        );


    }
}

PressReleaseItem.propTypes = {
    pressRelease: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    userLang: PropTypes.string,
};

export default function _PressReleaseItem(props) {
    const classes = useStyles();
    return <PressReleaseItem {...props} classes={classes} />;
}
