import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from '@material-ui/core/styles';

import Iframe from 'react-iframe';

const useStylesByAspectRatio = (aspectRatio) => {
    return makeStyles((theme) => ({
        iframe: {
            width: '100%',
            aspectRatio,
            border: 0,
        },
    }))
};

class YoutubeCardContent extends PureComponent {
    render() {
        const { data, classes } = this.props;
        const { youtubeId } = data;
        const allow = 'accelerometer; autoplay; clipboard-write; ' +
            'encrypted-media; gyroscope; picture-in-picture; web-share';
        if (youtubeId) {
            const src = 'https://www.youtube-nocookie.com/embed/' + youtubeId +
                '?modestbranding=1&rel=0';
            return (
                <Iframe
                    className={classes.iframe}
                    src={src}
                    frameborder='0'
                    width='100%'
                    allowfullscreen
                    allow={allow}
                />
            );
        } else {
            return null;
        }
    }
}

YoutubeCardContent.prototypes = {
    data: PropTypes.object.isRequired,
    aspectRatio: PropTypes.string.isRequired,
};

export default function _YoutubeCardContent(props) {
    const useStyles = useStylesByAspectRatio(props.aspectRatio);
    const classes = useStyles();
    return <YoutubeCardContent {...props} classes={classes} />;
}
