import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';

import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ArchiveRoundedIcon from '@material-ui/icons/ArchiveRounded';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SettingsIcon from '@material-ui/icons/Settings';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Box from '@material-ui/core/Box';

import L from './util/L10n';
import InvisibleImg from './a11y/InvisibleImg';
import logo from './images/logo_white.svg';

const useStyles = makeStyles((theme) => ({
    title: {
        display: 'flex',
        flexDirection: 'row',
        margin: 0,
        alignItems: 'center',
    },
    logo: {
        height: '42px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 20px 3px',
            margin: '-1px 0px 1px',
        },
        [theme.breakpoints.up('sm')]: {
            padding: '12px 20px 8px',
        },
    },
    arrowButton: {
        color: 'white',
        padding: '0px',
        marginLeft: '48px',
        marginRight: '8px',
    },
    arrow: {
        fontSize: '2.8rem',
    },
    paper: {
        color: 'white',
        background: '#006883',
    },
    labelLight: {
        color: 'rgba(255, 255, 255, 0.78)',
    },
    labelSmall: {
        fontSize: 14,
    },
    icon: {
        color: 'white',
    },
    iconLight: {
        color: 'rgba(255, 255, 255, 0.78)',
    },
    listItemIconClass: {
        minWidth: 40,
    },
    divider: {
        backgroundColor: 'rgba(255, 255, 255, 0.22)',
        margin: '12px 0',
    },
}));

class MainMenu extends PureComponent {
    render() {
        if (this.props.appEmbedded)
            return null;

        const { anchor, open, onOpen, onClose, classes,
            userLang, } = this.props;
        const closeAltText = L.t('menuCloseButton_ariaLabel', userLang);
        return (
            <SwipeableDrawer
                anchor={anchor}
                open={open}
                onOpen={onOpen}
                onClose={onClose}
                classes={{ paper: classes.paper, }}
            >
                <div
                    role="presentation"
                    onClick={onClose}
                    onKeyDown={onClose}
                >
                    <List component='nav'>
                        <Box className={classes.title}>
                            <Box>
                                <img className={classes.logo}
                                    src={logo}
                                    alt='' />
                            </Box>
                            <Box>
                                <IconButton
                                    onClick={onClose}
                                    className={classes.arrowButton}
                                >
                                    <ChevronLeftIcon
                                        className={classes.arrow} />
                                    <InvisibleImg alt={closeAltText} />
                                </IconButton>
                            </Box>
                        </Box>
                        {this.createMenuItems(userLang)}
                        {/* this.createMenuItemDarkMode(userLang) */}
                        {/* this.createMenuItemTextOnly(userLang) */}
                    </List>
                </div>
            </SwipeableDrawer >
        );
    }

    config() {
        const { classes } = this.props;
        return [
            {
                type: 'divider',
                label: 'divider-0',
            },
            {
                type: 'item',
                label: 'menuItemArchive',
                path: '/archiv',
                icon: ArchiveRoundedIcon,
                iconClass: classes.icon,
            },
            {
                type: 'item',
                label: 'menuItemAboutUs',
                path: '/ueber-uns',
                icon: PeopleAltIcon,
                iconClass: classes.icon,
            },
            {
                type: 'divider',
                label: 'divider-1',
            },
            {
                type: 'item',
                label: 'menuItemSettings',
                labelClass: classes.labelLight,
                path: '/einstellungen',
                icon: SettingsIcon,
                iconClass: classes.iconLight,
            },
            {
                type: 'divider',
                label: 'divider-2',
            },
            {
                type: 'item',
                label: 'menuItemImprint',
                labelClass: classes.labelLight,
                labelFontClass: classes.labelSmall,
                path: '/impressum',
            },
            {
                type: 'item',
                label: 'menuItemDataprotection',
                labelClass: classes.labelLight,
                labelFontClass: classes.labelSmall,
                path: '/datenschutz',
            },
        ];
    }

    createMenuItems(userLang) {
        const items = [];
        const { location, classes } = this.props;
        for (let conf of this.config()) {
            switch (conf.type) {
                case 'item':
                    let listItemIcon = null;
                    if (conf.icon) {
                        const Icon = conf.icon;
                        listItemIcon =
                            <ListItemIcon
                                className={classes.listItemIconClass}
                            >
                                <Icon
                                    className={conf.iconClass}
                                />
                            </ListItemIcon>;
                    }
                    const state = {
                        back: location.pathname,
                    }
                    const link = {
                        pathname: conf.path,
                        search: location.search,
                        state: state,
                    }
                    items.push(
                        <ListItem
                            button
                            key={conf.label}
                            component={Link}
                            to={link}
                        >
                            {listItemIcon}
                            <ListItemText
                                classes={{
                                    root: conf.labelClass,
                                    primary: conf.labelFontClass,
                                }}
                                // className={conf.labelClass}
                                primary={L.t(conf.label + '_text', userLang)}
                            />
                        </ListItem >
                    );
                    break;
                case 'divider':
                    items.push(
                        <Divider
                            className={classes.divider}
                            key={conf.label}
                        />
                    );
                    break;
                default: // ignore.
            }
        }
        return items;
    }
}

MainMenu.propTypes = {
    anchor: PropTypes.string,
    open: PropTypes.bool,
    onOpen: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    darkMode: PropTypes.bool,
    toggleDarkMode: PropTypes.func.isRequired,
    textOnly: PropTypes.bool,
    setTextOnly: PropTypes.func.isRequired,
    appEmbedded: PropTypes.bool,
    userLang: PropTypes.string,
};

MainMenu.defaultProps = {
    anchor: 'left',
};

export default withRouter((props) => {
    const classes = useStyles();
    return <MainMenu {...props} classes={classes} />;
});
