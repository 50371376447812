import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import getDealInfo from '../data/DealReader';
import DealExpiredBox from './DealExpiredBox';
import DealButtonContent from './DealButtonContent';
import L from "../util/L10n";

const useStyles = makeStyles((theme) => ({
    dealButton: {
    },
    fallbackDiv: {
        width: '100%',
        height: '48px',
        textAlign: 'center',
    },
}));

class DealButton extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            maxCallsChecked: false,
            maxCallsAvailable: false,
            maxCallsFailed: false,
        };
        this.onDataReceived = this.onDataReceived.bind(this);
        this.reload = this.reload.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    load() {
        const { data, entry } = this.props;
        if (!!data.maxCalls) {
            getDealInfo(entry.id, data.id, this.onDataReceived)
                .catch((error) => {
                    this.setState({ maxCallsFailed: true });
                });
        } else {
            this.setState({
                maxCallsChecked: true, maxCallsAvailable: true,
            });
        }
    }

    onDataReceived(data) {
        if (data && data.state && data.state === 'success') {
            this.setState({
                maxCallsChecked: true,
                maxCallsAvailable: true,
            });
        } else if (data && data.state && data.state === 'finished') {
            this.setState({
                maxCallsChecked: true,
                maxCallsAvailable: false,
            });
        } else {
            this.setState({ maxCallsFailed: true });
        }
    }

    reload() {
        const { data } = this.props;
        if (!!data.maxCalls) {
            const siht = this;
            this.setState({
                maxCallsChecked: false,
                maxCallsAvailable: false,
                maxCallsFailed: false,
            }, () => {
                setTimeout(() => {
                    siht.load();
                }, 20);
            });
        }
    }

    render() {
        const { classes, userLang, data, entry } = this.props;
        const {
            maxCallsChecked, maxCallsAvailable, maxCallsFailed,
        } = this.state;
        if (maxCallsChecked && maxCallsAvailable) {
            return (
                <DealButtonContent
                    data={data}
                    entry={entry}
                    userLang={userLang}
                    recheckMaxCalls={this.reload}
                />
            );
        } else if (maxCallsChecked) {
            return (
                <DealExpiredBox
                    text={L.t('dealMaxCallsEnded', userLang)}
                />
            );
        } else if (maxCallsFailed) {
            return (
                <div className={classes.dealButton}>
                    {L.t('dealMaxCallsFailed', userLang)}
                </div>
            );
        } else {
            return (
                <div className={classes.dealButton}>...</div>
            );
        }
    }
}

DealButton.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    entry: PropTypes.object.isRequired,
    userLang: PropTypes.string,
};

export default function _DealButton(props) {
    const classes = useStyles();
    return (
        <DealButton
            {...props}
            classes={classes}
        />
    );
}
