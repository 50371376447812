import React from 'react'
import ScrambledBox from "./ScrambledBox";
import ScrambledDroppable from "./ScrambledDroppable";


export default function ScrambledTarget({cWidth, text, observable}) {


    function renderSquare(i, size, character, index) {

        return (
            <div key={i}
                 style={{
                     margin: 4,
                     width: size,
                     minHeight: size,
                     backgroundColor: 'rgb(235, 235, 235)',
                     borderColor: 'rgb(220, 220, 220)',
                     borderRadius: '20%',
                     borderStyle: 'solid',
                     borderWidth: 1
                 }}>
                <ScrambledBox>
                    <ScrambledDroppable width={size} showAt={showAt} observable={observable} expected={character}
                                        index={index}></ScrambledDroppable>
                </ScrambledBox>
            </div>
        )
    }

    function renderSpace(i, size) {
        return (
            <div key={i}
                 style={{margin: 8, width: 1, minHeight: size, backgroundColor: "transparent", borderRadius: '20%'}}>

            </div>
        )

    }

    function renderLine(i, squares) {

        return (
            <div key={i}>
                <div style={{
                    width: {cWidth},
                    height: {size},
                    minHeight: {size},
                }}>
                    <div
                        style={{
                            width: {cWidth},
                            height: {size},
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'center'
                        }}
                    >
                        {squares}
                    </div>
                </div>
            </div>
        );
    }

    function showAt(position) {
        if (observable) observable.notify(position)
    }

    const size = cWidth / 8 - 1;


    const strLines = []
    let currentLine = ""

    const words = text.split(' ');
    for (let i = 0; i < words.length; i++) {
        let word = words[i];

        while (word.length > 8) {
            if (currentLine.length > 0) {
                strLines.push(currentLine);
                currentLine = "";
            }
            strLines.push(word.substring(0, 8));
            word = word.substring(8);
        }
        if (currentLine.length === 0) {
            currentLine = word;
        } else if (word.length + currentLine.length <= 7) {
            currentLine = currentLine.concat(' ', word);
        } else {
            strLines.push(currentLine);
            currentLine = word;
        }
    }
    if (currentLine.length !== 0) {
        strLines.push(currentLine);
    }

    let lines = []
    let index = 0;
    for (let i = 0; i < strLines.length; i++) {
        const strLine = strLines[i];
        let line = [];
        for (let j = 0; j < strLine.length; j++) {
            const c = strLine[j];
            if (c === ' ') {
                line.push(renderSpace(j, size - 8))
            } else {
                line.push(renderSquare(j, size - 8 - 2, c, index));
                index++
            }
        }
        lines.push(renderLine(i, line));
    }
    observable.setResultLength(index);


    return (
        <div style={{
            marginTop: '16px',
            marginBottom: '16px',
            display: 'flex',
            justifyContent: 'center',
        }}>
            <div style={{
                border: '1px solid gray',
                borderRadius: '10px'
            }}>
                {lines}
            </div>
        </div>
    )
}