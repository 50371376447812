import axios from 'axios';

import config from '../config/api.json';

export default async function getViewData(viewName, lang, onDataReceived, port,
    token, date) {
    const headers = token ? { 'X-WP-Nonce': token, } : {};
    const withCredentials = !!token;
    const datePath = date && token ? '/' + date : '';
    const langPath = lang ? '/' + lang : '';
    let url = config.api_base + config.entry_path + viewName +
        langPath + datePath;
    if (port && port !== 0) {
        url = "http://127.0.0.1:" + port + "/api" + config.entry_path + viewName;
        //console.log(url);
    }
    const response = await axios.get(url, {
        headers: headers,
        withCredentials: withCredentials,
        transformResponse: [(data) => {
            return data
        }],
    });
    if (response && 200 === response.status && response.data) {
        const lastModified = response.headers['last-modified'];
        onDataReceived(JSON.parse(response.data), lastModified);
    } else {
        throw new Error('request failed', response);
    }
}
