import React from 'react'

import Lottie from 'react-lottie-player'
import lottieJson from './cards/random.json'

import Config from './config/ui_settings.json';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";


export default function GlobalAnimation({onStop, animationData, loopAnimation}) {

    let lottieData = lottieJson;
    if(animationData !== null &&  animationData) {
        lottieData = animationData;
    }

    let loop = false
    if(loopAnimation !== null) {
        loop = loopAnimation;
    }


    function onComplete() {
        onStop();
    }


    const theme = useTheme();
    const isLandscape = useMediaQuery('@media (orientation: landscape)');
    const isWideScreen =
        useMediaQuery(theme.breakpoints.up(Config.entryWidescreenMinWidth));
    const twoColumnLayout = isLandscape || isWideScreen;

    let grid;
    let lottieEntry = <Lottie style={{height: '100%'}}
                              loop={loop}
                              animationData={lottieData}
                              play
                              onComplete={onComplete}
    />;

    if (twoColumnLayout) {
        grid = <Grid style={{height: '100%'}} container direction='row' justify='center' alignItems='center'
                     spacing={Config.gridSpacing}>
            <Grid item xs={3}/>
            <Grid style={{height: '100%'}} item xs={6}>
                {lottieEntry}
            </Grid>
            <Grid item xs={3}/>

        </Grid>;
    } else {
        grid = <Grid style={{height: '100%'}} item xs={12}>
            {lottieEntry}
        </Grid>;
    }


    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            backgroundColor: 'rgba(255, 255, 255, 0.9)'
        }}>

            <div style={{height: '100%', paddingTop: 40}}>
                <Container style={{height: '100%'}}
                           maxWidth={Config.containerMaxWidth}
                           component='main'

                >
                    {grid}

                </Container>
            </div>
        </div>
    );

}

