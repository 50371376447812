import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Icon from '@material-ui/core/Icon';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';

const useStyles = makeStyles((theme) => ({
    headline: {
        margin: 0,
        paddingTop: 24,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-start',
    },
    title: {
        color: '#000000',
        paddingTop: 24,
    },
    titleWithIcon: {
        color: '#000000',
    },
    icon: {
        minWidth: 28,
        color: '#000000',
    },
}));

class SectionTitle extends PureComponent {
    render() {
        const { title, showMediaIcon, classes, } = this.props;
        if (title) {
            if (showMediaIcon) {
                return (
                    <h2 className={classes.headline}>
                        <Icon className={classes.icon} component='div'>
                            <InsertPhotoIcon fontSize='small' />
                        </Icon>
                        <Typography
                            variant='subtitle2'
                            component='div'
                            className={classes.titleWithIcon}
                        >
                            {title}
                        </Typography>
                    </h2>
                );
            } else {
                return (
                    <Typography
                        variant='subtitle2'
                        component='h2'
                        className={classes.title}
                    >
                        {title}
                    </Typography>
                );
            }
        } else {
            return null;
        }
    }
}

SectionTitle.propTypes = {
    title: PropTypes.string,
    showMediaIcon: PropTypes.bool,
};

export default function _SectionTitle(props) {
    const classes = useStyles();
    return <SectionTitle {...props} classes={classes} />;
}
