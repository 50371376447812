export default class CarouselManager {
    constructor() {
        this.items = [];
        this.players = [];
    }

    setFocusHandler(handler) {
        this.focusHandler = handler;
    }

    setBlurHandler(handler) {
        this.blurHandler = handler;
    }

    addItem(item, pos) {
        if (!this.items[pos]) {
            this.items[pos] = item;
            if (this.focusHandler && item.setFocusHandler) {
                item.setFocusHandler(this.focusHandler);
            }
            if (this.blurHandler && item.setBlurHandler) {
                item.setBlurHandler(this.blurHandler);
            }
        }
    }

    addPlayer(item) {
        this.players.push(item);
    }

    clear() {
        this.items = [];
        this.players = [];
    }

    change(pos) {
        for (let i = 0; i < this.items.length; i += 1) {
            const item = this.items[i];
            if (item && item.setTabIndex) {
                item.setTabIndex(i === pos);
            }
        }
        for (let i = pos; i < pos + 2; i += 1) {
            const item = this.items[i];
            if (item && item.loadImage) {
                item.loadImage();
            }
        }
        for (let item of this.players) {
            if (item && item.pause) {
                item.pause();
            }
        }
    }
}
