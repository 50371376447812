import React, { PureComponent, lazy, Suspense, } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';

import L from "../util/L10n";
import TextUtil from '../util/TextUtil';
import CompetitionCardDurationBox from '../forms/CompetitionCardDurationBox';
import CompetitionCardExpiredBox from '../forms/CompetitionCardExpiredBox';
const CompetitionCardForm = lazy(() => import('../forms/CompetitionCardForm'));

const useStyles = makeStyles((theme) => ({
    competitionCard: {

    },
    title: {
        color: '#000000',
        marginBottom: 3,
    },
    divider: {
        height: 2,
        backgroundColor: '#f3f1f3',
        marginBottom: 8,
    },
    formFallback: {
        width: '100%',
        height: '48px',
        textAlign: 'center',
    },
}));

class CompetitionCard extends PureComponent {
    _competitionEnded(end) {
        const now = new Date();
        return !!end && now > end;
    }

    _competitionOpen(start, end) {
        const now = new Date();
        if (this._competitionEnded(end)) {
            return false;
        }
        return !start || now >= start;
    }

    render() {
        const { classes, data, userLang, lockLayout, entryId, } = this.props;
        const title = TextUtil.isEmptyText(data.title) ?
            L.t('competitionTitle', userLang) : data.title;
        const requestEmail = 'optional' === data.email ||
            'mandatory' === data.email;
        const requiredEmail = 'mandatory' === data.email;
        const requestPhone = 'optional' === data.phone ||
            'mandatory' === data.phone;
        const requiredPhone = 'mandatory' === data.phone;
        const requestFreetext = 'optional' === data.freetext ||
            'mandatory' === data.freetext;
        const requiredFreetext = 'mandatory' === data.freetext;

        const startDate = data.startDate ? new Date(data.startDate) : null;
        const endDate = data.endDate ? new Date(data.endDate) : null;
        const showDates = !!data.showEnddate && (!!startDate || !!endDate);
        if (this._competitionOpen(startDate, endDate)) {
            return (
                <div className={classes.competitionCard}>
                    <CompetitionCardDurationBox
                        showDates={showDates}
                        startDate={startDate}
                        endDate={endDate}
                        labelDuration={L.t('competitionDurationLabelDuration',
                            userLang)}
                        labelFrom={L.t('competitionDurationLabelFrom',
                            userLang)}
                        labelTo={L.t('competitionDurationLabelTo', userLang)}
                        userLang={userLang}
                    />
                    <Typography
                        variant='subtitle2'
                        component='h2'
                        classes={{ root: classes.title, }}
                    >
                        {title}
                    </Typography>
                    <Divider
                        classes={{ root: classes.divider, }}
                    />

                    <Suspense fallback={
                        <div className={classes.formFallback}>...</div>
                    }>
                        <CompetitionCardForm
                            userLang={userLang}
                            requestEmail={requestEmail}
                            requiredEmail={requiredEmail}
                            requestPhone={requestPhone}
                            requiredPhone={requiredPhone}
                            requestFreetext={requestFreetext}
                            requiredFreetext={requiredFreetext}
                            freetextLabel={data.freetextLabel}
                            agbLabel={data.agbLabel}
                            buttonText={data.buttonText}
                            buttonColor={data.buttonColor}
                            buttonStyle={data.buttonStyle}
                            competition_id={data.id}
                            competition_title={data.title}
                            entry_id={'' + entryId}
                            lockLayout={lockLayout}
                        />
                    </Suspense>
                </div>
            );
        } else {
            const text = L.t(this._competitionEnded(endDate) ?
                'competitionDurationEnded' : 'competitionDurationNotStarted',
                userLang);
            return (
                <div className={classes.competitionCard}>
                    <CompetitionCardExpiredBox
                        text={text}
                    />
                </div>
            );
        }
    }
}

CompetitionCard.propTypes = {
    data: PropTypes.object.isRequired,
    entryId: PropTypes.number.isRequired,
    userLang: PropTypes.string,
    lockLayout: PropTypes.func,
};

export default function _CompetitionCard(props) {
    const classes = useStyles();
    return <CompetitionCard {...props} classes={classes} />;
}
