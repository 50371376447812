import axios from 'axios';

import config from '../config/api.json';

export default async function getDealInfo(entrId, dealId, onDataReceived) {
    const url = config.api_base + config.deal_path +
        'info/' + entrId + '/' + dealId;
    const response = await axios.get(url, {
        transformResponse: [(data) => {
            return data
        }],
    });
    if (response && 200 === response.status && response.data) {
        onDataReceived(JSON.parse(response.data));
    } else {
        throw new Error('request failed', response);
    }
}
