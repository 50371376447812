import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import L from '../util/L10n';
import getViewData from "../data/ViewReader";

const useStyles = makeStyles((theme) => ({
    tabBorderDiv: {
        position: 'absolute',
        top: 1,
        left: 4,
        bottom: 0,
        right: 4,
        borderTop: '1px solid #d8d8d8',
    },
}));

class TopTabBar extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: false,
            data: null,
            error: null,
        }
        this.onDataReceived = this.onDataReceived.bind(this);
    }

    load() {
        const { appServerPort, previewToken, previewDate } = this.props;
        const lang = 'de';
        getViewData('tabs', lang, this.onDataReceived, appServerPort,
            previewToken, previewDate).catch((error) => {
                //console.log(error)
                this.setState({ error: error })
            });
    }

    onDataReceived(data) {
        this.setState({ data: data, error: null })
    }

    config() {
        return [
            {
                value: 'today',
                path: '/',
                label: 'bottomNavigationBarToday',
                name: 'start',
                always: true,
            },
            {
                value: 'line',
                path: '/linie',
                label: 'bottomNavigationBarLine',
                name: 'line',
                default: true,
            },
            {
                value: 'preview',
                path: '/vorschau',
                label: 'bottomNavigationBarPreview',
                name: 'events',
                default: true,
            },
            {
                value: 'prizes',
                path: '/aktion',
                label: 'bottomNavigationBarPrizes',
                name: 'prizes',
                default: true,
            },
            {
                value: 'deals',
                path: '/deal',
                label: 'bottomNavigationBarDeals',
                name: 'deals',
            },
        ];
    }

    activeConfig() {
        const config = this.config();
        const { data, error } = this.state;
        if (data) {
            return config.filter(page =>
                page.always || data[page.name] > 0);
        } else if (error) {
            return config;
        } else {
            return config.filter(page =>
                page.always || page.default);
        }
    }

    valueByPath(path) {
        if (path && path.length > 1 &&
            (path.charAt(path.length - 1) === '/')) {
            path = path.substr(0, path.length - 1);
        }
        let newValue = false;
        for (let conf of this.activeConfig()) {
            if (path === conf.path) {
                newValue = conf.value;
                break;
            }
        }
        if (newValue !== this.state.value) {
            this.setState({ value: newValue, });
        }
    }

    componentDidMount() {
        this.load();
        this._didUpdateOrMount();
    }

    componentDidUpdate() {
        this._didUpdateOrMount();
    }

    _didUpdateOrMount() {
        this.valueByPath(this.props.location.pathname);
    }

    render() {
        const { appEmbedded, variant, userLang, } = this.props;
        if (appEmbedded)
            return null;

        return (
            <Tabs
                value={this.state.value}
                indicatorColor='primary'
                variant={variant}
                component='nav'
            >
                {this.createTabs(userLang)}
            </Tabs>
        );
    }

    createTabs(userLang) {
        const { classes, } = this.props;
        const tabs = [];
        for (let conf of this.activeConfig()) {
            const link = {
                pathname: conf.path,
                search: this.props.location.search,
            }
            tabs.push(
                <Tab
                    value={conf.value}
                    to={link}
                    component={Link}
                    label={
                        <span>
                            <span>
                                {L.t(conf.label, userLang)}
                            </span>
                            <span className={classes.tabBorderDiv} />
                        </span>
                    }
                    key={conf.value}
                    tabIndex={0}
                />
            );
        }
        return tabs;
    }
}

TopTabBar.propTypes = {
    appEmbedded: PropTypes.bool,
    variant: PropTypes.string,
    userLang: PropTypes.string,
    previewToken: PropTypes.string,
    previewDate: PropTypes.string,
};

TopTabBar.defaultProps = {
    variant: 'fullWidth',
};

export default withRouter((props) => {
    const classes = useStyles();
    return <TopTabBar {...props} classes={classes} />;
});
