import axios from 'axios';

import config from '../config/api.json';

export default async function incrementDealCounter(actionName, data,
    onResponseReceived, onResponseFailed) {

    const url = config.api_base + config.deal_path + actionName;
    const response = await axios.post(url, data, {
        transformResponse: [(data) => data],
    });
    if (response && 200 === response.status && response.data) {
        try {
            const result = JSON.parse(response.data);
            if ('success' === result.state) {
                onResponseReceived();
            } else if (result.state === 'finished') {
                onResponseFailed('deal finished');
            } else {
                onResponseFailed(result.errormessage);
            }
        } catch (error) {
            onResponseFailed('JSON parse error');
        }
    } else {
        onResponseFailed('request failed');
    }
}
