import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import CardMedia from '@material-ui/core/CardMedia';
import Typography from "@material-ui/core/Typography";

import MaybeLink from '../util/MaybeLink';
import AltText from '../util/AltText';
import DefaultImage from '../components/DefaultImage';
import ImageResizerUrl from '../util/ImageResizerUrl';
import EntryUrl from '../util/EntryUrl';

const useStyles = makeStyles((theme) => ({
    cardMediaStyle: {
        height: 'auto',
        backgroundColor: '#d0d0d0',
    },
    cardMediaTextOnly: {
        display: 'block',
        padding: 8,
        color: '#000000',
        backgroundColor: '#ffffff',
    },
}));

class ImageCardContent extends PureComponent {
    constructor(props) {
        super(props);
        const { mediaWidth, mediaHeight, } = props.data;
        const width = mediaWidth && mediaHeight ?
            ImageResizerUrl.getOptimizedWidth(props.imageType, props.deviceSize,
                mediaWidth) :
            null;
        const height = mediaWidth && mediaHeight ?
            ImageResizerUrl.getOptimizedHeight(props.imageType,
                props.deviceSize, mediaWidth, mediaHeight) :
            null;
        const newMediaURL = ImageResizerUrl.getResizeURL(props.data.mediaURL,
            props.imageType, props.deviceSize);
        const mediaURL =
            DefaultImage.getWH(props.imageType, width, height);
        this.state = {
            width: width,
            height: height,
            newMediaURL: newMediaURL,
            mediaURL: mediaURL,
        };

        // Lazy loading with IntersectionObserver.
        this.handleObserved = this._handleObserved.bind(this);
        this.replaceUrl = this._replaceUrl.bind(this);
        if ('IntersectionObserver' in window) {
            const options = {
                root: document.querySelector('#scrollArea'),
                rootMargin: '0px',
            }
            this.observer = new IntersectionObserver(this.handleObserved,
                options);
        }
        this.setFocusHandler = this.setFocusHandler.bind(this);
        this.setBlurHandler = this.setBlurHandler.bind(this);
    }

    componentDidMount() {
        const { manager } = this.props;
        if (manager) {
            manager.addItem(this, 0);
        }
        if (this.imageElement) {
            if (this.observer) {
                this.observer.observe(this.imageElement);
            } else {
                this.replaceUrl();
            }
        }
    }

    componentDidUpdate() {
        const { manager } = this.props;
        if (manager) {
            manager.addItem(this, 0);
        }
    }

    componentWillUnmount() {
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    _handleObserved(entries, observer) {
        for (let entry of entries) {
            if (entry.intersectionRatio > 0.0) {
                this.replaceUrl();
                observer.unobserve(this.imageElement);
            }
        }
    }

    _replaceUrl() {
        this.setState({ mediaURL: this.state.newMediaURL, });
    }

    setFocusHandler(onFocus) {
        this.setState({ onFocus });
    }

    setBlurHandler(onBlur) {
        this.setState({ onBlur });
    }

    linkUrl() {
        let url = null;
        const { parentEntry, location, link, externalLink, } = this.props;
        if (parentEntry) {
            let pathname;
            if(externalLink && externalLink.startsWith("level://")) {
                pathname = location.pathname;
                if(pathname === "/") {
                    pathname = "/heute"
                }
                pathname = pathname + externalLink.substring(8);
            } else {
                pathname = EntryUrl.getPath(parentEntry);
            }
            const state = {
                data: parentEntry,
                back: location.pathname,
            }
            url = {
                pathname: pathname,
                search: location.search,
                state: state,
            }
        } else if (link) {
            url = link;
        }
        return url;
    }

    altText() {
        const { data, } = this.props;
        const text = new AltText();
        text.append(data.subheader);
        text.append(data.header);
        text.append(data.text);
        return text.toString();
    }

    textSubheader() {
        const { data } = this.props;
        if (data.header && data.header.trim() !== '') {
            return data.subheader;
        } else {
            return '';
        }
    }

    textHeader() {
        const { data, parentEntry } = this.props;
        if (data.header && data.header.trim() !== '') {
            return data.header;
        } else if (data.subheader && data.subheader.trim() !== '') {
            return data.subheader;
        } else if (parentEntry && parentEntry.title) {
            return parentEntry.title;
        } else {
            return '';
        }
    }

    textText() {
        const { data } = this.props;
        return data.text;
    }

    _onClickImageCard() {
        if (window.FgfChannel) {
            const { parentEntry } = this.props;
            window.FgfChannel.postMessage("onPathChanged:/beitrag/" +
                parentEntry.id);
        }
    }

    render() {
        let {
            classes, externalLink, onFocusVisible, onFocus, onBlur, manager,
            textOnly, textOnlyHeadLevelIncrement,
        } = this.props;
        const textHeadComponent = 'h' + (2 + textOnlyHeadLevelIncrement);
        if (manager) {
            onFocusVisible = this.state.onFocus;
            onBlur = this.state.onBlur;
        }
        const cardMedia = textOnly ?
            <div className={classes.cardMediaTextOnly}>
                <Typography
                    variant='subtitle1'
                    component='div'
                >
                    {this.textSubheader()}
                </Typography>
                <Typography
                    variant='h6'
                    component={textHeadComponent}
                >
                    {this.textHeader()}
                </Typography>
                <Typography
                    variant='body1'
                    component='div'
                >
                    {this.textText()}
                </Typography>
            </div> :
            <CardMedia
                className={classes.cardMediaStyle}
                image={this.state.mediaURL}
                component='img'
                width={this.state.width}
                height={this.state.height}
                alt={this.altText()}
                ref={element => { this.imageElement = element; }}
            />;
        let checkedExternalLink;
        if(externalLink && !externalLink.startsWith("level://")) {
            checkedExternalLink = externalLink;
        }
        return (
            <MaybeLink
                onClick={window.FgfChannel && !checkedExternalLink ?
                    this._onClickImageCard.bind(this) : null}
                to={this.linkUrl()}
                externalLink={checkedExternalLink}
                tabIndex={0}
                onFocusVisible={onFocusVisible}
                onFocus={onFocus}
                onBlur={onBlur}
            >
                {cardMedia}
            </MaybeLink>
        );
    }
}

ImageCardContent.prototypes = {
    data: PropTypes.object.isRequired,
    parentEntry: PropTypes.object,
    link: PropTypes.string,
    externalLink: PropTypes.string,
    imageType: PropTypes.string,
    deviceSize: PropTypes.string,
    onFocusVisible: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    manager: PropTypes.object,
    textOnly: PropTypes.bool,
    textOnlyHeadLevelIncrement: PropTypes.number,
};

ImageCardContent.defaultProps = {
    imageType: 'big',
    textOnlyHeadLevelIncrement: 0,
};

export default withRouter((props) => {
    const classes = useStyles();
    return <ImageCardContent {...props} classes={classes} />;
});
