import DetectLocale from './DetectLocale';
import l10n_de from '../l10n/de.json';
import l10n_en from '../l10n/en.json';

export default class L10n {
    static t(label, userLang, params) {
        const lang = userLang ? userLang : DetectLocale.getUserLanguage();
        const l10n = lang === 'en' ? l10n_en : l10n_de;

        if (label in l10n) {
            return L10n.replace(l10n[label], params);
        } else {
            console.warn('l10n label ' + label + ' not defined');
            return label
        }
    }

    static replace(template, params) {
        let result = template;
        for (let element in params) {
            const key = new RegExp('{\\s*' + element + '\\s*}', 'g');
            const val = params[element];
            result = result.replaceAll(key, val);
        }
        return result.replaceAll(/{[^{}]*}/g, '');
    }
}
