import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';

import TextUtil from '../util/TextUtil';
import AccordionItem from './AccordionItem';

const useStyles = makeStyles((theme) => ({
    infosCard: {},
    title: {
        color: '#000000',
        marginBottom: 3,
    },
    divider: {
        height: 2,
        backgroundColor: '#f3f1f3',
        marginBottom: 8,
    },
    list: {
        marginBottom: 6,
    },
}));

class AccordionCard extends PureComponent {
    addItem(accordionItems, head, content, userLang, index) {
        if(!TextUtil.isEmptyText(head) && !TextUtil.isEmptyText(content)) {
            accordionItems.push(
                <AccordionItem
                    key={"ai_" + index}
                    index={index}
                    head={head}
                    content={content}
                    userLang={userLang}
                />);
            index++;
        }
        return index;
    }

    render() {
        const { classes, data, userLang, } = this.props;
        let accordionItems = [];
        let index = 0;
        this.addItem(accordionItems, data.akkordeon1, data.akkordeon1text, userLang, index);
        this.addItem(accordionItems, data.akkordeon2, data.akkordeon2text, userLang, index);
        this.addItem(accordionItems, data.akkordeon3, data.akkordeon3text, userLang, index);
        this.addItem(accordionItems, data.akkordeon4, data.akkordeon4text, userLang, index);
        this.addItem(accordionItems, data.akkordeon5, data.akkordeon5text, userLang, index);

        const title = TextUtil.isEmptyText(data.title) ?
            "" : data.title.toUpperCase();
        return (
            <div className={classes.quoteCard}>
                <Typography
                    variant='subtitle2'
                    component='h2'
                    classes={{ root: classes.title }}
                >
                    {title}
                </Typography>
                <Divider
                    classes={{ root: classes.divider }}
                />
                <List disablePadding={true} className={classes.list}>
                    {accordionItems}
                </List>
            </div>
        );
    }
}

AccordionCard.propTypes = {
    data: PropTypes.object.isRequired,
    userLang: PropTypes.string,
    pressReleases: PropTypes.array,
};

export default function _AccordionCard(props) {
    const classes = useStyles();
    return <AccordionCard {...props} classes={classes} />;
}
