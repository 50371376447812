export default class VoucherErrorCodes {
    static getLabel(code) {
        switch(code) {
            case 'NO QRCODE ACTION': return('qrErrorNoQrAction');
            case 'WRONG PASSWORD': return('qrErrorWrongPassword');
            case 'ACTION ENDED': return('qrErrorActionEnded');
            case 'RESOLVING ANOTHER VOUCHER': return('qrErrorResolvingAnother');
            case 'CODE NOT FOUND': return('qrErrorCodeNotFound');
            case 'CODE USED': return('qrErrorCodeUsed');
            default: return('qrErrorUnknownError');
        }
    }
}
