import locales from '../config/locales.json';

export default class DetectLocale {
    static getUserLanguage() {
        const userLocales = DetectLocale.getUserLocales();
        let lang = locales.defaultLanguageCode;
        if (userLocales) {
            const langCodes = new Set(locales.languageCodes);
            for (let i = 0; i < userLocales.length; i++) {
                const langCode = userLocales[i].substring(0, 2).toLowerCase();
                if (langCodes.has(langCode)) {
                    lang = langCode;
                    break;
                }
            }
        }
        return lang;
    }

    static isLanguageSupported(userLang) {
        const langCodes = new Set(locales.languageCodes);
        return langCodes.has(userLang);
    }

    static getUserLocales() {
        let userLocales = null;

        if (navigator) {
            try {
                if (navigator.languages && navigator.languages.length) {
                    // preferred languages.
                    userLocales = navigator.languages;
                } else if (navigator.userLanguage) {
                    // IE
                    userLocales = [navigator.userLanguage];
                } else {
                    // the browser language
                    userLocales = [navigator.language];
                }
            } catch (e) {
                userLocales = null;
            }
        }

        return userLocales;
    }

    static getPreferredLocale() {
        const locales = DetectLocale.getUserLocales();
        if (locales)
            return locales[0];
        else
            return null;
    }
}
