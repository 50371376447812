import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';

import L from '../util/L10n';
import DetectLocale from '../util/DetectLocale';

const useStyles = makeStyles((theme) => ({
    date: {
        fontWeight: 500,
        textTransform: 'uppercase',
        color: '#828282',
        fontSize: '0.9rem',
    },
    lang: {
        fontWeight: 500,
        textTransform: 'uppercase',
        textAlign: 'right',
        color: '#828282',
        fontSize: '0.9rem',
    },
    title: {
        fontWeight: 500,
        letterSpacing: '-1px',
    },
}));

class PageTitleItem extends PureComponent {
    render() {
        // TODO Verify browsers do not choke on options. See
        // https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString#Browser_compatibility
        const { classes, name, userLang, level, } = this.props;
        const locale = userLang ? userLang : DetectLocale.getPreferredLocale();
        const dateOptions =
            { weekday: 'long', month: 'long', day: 'numeric', };
        let dateValue = new Date();
        const { previewToken, previewDate, } = this.props;
        if (previewToken && previewDate) {
            dateValue = new Date(previewDate);
        }
        const dateString = dateValue.toLocaleDateString(locale, dateOptions);
        const dateTime = dateValue.toISOString().substring(0, 10);
        return (
            <Grid item xs={12}>
                <Box>
                    <Typography variant='subtitle1' component='span'
                        className={classes.date}>
                        <time dateTime={dateTime}>
                            {dateString}
                        </time>
                    </Typography>
                    <Typography variant='h4' component='h2'
                        className={classes.title}>
                        {level ? level : L.t(name + 'TeaserPageTitle', userLang)}
                    </Typography>
                </Box>
            </Grid>
        );
    }
}

PageTitleItem.propTypes = {
    name: PropTypes.string,
    level: PropTypes.string,
    previewToken: PropTypes.string,
    previewDate: PropTypes.string,
    userLang: PropTypes.string,
    updateUserLang: PropTypes.func,
};

export default function _PageTitleItem(props) {
    const classes = useStyles();
    return <PageTitleItem {...props} classes={classes} />;
}
