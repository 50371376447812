import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Facebook';

import { useMatomo } from '@datapunt/matomo-tracker-react'

import L from "../util/L10n";
import EntryUrl from '../util/EntryUrl';
import InvisibleImg from '../a11y/InvisibleImg';

const useStyles = makeStyles((theme) => ({
    iconButton: {
        padding: 0,
        marginLeft: -10,
    },
    facebookIcon: {
        fontSize: 48,
        color: '#5d7dae',
        borderRadius: 8,
        padding: 4,
    },
}));

class ShareFacebookButton extends PureComponent {
    render() {
        const { classes, userLang, entry, trackShare } = this.props;
        const url = EntryUrl.getUrl(entry);
        const href = 'https://www.facebook.com/sharer/sharer.php?u=' +
            encodeURIComponent(url);
        const altText = L.t('shareFacebookAlt', userLang);
        return (
            <IconButton
                className={classes.iconButton}
                component='a'
                href={href}
                target='_blank'
                rel='noopener'
                onClick={() => {
                    trackShare(url);
                }}
            >
                <FacebookIcon
                    className={classes.facebookIcon}
                />
                <InvisibleImg
                    alt={altText}
                />
            </IconButton>
        );
    }
}

ShareFacebookButton.propTypes = {
    classes: PropTypes.object,
    entry: PropTypes.object,
    trackShare: PropTypes.func,
    userLang: PropTypes.string,
};

export default function _ShareFacebookButton(props) {
    const classes = useStyles();
    const { trackEvent } = useMatomo();
    const onShare = (url) => {
        trackEvent({
            category: 'share',
            action: 'facebook',
            name: url,
        });
        return true;
    };
    return (
        <ShareFacebookButton
            {...props}
            classes={classes}
            trackShare={onShare}
        />
    );
}
