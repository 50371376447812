import config from '../config/api.json';

export default class ImageResizerUrl {
    static getResizeURL(url, imageType, deviceSize) {
        const pattern =
            '^(' + config.api_base + '/wp-content/uploads/.*/)([^/]+)$';
        const matches = url.match(pattern);
        if (!matches) {
            return url;
        }

        const width = this.getOptimizedWidth(imageType, deviceSize);
        const quality = this._getOptimizedQuality(imageType, deviceSize);
        return matches[1] + 'resize/' + width + '/' + quality + '/' +
            matches[2];
    }

    static getOptimizedWidth(imageType, deviceSize, orgWidth) {
        const optimalWidth = 'small' === imageType ? 640 : 1280;
        if (orgWidth && orgWidth < optimalWidth) {
            return orgWidth;
        } else {
            return optimalWidth;
        }
    }

    static getOptimizedHeight(imageType, deviceSize, orgWidth, orgHeight) {
        const optimalWidth = 'small' === imageType ? 640 : 1280;
        const optimalHeight = 'small' === imageType ? 480 : 960;
        if (orgWidth && orgHeight) {
            if (orgWidth < optimalWidth) {
                return orgHeight;
            } else {
                return Math.round(orgHeight * optimalWidth / orgWidth);
            }
        } else {
            return optimalHeight;
        }
    }

    static _getOptimizedQuality(imageType, deviceSize) {
        let quality = 90;
        if ('tablet' === deviceSize) {
            quality = 80;
        } else if ('smartphone' === deviceSize) {
            quality = 70;
        }
        return quality;
    }
}
