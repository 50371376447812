export default class CanonicalUrl {
    static get(path) {
        let { protocol, host } = window.location;
        if (host === 'fahrgastfernsehen.online' ||
            host === 'www.fahrgastfernsehen.online') {
            host = 'www.fahrgastfernsehen.city';
        }
        if (!path) {
            path = "/";
        }
        return protocol + '//' + host + path;
    }
}
