import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import './TextCard.css';

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    text: {
        '& strong': {
            fontWeight: 500,
        },
        '& em': {
            fontStyle: 'italic',
        },
        '& s': {
            textDecoration: 'line-through',
        },
        '& u': {
            textDecoration: 'underline',
        },
        '& sup': {
            verticalAlign: 'super',
            fontSize: 'smaller',
        },
        '& sub': {
            verticalAlign: 'sub',
            fontSize: 'smaller',
        },
        '& p': {
            margin: 0,
        },
    },
}));

class TextCard extends PureComponent {
    render() {
        const { classes, data, } = this.props;
        const text = { __html: data.content, };
        return (
            <Typography
                variant='body1'
                component='div'
                classes={{ root: classes.text, }}
                dangerouslySetInnerHTML={text}
            />
        );
    }
}

TextCard.propTypes = {
    data: PropTypes.object.isRequired,
};

export default function _TextCard(props) {
    const classes = useStyles();
    return <TextCard {...props} classes={classes} />;
}
