import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import CarouselBannerCardContent from './CarouselBannerCardContent';

const useStyles = makeStyles((theme) => ({
    item: {
        float: 'left',
        position: 'relative',
        transitionProperty: 'transform',
    },
}));

class CarouselBannerItem extends PureComponent {
    render() {
        const {
            classes, data, parentEntry, link, externalLink, bgUrl,
            deviceSize, manager, sliderItem, duplicate,
        } = this.props;
        return (
            <div className={classes.item}>
                <CarouselBannerCardContent
                    data={data}
                    parentEntry={parentEntry}
                    link={link}
                    externalLink={externalLink}
                    bgUrl={bgUrl}
                    deviceSize={deviceSize}
                    manager={manager}
                    sliderItem={sliderItem}
                    duplicate={duplicate}
                />
            </div>
        );
    }
}

CarouselBannerItem.prototypes = {
    data: PropTypes.object.isRequired,
    parentEntry: PropTypes.object,
    link: PropTypes.string,
    externalLink: PropTypes.string,
    bgUrl: PropTypes.string,
    deviceSize: PropTypes.string,
    manager: PropTypes.object,
    sliderItem: PropTypes.number,
    duplicate: PropTypes.bool,
};

export default function _CarouselBannerItem(props) {
    const classes = useStyles();
    return <CarouselBannerItem {...props} classes={classes} />
}

