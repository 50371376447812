import React, {PureComponent} from 'react';
import PropTypes from "prop-types";

import {makeStyles} from "@material-ui/core/styles";

import Survey from "./survey/Survey";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {withRouter} from "react-router-dom";
import {useMatomo} from "@datapunt/matomo-tracker-react";
import CookieAccess from "../util/CookieAccess";

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: 8,
        transform: 'translateZ(0)',
    },
    focusedCard: {
        borderRadius: 8,
        transform: 'translateZ(0)',
        outline: '6px solid #888888',
    },
    textOnlyCard: {
        borderRadius: 0,
        transform: 'translateZ(0)',
        outline: '1px solid #888888',
    },
    cardContent: {
        padding: 0,
    },
}));

class SurveyCard extends PureComponent {
    constructor(props) {
        super(props);
        const focused = false;
        const fontSize = 16;
        this.state = { focused, fontSize };
        this.onFocus = this.onFocus.bind(this);
        this.onFontSizeChanged = this.onFontSizeChanged.bind(this);
        this.onAcceptCookie = this.onAcceptCookie.bind(this);
        this.isEnded = this.isEnded.bind(this);
    }

    onAcceptCookie() {
        //pushInstruction('forgetConsentGiven');
        CookieAccess.deleteMatomoCookie();
        CookieAccess.deleteAllSurveyCookies();
        //window.location.assign(window.location.pathname + window.location.search);
        this.setState({  });
    }

    onFocus() {
        const focused = true;
        this.setState({ focused });
    }

    onFontSizeChanged(newSize) {
        const fontSize = newSize;
        const thiss = this;
        setTimeout(function(){thiss.setState({ fontSize });},1);

    }

    isEnded() {
        const {data,} = this.props;
        if(!data.endDate) return false;
        let endDate = new Date(data.endDate.replace(' ', 'T'));
        if (endDate.getTime() === 0) {
            return false;
        }
        return new Date().getTime() > endDate.getTime();
    }


    render() {
        const {
            classes, data, userLang, textOnly,
        } = this.props;
        const { focused, fontSize } = this.state;

        let blurStyle = {};
        let cookieDiv = <div/>;
        let cookieDiv2 = <div/>;
        let cookieText = <div/>
        if(!this.isEnded() && !CookieAccess.hasConsent()) {
            blurStyle = {filter: "blur(2px)"};
            cookieDiv = <div style={{
                position: "absolute",
                height: "100%",
                top: 0,
                left: 0,
                width: "100%",
                background: data.backgroundColor ? data.backgroundColor : "#338C15",
                opacity: 0.5,
            }}/>;
            cookieDiv2 = <div style={{
                position: "absolute",
                height: "100%",
                top: 0,
                left: 0,
                width: "100%",
                background: "rgba(0,0,0,0.3)",
            }}/>;
            cookieText = <div style={{
                position: "absolute",
                top: "50%", left: "50%",
                transform: "translate(-50%,-50%)",
                color: "white",
                width: "90%",
                fontSize: fontSize,
                fontFamily: 'DinforPBR',
                fontWeight: 500,
                textAlign: "center",
            }}
            >
                <p>Um an unseren Umfragen teilnehmen zu können, erlaube der Website, Cookies zu speichern. Wir nutzen
                    Cookies, um Mehrfachabstimmungen zu vermeiden. Die Entscheidung kannst Du jederzeit unter
                    “Datenschutz” zurücksetzen.</p>
                <p><a onClick={this.onAcceptCookie} href={"#"} style={{color: "white"}}>Cookies erlauben</a></p>
            </div>
        }
        return (
            <Card
                className={focused ? classes.focusedCard :
                    (textOnly ? classes.textOnlyCard : classes.card)}
                elevation={textOnly ? 0 : 11}
            >
                <div style={blurStyle}>

                    <CardContent className={classes.cardContent}>
                        <Survey userLang={userLang}
                                parentEntry={this.props.parentEntry}
                                location={this.props.location}
                                surveyId={data.id}
                                question={data.question}
                                answer_1={data.answer1}
                                answer_2={data.answer2}
                                answer_3={data.answer3}
                                answer_4={data.answer4}
                                color={data.backgroundColor ? data.backgroundColor : "#338C15"}
                                onFontSizeChanged={this.onFontSizeChanged}
                                endDate={data.endDate}
                        >
                        </Survey>

                    </CardContent>
                    {cookieDiv2}

                </div>
                {cookieDiv}
                {cookieText}
            </Card>
        );
    }

}

SurveyCard.propTypes = {
    data: PropTypes.object.isRequired,
    parentEntry:PropTypes.object,
    userLang: PropTypes.string,
};


export default withRouter((props) => {
    const classes = useStyles();
    const { pushInstruction } = useMatomo();
    const acceptTrackingConsent = () => {
        pushInstruction('rememberConsentGiven'); // , 9480);
        window.location.assign(window.location.pathname +
            window.location.search);
    };
    return <SurveyCard {...props} acceptTrackingConsent={acceptTrackingConsent}  classes={classes} />;
});

