import React, {useState} from 'react'
import './styles.css';
import ScrambledDraggable from "./ScrambledDraggable";

export default function ScrambledDroppable({width, showAt, observable, expected, index}) {


    const [data, setData] = useState(null);

    function allowDrop(event) {
        event.preventDefault();
    }

    function onDrop(event) {
        event.preventDefault();
        if(data !== null) {
            observable.notify(data.position);
        }
        try {
            const newData = JSON.parse(event.dataTransfer.getData("json"));
            setData(newData);
            if (newData) {
                observable.setDropPosition(newData.position);
                observable.setResult(index, newData.character === expected);
            }
        }catch(_){}
    }

    function onDropped(position, isCopy) {
        if(observable.getIsSolved()) return;

        setData(null);
        observable.setResult(index, null);
        if (!isCopy && data !== null) {
            if(showAt) showAt(data.position);
        }
    }

    const style = {
        width: '100%',
        height: '100%',
        backgroundColor: 'rgb(235, 235, 235)',
        borderRadius: '20%',
        borderColor: 'rgb(220, 220, 220)',
    }

    if (data) {
        return (
            <div
                style={style}
                onDragOver={(event) => allowDrop(event)}
                onDrop={(event) => onDrop(event)}
            >
                <ScrambledDraggable width={width} character={data.character} position={data.position}
                                    onDropped={onDropped} observable={observable}></ScrambledDraggable>
            </div>
        )
    } else {
        return (
            <div
                style={style}
                onDragOver={(event) => allowDrop(event)}
                onDrop={(event) => onDrop(event)}
            >
            </div>
        )
    }
}