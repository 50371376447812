import React, { Fragment } from "react";

import Grid from "@material-ui/core/Grid";

export default class FullBuilder {
    static build(card, pos) {
        const single =
            <Grid item xs={12} key={'gridpos' + pos + 'single'}>
                {card}
            </Grid>;
        const double =
            <Fragment key={'fgridpos' + pos + 'double'}>
                <Grid item xs={3} />
                <Grid item xs={6} key={'gridpos' + pos + 'double'}>
                    {card}
                </Grid>
                <Grid item xs={3} />
            </Fragment>;
        return [single, double];
    }
}
