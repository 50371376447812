import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from "@material-ui/core/Typography";

import { useMatomo } from '@datapunt/matomo-tracker-react';

import L from './util/L10n';
import PageHelmet from './util/PageHelmet';
import CanonicalUrl from './util/CanonicalUrl';

const useStyles = makeStyles({
    h2: {
        marginBlockStart: '0.83em',
        marginBlockEnd: '0.83em',
    },
    h3: {
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
    },
    h4Inset: {
        marginTop: '1em',
        marginLeft: '2rem',
    },
    p: {
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        textAlign: 'left',
        hyphens: 'auto',
    },
    pb: {
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        textAlign: 'justify',
        hyphens: 'auto',
    },
    pInset: {
        marginLeft: '2rem',
    },
});

class A11yPage extends PureComponent {
    componentDidMount() {
        try {
            window.scrollTo(0, 0);
        } catch (e) {
            // Ignore.
        }
    }

    buildMetaData() {
        const canonicalUrl = CanonicalUrl.get('/barrierefreiheit');
        const ogLocale = 'de_DE';
        const ogType = 'website';
        return { canonicalUrl, ogLocale, ogType };
    }

    render() {
        const { classes, userLang, documentTitle, } = this.props;
        const {
            description, canonicalUrl, ogLocale, ogType,
        } = this.buildMetaData();
        const pageHelmet =
            <PageHelmet
                documentTitle={documentTitle}
                metaDescription={description}
                canonicalUrl={canonicalUrl}
                ogTitle={documentTitle}
                ogDescription={description}
                ogUrl={canonicalUrl}
                ogLocale={ogLocale}
                ogType={ogType}
            />;
        return (
            <Box>
                {pageHelmet}
                <Typography variant='h4' component='h2' className={classes.h2}>
                    {L.t('a11yPageTitle', userLang)}
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Diese Erklärung zur Barrierefreiheit gilt für die aktuelle Website des Fahrgastfernsehens.
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Wir sind als Verantwortliche für die Website bemüht, die Vorgaben der harmonisierten europäischen Norm EN 301 549 V2.1.2 (08-2018) einzuhalten. Die Website ist mit den Vorgaben <em>größtenteils</em> vereinbar.
                </Typography>
                <Typography variant='h5' component='h3' className={classes.h3}>
                    Nicht barrierefreie Inhalte der Fahrgastfernsehen-Website:
                </Typography>
                <Typography variant='h6' component='h4' className={classes.h4Inset}>
                    Schrift-Kontraste
                </Typography>
                <Typography variant='body1' component='p' className={classes.pInset}>
                    Schriften werden teilweise mit einem gem. WCAG zu geringen Kontrast vor dem jeweiligen Hintergrund dargestellt. Für Personen mit eingeschränktem Sehvermögen kann dies zu einer schlechteren Lesbarkeit führen.
                </Typography>
                <Typography variant='h6' component='h4' className={classes.h4Inset}>
                    Schrift auf Grafik
                </Typography>
                <Typography variant='body1' component='p' className={classes.pInset}>
                    Die dargestellten Meldungen bestehen aus systemseitigen Gründen in der Regel aus Bild-Tafeln mit integriertem Text. Dadurch lassen sich Schriften nicht beliebig skalieren. Um diesem Umstand entgegenzuwirken, können die Bildtafeln über einen Screen-Reader vorgelesen werden. Darüber hinaus lässt sich auf der Website unter Einstellungen eine Darstellungsvariante lediglich mit Text auswählen, so dass hierdurch eine Skalierbarkeit gegeben ist.
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Die aufgeführten Inhalte sind aus folgenden Gründen nicht barrierefrei: Die Gestaltung und das Format der Website ergeben sich aus den für das Fahrgastfernsehen genutzten IT-Systemen und dem vorgegebenen CI.
                    </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Die Fahrgastfernsehen-Website wird kontinuierlich an die EU-Richtlinie 2016/2102 zur Umsetzung des barrierefreien Internets öffentlicher Stellen angepasst und optimiert. Die identifizierten Defizite und Mängel befinden sich aktuell in einem kontinuierlichen Verbesserungsprozess, der sukzessive abgearbeitet wird.
                </Typography>
                <Typography variant='h5' component='h3' className={classes.h3}>
                    Erstellung dieser Erklärung zur Barrierefreiheit
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Diese Erklärung wurde am 13.12.2022 erstellt. Die Einschätzung basiert auf einer im November 2022 durchgeführten Selbstbewertung.
                </Typography>
                <Typography variant='h5' component='h3' className={classes.h3}>
                    Feedback und Kontaktangaben
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Sind Ihnen Mängel in Bezug auf den barrierefreien Zugang zur Web- & App-Anwendung aufgefallen? Dann melden Sie sich gerne bei uns:<br />
                    <a href="mailto:info@fahrgastfernsehen.city">info@fahrgastfernsehen.city</a>
                </Typography>
                <Typography variant='h5' component='h3' className={classes.h3}>
                    Schlichtungsverfahren
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Wenn Sie nach einer Kontaktaufnahme an oben angegebener Kontaktaufnahmemöglichkeit kein zufriedenstellendes Feedback erhalten, können Sie bei der Schlichtungsstelle, eingerichtet bei der Landesbeauftragten für Menschen mit Behinderungen in Niedersachsen, einen Antrag auf Einleitung eines Schlichtungsverfahrens nach dem Niedersächsischen  Behindertengleichstellungsgesetz (NBGG) stellen.
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Die Schlichtungsstelle nach § 9 d NBGG hat die Aufgabe, Streitigkeiten zwischen Menschen mit Behinderungen und öffentlichen Stellen des Landes Niedersachsen zum Thema Barrierefreiheit in der IT beizulegen.
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Das Schlichtungsverfahren ist kostenlos. Es muss kein Rechtsbeistand eingeschaltet werden.
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Direkt kontaktieren können Sie die Schlichtungsstelle unter:<br />
                    Telefon: 0511/120-4010<br />
                    E-Mail: <a href="mailto:schlichtungsstelle@ms.niedersachsen.de">schlichtungsstelle@ms.niedersachsen.de</a>
                </Typography>
            </Box>
        );
    }
}

A11yPage.propTypes = {
    userLang: PropTypes.string,
    documentTitle: PropTypes.string,
};

export default function _A11yPage(props) {
    const classes = useStyles();
    const documentTitle = L.t('a11yPageTitle', props.userLang) + ' - ' +
        L.t('appTitle', props.userLang);
    const { trackPageView, } = useMatomo();
    React.useEffect(() => {
        trackPageView({ documentTitle, });
    }, [trackPageView, documentTitle]);
    return (
        <A11yPage
            {...props}
            classes={classes}
            documentTitle={documentTitle}
        />
    );
}
