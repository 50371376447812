import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Typography from "@material-ui/core/Typography";
import DateRangeIcon from '@material-ui/icons/DateRange';

import DetectLocale from '../util/DetectLocale';

const useStyles = makeStyles((theme) => ({
    box: {
        display: 'flex',
        width: '100%',
        marginBottom: '12px',
    },
    innerBox: {
        display: 'flex',
        width: '100%',
        height: '48px',
        border: 'solid #000000 2px',
        borderRadius: '4px',
    },
    icon: {
        color: '#000000',
        padding: '12px 16px',
    },
    text: {
        color: '#000000',
        fontSize: '14px',
        fontWeight: 500,
        textAlign: 'left',
        padding: '12px 0px',
    },
}));

class ActionCardDurationBox extends PureComponent {
    _formatDate(date) {
        const { userLang, } = this.props;
        const locale = userLang ? userLang : DetectLocale.getPreferredLocale();
        const dateOptions =
            { year: 'numeric', month: '2-digit', day: '2-digit', };
        return date.toLocaleDateString(locale, dateOptions);
    }

    render() {
        const { classes, showDates, startDate, endDate,
            labelDuration, labelFrom, labelTo, } = this.props;
        if (showDates && (startDate || endDate)) {
            let text = '';
            if (startDate && endDate) {
                text = labelDuration + this._formatDate(startDate) +
                    ' - ' + this._formatDate(endDate);
            } else if (startDate) {
                text = labelFrom + this._formatDate(startDate);
            } else {
                text = labelTo + this._formatDate(endDate);
            }
            return (
                <Box className={classes.box}>
                    <div className={classes.innerBox}>
                        <div>
                            <DateRangeIcon className={classes.icon} />
                        </div>

                        <Typography
                            variant='body1'
                            component='div'
                            className={classes.text}
                        >
                            {text}
                        </Typography>
                    </div>
                </Box>
            );
        } else {
            return null;
        }
    }
}

ActionCardDurationBox.propTypes = {
    showDates: PropTypes.bool,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    labelDuration: PropTypes.string.isRequired,
    labelFrom: PropTypes.string.isRequired,
    labelTo: PropTypes.string.isRequired,
    userLang: PropTypes.string,
};

export default function _ActionCardDurationBox(props) {
    const classes = useStyles();
    return <ActionCardDurationBox {...props} classes={classes} />;
}
