import React from "react";

import Grid from "@material-ui/core/Grid";

import Config from '../config/ui_settings.json';

export default class SmallBuilder {
    static build(cards, pos, userLang) {
        const grids = this.buildItems(cards, pos, userLang);
        let rows = [];
        for (let i = 0; i < grids.length; i += 2) {
            const single =
                <Grid
                    item xs={12}
                    key={'gridpos' + pos + 'sub' + i + userLang}
                >
                    <Grid container direction='row' justify='flex-start'
                        alignItems='center' spacing={Config.gridSpacing}>
                        {grids[i]}
                        {i + 1 < grids.length ? grids[i + 1] : null}
                    </Grid>
                </Grid>;
            const double =
                <Grid container direction='row' justify='flex-start'
                    alignItems='center' spacing={Config.gridSpacing}>
                    {grids[i]}
                    {i + 1 < grids.length ? grids[i + 1] : null}
                </Grid>
            rows.push([single, double]);
        }
        return rows;
    }

    static buildItems(cards, pos, userLang) {
        let grids = [];
        let cardno = 0;
        for (let card of cards) {
            grids.push(
                (<Grid
                    item xs={6}
                    key={'gridpos' + pos + 'grid' + cardno + userLang}
                >
                    {card}
                </Grid>)
            )
            cardno++;
        }
        return grids;
    }
}
