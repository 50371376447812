import React from "react";

import Grid from "@material-ui/core/Grid";

import AdaptiveGridItem from './AdaptiveGridItem';

export default class CardBox {
    constructor() {
        this.boxCount = 0;
        this.reset();
    }

    addSmall(item) {
        if (this.top.items.length === 0) {
            this.top.items.push(item);
            this.top.isSmall = true;
        } else if (this.bottom.items.length === 0) {
            this.bottom.items.push(item);
            this.bottom.isSmall = true;
        } else {
            // console.error('bad call to addSmall, call finish first');
        }
    }

    addBanner(item) {
        if (this.top.items.length === 0) {
            this.top.items.push(item);
            this.top.isBanner = true;
        } else if (this.top.isBanner && this.top.items.length < 2) {
            this.top.items.push(item);
        } else if (this.bottom.items.length === 0) {
            this.bottom.items.push(item);
            this.bottom.isBanner = true;
        } else if (this.bottom.isBanner && this.bottom.items.length < 2) {
            this.bottom.items.push(item);
        } else {
            // console.error('bad call to addBanner, call finish first');
        }
    }

    canAddSmall() {
        return (this.top.items.length === 0 || this.bottom.items.length === 0);
    }

    canAddBanner() {
        return (this.top.items.length === 0 ||
            (this.top.isBanner && this.top.items.length < 2) ||
            this.bottom.items.length === 0 ||
            (this.bottom.isBanner && this.bottom.items.length < 2));
    }

    isEmpty() {
        return (this.top.items.length === 0);
    }

    finish() {
        if (this.top.items.length === 0) {
            return null;
        } else {
            const variant = this.determineAndAdjustVariant();
            let items = [];
            let itemNo = 0;
            for (let item of this.top.items) {
                items.push(
                    <AdaptiveGridItem
                        key={'agcardbox' + this.boxCount + 'item' + itemNo}
                        keyValue={'cardbox' + this.boxCount + 'item' + itemNo}
                        variant={variant}
                        itemNo={itemNo}
                    >
                        {item}
                    </AdaptiveGridItem>
                );
                itemNo++;
            }
            for (let item of this.bottom.items) {
                items.push(
                    <AdaptiveGridItem
                        key={'agcardbox' + this.boxCount + 'item' + itemNo}
                        keyValue={'cardbox' + this.boxCount + 'item' + itemNo}
                        variant={variant}
                        itemNo={itemNo}
                    >
                        {item}
                    </AdaptiveGridItem>
                );
                itemNo++;
            }
            const box =
                <Grid item xs={6} key={'thecardbox' + this.boxCount}>
                    <Grid container direction='row' justify='center'
                        alignItems='center' spacing={2}
                    >
                        {items}
                    </Grid>
                </Grid>;
            this.boxCount++;
            this.reset();
            return box;
        }
    }

    // end of interface.

    reset() {
        this.top = {
            items: [],
            isSmall: false,
            isBanner: false,
        };
        this.bottom = {
            items: [],
            isSmall: false,
            isBanner: false,
        };
    }

    determineAndAdjustVariant() {
        if (this.top.isSmall) {
            if (this.bottom.isSmall) {
                return 'smallSmall';
            } else if (this.bottom.isBanner) {
                return 'smallBanner';
            } else {
                return 'smallFiller';
            }
        } else if (this.top.isBanner) {
            if (this.bottom.isSmall) {
                return 'bannerSmall';
            } else if (this.bottom.isBanner) {
                if (this.bottom.items.length === 2) {
                    return 'fourBanner';
                } else {
                    return 'threeBanner';
                }
            } else {
                return 'twoBanner';
            }
        }

        // console.error('failed to determine variant');
        return 'unknown';
    }
}
