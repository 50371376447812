import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import Grid from "@material-ui/core/Grid";

import PageTitleItem from '../components/PageTitleItem';
import SearchButton from './SearchButton';
import L from "../util/L10n";

export default class Header extends PureComponent {
    render() {
        const { name, userLang, twoColumnLayout } = this.props;
        const pageTitleItem =
            <PageTitleItem
                name={name}
                userLang={userLang}
            />;
        const searchButton = name === 'archive' ?
            <SearchButton
                content={L.t('archiveSearchButtonText', userLang)}
                to="/archivsuche"
                icon="search"
            /> :
            <SearchButton
                content={L.t('archiveBackButtonText', userLang)}
                to="/archiv"
                icon="archive"
            />;
        const single =
            <Fragment>
                {pageTitleItem}
                <Grid item xs={12}>
                    {searchButton}
                </Grid>
            </Fragment>;
        const double =
            <Fragment>
                {pageTitleItem}
                <Grid item xs={3} />
                <Grid item xs={6}>
                    {searchButton}
                </Grid>
                <Grid item xs={3} />
            </Fragment>;
        return twoColumnLayout ? double : single;
    }
}

Header.propTypes = {
    name: PropTypes.string.isRequired,
    twoColumnLayout: PropTypes.bool,
    userLang: PropTypes.string,
}
