import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    button: {
        color: '#02a5ce',
    },
    textField: {
        margin: '10px 0px',
    },
}));

class VoucherPasswordDialog extends PureComponent {
    render() {
        const {
            classes, open, onCancel, onConfirm, onChange, label, title, text,
            inputLabel, cancel, confirm,
        } = this.props;
        return (
            <Dialog
                open={open}
                onClose={onCancel}
            >
                <DialogTitle id={label + '-dialog-title'}>
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id={label + '-dialog-description'}
                        component='div'
                    >
                        <div
                            dangerouslySetInnerHTML={{ __html: text }}
                        />
                    </DialogContentText>
                    <TextField
                        id={label + '-dialog-input'}
                        className={classes.textField}
                        variant='outlined'
                        label={inputLabel}
                        type='password'
                        autoComplete='current-password'
                        onChange={(event) => {
                            onChange(event.target.value);
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                onConfirm();
                            }
                        }}
                        autoFocus
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        className={classes.button}
                        onClick={onCancel}
                    >
                        {cancel}
                    </Button>
                    <Button
                        className={classes.button}
                        onClick={onConfirm}
                        autoFocus
                    >
                        {confirm}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

VoucherPasswordDialog.propTypes = {
    label: PropTypes.string.isRequired,
    title: PropTypes.string,
    text: PropTypes.string,
    inputLabel: PropTypes.string,
    confirm: PropTypes.string,
    cancel: PropTypes.string,
    open: PropTypes.bool,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
};

export default function _VoucherPasswordDialog(props) {
    const classes = useStyles();
    return <VoucherPasswordDialog {...props} classes={classes} />;
}
