import React, {Fragment, PureComponent} from 'react';
import PropTypes from "prop-types";


// We rely on TextCard.css being imported by TextCard.js for
// some extra styles.
import {makeStyles} from "@material-ui/core/styles";

import ListItem from '@material-ui/core/ListItem';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';

import L from '../util/L10n';

const useStyles = makeStyles((theme) => ({
    item: {
        padding: 0,
        marginBottom: 8,
    },
    icon: {
        minWidth: 32,
        color: '#249dc5',
        paddingTop: 2,
    },
    text: {
        fontWeight: 500,
        // fontSize: '1rem',
        color: '#249dc5',
        // '&:hover': {
        //     textDecoration: 'underline',
        // },
        marginTop: 0,
    },
    accordion: {
        width: '100%',
        backgroundColor: 'transparent',
    },
    accordionSummary: {
        padding: 0,
    },
    expandIcon: {
        color: '#249dc5',
    },
    accordionDetailsRoot: {
        padding: '0 0 16px',
        display: 'flex',
        flexDirection: 'column',
    },
    accordionDetailsLogo: {
        margin: '8px 0',
        textAlign: 'right',
    },
    accordionDetailsLogoImage: {
        maxWidth: '50%',
        maxHeight: 64,
    },
    accordionDetailsAttrDate: {
        color: '#707070',
    },
    accordionDetailsAttrHead: {
        color: '#707070',
    },
    accordionDetailsAttrName: {
        color: '#707070',
    },
    accordionDetailsSubheader: {
        margin: '16px 0 -8px',
    },
    accordionDetailsHeader: {
        fontWeight: 500,
        margin: '8px 0 16px',
    },
    accordionDetailsText: {
        width: '100%',
        hyphens: 'auto',
        '& strong': {
            fontWeight: 500,
        },
        '& em': {
            fontStyle: 'italic',
        },
        '& s': {
            textDecoration: 'line-through',
        },
        '& u': {
            textDecoration: 'underline',
        },
        '& sup': {
            verticalAlign: 'super',
            fontSize: 'smaller',
        },
        '& sub': {
            verticalAlign: 'sub',
            fontSize: 'smaller',
        },
        '& p': {
            margin: '6px 0',
        },
    },
    accordionDetailsCopyrightStars: {
        color: '#707070',
        margin: '22px 0 0',
        textAlign: 'center',
        letterSpacing: '0.5rem',
    },
    accordionDetailsCopyright: {
        color: '#707070',
        margin: '12px 0 8px',
    },
    divider: {
        height: 2,
        backgroundColor: '#f3f1f3',
        marginBottom: 8,
    },
}));

class AccordionItem extends PureComponent {
    render() {
        const {head, content, index, classes, userLang} = this.props;


        const textContent = {__html: content};
        const alt = L.t('infosAccordionItem', userLang);
        const expandIcon =
            <ExpandMoreIcon
                className={classes.expandIcon}
            />;


        return (
            <Fragment>
                <ListItem
                    disableGutters={true}
                    classes={{root: classes.item,}}
                >
                    <Accordion
                        className={classes.accordion}
                        elevation={0}
                    >
                        <AccordionSummary
                            className={classes.accordionSummary}
                            id={'panel-' + index + '-header'}
                            aria-controls={'panel-' + index + '-content'}
                            expandIcon={expandIcon}
                        >

                            <Typography
                                className={classes.text}
                                component='h3'
                                variant='body1'
                            >
                                {head}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            id={'panel-' + index + '-content'}
                            classes={{root: classes.accordionDetailsRoot}}
                        >
                            <Typography
                                component='div'
                                variant='body1'
                                className={classes.accordionDetailsText}
                                dangerouslySetInnerHTML={textContent}
                            />
                        </AccordionDetails>
                    </Accordion>
                </ListItem>
                <Divider
                    classes={{root: classes.divider}}
                />
            </Fragment>
        );


    }
}

AccordionItem.propTypes = {
    head: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    userLang: PropTypes.string,
};

export default function _AccordionItem(props) {
    const classes = useStyles();
    return <AccordionItem {...props} classes={classes}/>;
}
