import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from "@material-ui/core/Typography";

import L from '../util/L10n';
import InvisibleImg from '../a11y/InvisibleImg';

const useStyles = makeStyles((theme) => ({
    indicatorBox: {
    },
    indicatorCircles: {
        verticalAlign: 'middle',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '5px',
    },
    indicatorTextBox: {
    },
    indicatorText: {
        paddingTop: 4,
    },
    inactiveButton: {
        padding: '5px',
    },
    inactive: {
        fontSize: '1.0rem',
        fontWeight: 500,
        color: '#afafaf',
        cursor: "pointer",
    },
    activeButton: {
        padding: '5px',
    },
    active: {
        fontSize: '1.0rem',
        fontWeight: 500,
        color: '#1aa7cc',
        cursor: "pointer",
    },
    arrowButton: {
        padding: '0px',
        paddingTop: '5px',
        marginLeft: '-6px',
        marginRight: '-6px',
    },
    arrow: {
        fontSize: '24px',
        fontWeight: 500,
        color: '#afafaf',
        cursor: "pointer",
    },
}));

class CarouselIndicators extends PureComponent {
    render() {
        const {
            classes, count, active, onSelect, onNext, onPrev, userLang,
            isMediaSlider,
        } = this.props;
        if (count > 0) {
            let indicatorsGridElement;
            if (isMediaSlider) {
                const number = '' + (active + 1);
                const text = L.t('mediaSliderOf', userLang, { number, count });
                indicatorsGridElement =
                    <Grid item className={classes.indicatorTextBox}>
                        <Typography
                            className={classes.indicatorText}
                            variant='body1'
                            component='div'
                        >
                            {text}
                        </Typography>
                    </Grid>;
            } else {
                const indicators = [];
                for (let i = 0; i < count; i++) {
                    const classNameButton = i === active ?
                        classes.activeButton : classes.inactiveButton;
                    const className = i === active ?
                        classes.active : classes.inactive;
                    const number = '' + (i + 1);
                    const altText = L.t(
                        i === active ? 'sliderSelectedButton_ariaLabel' :
                            'sliderSelectButton_ariaLabel',
                        userLang, { number, count }
                    );
                    const item =
                        <IconButton
                            key={'iconbutton' + i}
                            className={classNameButton}
                            onClick={() => { onSelect(i) }}
                        >
                            <FiberManualRecordIcon
                                key={'selector' + i}
                                size='large'
                                className={className}
                            />
                            <InvisibleImg alt={altText} />
                        </IconButton>;
                    indicators.push(item);
                }
                indicatorsGridElement =
                    <Grid item className={classes.indicatorCircles}>
                        {indicators}
                    </Grid>;
            }
            const navigatePrevAltText = L.t('sliderPrevButton_ariaLabel',
                userLang);
            const navigateNextAltText = L.t('sliderNextButton_ariaLabel',
                userLang);
            return (
                <Grid
                    container
                    direction='row'
                    justify='space-between'
                    className={classes.indicatorBox}
                >
                    <Grid item>
                        <IconButton
                            className={classes.arrowButton}
                            onClick={() => { onPrev() }}
                        >
                            <NavigateBeforeIcon className={classes.arrow} />
                            <InvisibleImg alt={navigatePrevAltText} />
                        </IconButton>
                    </Grid>
                    {indicatorsGridElement}
                    <Grid item>
                        <IconButton
                            className={classes.arrowButton}
                            onClick={() => { onNext() }}
                        >
                            <NavigateNextIcon className={classes.arrow} />
                            <InvisibleImg alt={navigateNextAltText} />
                        </IconButton>
                    </Grid>
                </Grid>
            );
        } else {
            return null;
        }
    }
}

CarouselIndicators.prototypes = {
    count: PropTypes.number,
    active: PropTypes.number,
    onSelect: PropTypes.func,
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
    userLang: PropTypes.string,
    isMediaSlider: PropTypes.bool,
};

CarouselIndicators.defaultProps = {
    isMediaSlider: false,
};

export default function _CarouselIndicators(props) {
    const classes = useStyles();
    return <CarouselIndicators {...props} classes={classes} />
}
