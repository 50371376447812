import React, {PureComponent} from 'react';
import PropTypes from "prop-types";

import {makeStyles} from "@material-ui/core/styles";

import L from "../util/L10n";
import {getActionInfo, getTokenInfo} from '../data/ActionReader';
import ActionCardContent from './ActionCardContent';
import ActionCardNotOpenBox from '../forms/ActionCardNotOpenBox';
import lottieJson from './random.json'
import SolvedButton from "./scrambledword/SolvedButton";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles((theme) => ({
    actionCard: {},
    formFallback: {
        width: '100%',
        height: '48px',
        textAlign: 'center',
    },
}));

class ActionCard extends PureComponent {
    randomGeneratorCalled;

    constructor(props) {
        super(props);
        this.state = {
            maxCallsChecked: false,
            maxCallsAvailable: false,
            maxCallsFailed: false,
            token: null,
            waitingForToken: true,
        };
        this.randomGeneratorCalled = false;

        this.onDataReceived = this.onDataReceived.bind(this);
        this.onTockenDataReceived = this.onTockenDataReceived.bind(this);
        this.reload = this.reload.bind(this);
        this.onRandomStartClicked = this._onRandomStartClicked.bind(this);
    }

    componentDidMount() {
        this.load();
    }

    load() {
        const {data, entry} = this.props;
        if (!!data.maxCalls) {
            getActionInfo(entry.id, data.id, this.onDataReceived)
                .catch((error) => {
                    this.setState({maxCallsFailed: true});
                });
        } else {
            this.setState({
                maxCallsChecked: true, maxCallsAvailable: true,
            });
        }
    }

    onDataReceived(data) {
        if (data && data.state && data.state === 'success') {
            if (data.info && 'countLeft' in data.info) {
                const countLeft = parseInt(data.info.countLeft);
                this.setState({
                    maxCallsChecked: true,
                    maxCallsAvailable: countLeft > 0,
                });
            } else {
                this.setState({
                    maxCallsChecked: true,
                    maxCallsAvailable: true,
                });
            }
        } else {
            this.setState({maxCallsFailed: true});
        }
    }

    onTockenDataReceived(data) {
        const {stopAnimation} = this.props;
        stopAnimation();
        if (data && data.state && data.state === 'success') {
            this.setState({
                token: data.token,
                waitingForToken: false,
            });
        } else {
            if (data.errormessage && data.errormessage === 'NO TOKEN') {
                this.setState({
                    token: null,
                    waitingForToken: false,
                });
            } else {
                this.setState({
                    maxCallsFailed: true,
                    waitingForToken: false,
                });
            }
        }
    }

    reload() {
        const {data} = this.props;
        if (!!data.maxCalls) {
            const siht = this;
            this.setState({
                maxCallsChecked: false,
                maxCallsAvailable: false,
                maxCallsFailed: false,
            }, () => {
                setTimeout(() => {
                    siht.load();
                }, 20);
            });
        }
    }

    actionEnded(end) {
        const now = new Date();
        return !!end && now > end;
    }

    actionOpen(start, end) {
        const now = new Date();
        return !this.actionEnded(end) && (!start || now >= start);
    }

    _onRandomStartClicked() {
        const {data, entry, showAnimation} = this.props;
        this.randomGeneratorCalled = true;
        showAnimation(lottieJson, true);
        getTokenInfo(entry.id, data.id, this.onTockenDataReceived)
            .catch((error) => {
                this.setState({maxCallsFailed: true});
            });
    }

    render() {
        const {classes, data, entry, userLang, lockLayout} = this.props;
        const {
            maxCallsChecked, maxCallsAvailable, maxCallsFailed, token,
        } = this.state;

        const startDate = data.startDate ? new Date(data.startDate) :
            (entry.publish_from ?
                new Date(entry.publish_from.replace(' ', 'T')) : null);
        const endDate = data.endDate ? new Date(data.endDate) :
            (entry.publish_till ?
                new Date(entry.publish_till.replace(' ', 'T')) : null);
        const isActionOpen = this.actionOpen(startDate, endDate);
        const isActionEnded = this.actionEnded(endDate);

        if (maxCallsChecked && maxCallsAvailable) {
            if (data.randomGeneratorActivate) {
                if (isActionEnded) {
                    return <div className={classes.actionCard}>
                        <ActionCardNotOpenBox
                            text={data.randomGeneratorTextOver ?
                                data.randomGeneratorTextOver : L.t('actionRandomClosed', userLang)}
                            ended={isActionEnded}
                        />
                    </div>
                }
                if (!this.randomGeneratorCalled) {
                    return <SolvedButton
                        content={data.randomGeneratorButtonText ? data.randomGeneratorButtonText : "Zufallsgenerator starten"}
                        onClick={this.onRandomStartClicked}
                        backgroundColor={data.randomGeneratorButtonColor}
                        inverted={'inverse' === data.randomGeneratorButtonStyle}
                        disabled={false}
                    />


                }
                if (token) {
                    let msgRandom = <Typography
                        style={{fontWeight: 600,}}
                        align='left'
                        variant='body1'
                        component='p'
                    >
                        {data.randomGeneratorTextWon ? data.randomGeneratorTextWon : L.t('actionRandomHit', userLang)}
                    </Typography>;
                    return (
                        <div>
                            {msgRandom}
                            <div style={{paddingBottom: '10pt',}}></div>

                            <ActionCardContent
                                data={data}
                                entry={entry}
                                userLang={userLang}
                                lockLayout={lockLayout}
                                token={token}
                                isActionOpen={isActionOpen}
                                isActionEnded={isActionEnded}
                                startDate={startDate}
                                endDate={endDate}
                                recheckMaxCalls={this.reload}
                            />
                        </div>
                    );
                } else {
                    let text;
                    if (maxCallsFailed) {
                        text = L.t('actionMaxCallsFailed', userLang);
                    } else {
                        text = data.randomGeneratorTextLost ?
                            data.randomGeneratorTextLost : L.t('actionRandomLost', userLang);
                    }
                    return <Typography
                        style={{fontWeight: 600,}}
                        align='left'
                        variant='body1'
                        component='p'
                    >
                        {text}
                    </Typography>;
                }
            }

            return (
                <ActionCardContent
                    data={data}
                    entry={entry}
                    userLang={userLang}
                    lockLayout={lockLayout}
                    isActionOpen={isActionOpen}
                    isActionEnded={isActionEnded}
                    startDate={startDate}
                    endDate={endDate}
                    recheckMaxCalls={this.reload}
                />
            );
        } else if (maxCallsChecked) {
            return (
                <div className={classes.actionCard}>
                    <ActionCardNotOpenBox
                        text={L.t('actionMaxCallsEnded', userLang)}
                        ended={true}
                    />
                </div>
            );
        } else if (maxCallsFailed) {
            return (
                <div className={classes.formFallback}>
                    {L.t('actionMaxCallsFailed', userLang)}
                </div>
            );
        } else {
            return (
                <div className={classes.formFallback}>...</div>
            );
        }
    }
}

ActionCard.propTypes = {
    classes: PropTypes.object.isRequired,
    data:
    PropTypes.object.isRequired,
    entry:
    PropTypes.object.isRequired,
    userLang:
    PropTypes.string,
    lockLayout:
    PropTypes.func,
    showAnimation:
    PropTypes.func.isRequired,
    stopAnimation:
    PropTypes.func.isRequired,
};

export default function _ActionCard(props) {
    const classes = useStyles();
    return <ActionCard {...props} classes={classes}/>;
}
