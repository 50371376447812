import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import L from "../util/L10n";
import TextUtil from '../util/TextUtil';

const useStyles = makeStyles((theme) => ({
    quoteCard: {},
    title: {
        color: '#000000',
        marginBottom: 6,
    },
    divider: {
        height: 2,
        backgroundColor: '#f3f1f3',
        marginBottom: 16,
    },
    card: {
        borderRadius: '8px',
        transform: 'translateZ(0)',
        padding: 20,
        backgroundColor: '#f3f1f3',
    },
    cardContent: {},
    quote: {
        '& strong': {
            fontWeight: 500,
        },
        '& em': {
            fontStyle: 'italic',
        },
        '& s': {
            textDecoration: 'line-through',
        },
        '& u': {
            textDecoration: 'underline',
        },
        '& sup': {
            verticalAlign: 'super',
            fontSize: 'smaller',
        },
        '& sub': {
            verticalAlign: 'sub',
            fontSize: 'smaller',
        },
        '& p': {
            margin: 0,
        },
    },
    authorDiv: {
        margin: '20px 0 0',
    },
    author: {
        fontWeight: 500,
        fontSize: 16,
        color: '#000000',
    },
    role: {
        fontWeight: 400,
        fontSize: 16,
        color: 'rgba(0, 0, 0, 0.54)',
    },
}));

class QuoteCard extends PureComponent {
    render() {
        const { classes, data, userLang, } = this.props;
        const title = TextUtil.isEmptyText(data.title) ?
            L.t('quotationTitle', userLang) : data.title;
        const quoteText = { __html: data.quote, };
        const authorDiv = TextUtil.isEmptyText(data.author) &&
            TextUtil.isEmptyText(data.author_role) ? null :
            <div className={classes.authorDiv}>
                <Typography
                    variant='body1'
                    component='div'
                    classes={{ root: classes.author, }}
                >
                    {data.author}
                </Typography>
                <Typography
                    variant='body1'
                    component='div'
                    classes={{ root: classes.role, }}
                >
                    {data.author_role}
                </Typography>
            </div>;
        return (
            <div className={classes.quoteCard}>

                <Typography
                    variant='subtitle2'
                    component='h2'
                    classes={{ root: classes.title, }}
                >
                    {title}
                </Typography>

                <Divider
                    classes={{ root: classes.divider, }}
                />
                <Card className={classes.card} elevation={0}>
                    <CardContent className={classes.cardContent}>
                        <Typography
                            variant='body1'
                            component='blockquote'
                            classes={{ root: classes.quote, }}
                            dangerouslySetInnerHTML={quoteText}
                        />
                        {authorDiv}
                    </CardContent>
                </Card>
            </div>
        );
    }
}

QuoteCard.propTypes = {
    data: PropTypes.object.isRequired,
    userLang: PropTypes.string,
};

export default function _QuoteCard(props) {
    const classes = useStyles();
    return <QuoteCard {...props} classes={classes} />;
}
