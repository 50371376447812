import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import VideoPlayer from '../video/VideoPlayer';
import AltText from '../util/AltText';

class VideoCardContent extends PureComponent {
    getUrl(data) {
        if (data && data.mediaURL) {
            return data.mediaURL + '-high.m3u8';
        } else {
            return null;
        }
    }

    altText(data) {
        const text = new AltText();
        text.append(data.subheader);
        text.append(data.header);
        text.append(data.text);
        return text.toString();
    }

    render() {
        const {
            data, manager, sliderItem, videoQuality, videoQualityCallback,
            fullscreenVideoCallback, playsInline, userLang,
        } = this.props;
        const url = this.getUrl(data);
        const preview = data.previewMediaURL;
        const altText = this.altText(data);
        if (url) {
            return (
                <VideoPlayer
                    url={url}
                    preview={preview}
                    altText={altText}
                    manager={manager}
                    sliderItem={sliderItem}
                    videoQuality={videoQuality}
                    videoQualityCallback={videoQualityCallback}
                    fullscreenVideoCallback={fullscreenVideoCallback}
                    playsInline={playsInline}
                    userLang={userLang}
                />
            );
        } else {
            return null;
        }
    }
}

VideoCardContent.prototypes = {
    data: PropTypes.object.isRequired,
    manager: PropTypes.object,
    sliderItem: PropTypes.number,
    videoQuality: PropTypes.number,
    videoQualityCallback: PropTypes.func,
    fullscreenVideoCallback: PropTypes.func,
    playsInline: PropTypes.bool,
    userLang: PropTypes.string,
};

export default function _VideoCardContent(props) {
    return <VideoCardContent {...props} />;
}
