import CanonicalUrl from './CanonicalUrl';
import SlugifyUtil from './SlugifyUtil';

export default class EntryUrl {
    static getPath(entry) {
        const { id, title } = entry;
        return SlugifyUtil.slugify('/beitrag/' + id, title);
    }

    static getUrl(entry) {
        return CanonicalUrl.get(this.getPath(entry));
    }
}
