import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from "@material-ui/core/Typography";

import { useMatomo } from '@datapunt/matomo-tracker-react';

import L from './util/L10n';
import PageHelmet from './util/PageHelmet';
import CanonicalUrl from './util/CanonicalUrl';

const useStyles = makeStyles({
    h2: {
        marginBlockStart: '0.83em',
        marginBlockEnd: '0.83em',
    },
    h3: {
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
    },
    p: {
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        textAlign: 'left',
        hyphens: 'auto',
    },
    pb: {
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        textAlign: 'justify',
        hyphens: 'auto',
    },
});

class ImprintPage extends PureComponent {
    componentDidMount() {
        try {
            window.scrollTo(0, 0);
        } catch (e) {
            // Ignore.
        }
    }

    buildMetaData() {
        const canonicalUrl = CanonicalUrl.get('/impressum');
        const ogLocale = 'de_DE';
        const ogType = 'website';
        return { canonicalUrl, ogLocale, ogType };
    }

    render() {
        const { classes, userLang, documentTitle, } = this.props;
        const {
            description, canonicalUrl, ogLocale, ogType,
        } = this.buildMetaData();
        const pageHelmet =
            <PageHelmet
                documentTitle={documentTitle}
                metaDescription={description}
                canonicalUrl={canonicalUrl}
                ogTitle={documentTitle}
                ogDescription={description}
                ogUrl={canonicalUrl}
                ogLocale={ogLocale}
                ogType={ogType}
            />;
        return (
            <Box>
                {pageHelmet}
                <Typography variant='h4' component='h2' className={classes.h2}>
                    {L.t('imprintPageTitle', userLang)}
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    Fahrgastmedien Hannover GmbH<br />
                    Goethestraße 13a<br />
                    30169 Hannover<br /><br />
                    Telefon: 0511 36699-99<br />
                    Telefax: 0511 36699-98<br />
                    E-Mail: <a href="mailto:info@fahrgastfernsehen.city">info@fahrgastfernsehen.city</a>
                </Typography>
                <Typography variant='h5' component='h3' className={classes.h3}>
                    Vertreten durch die Geschäftsführer:
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    René Schweimler, Bernd Hoedtke<br />
                    Goethestraße 13a<br />
                    30169 Hannover<br /><br />
                    Registergericht: Amtsgericht Hannover<br />
                    Registernummer: HRB 219796<br /><br />
                    Umsatzsteuer-Identifikationsnummer: DE 329876966
                </Typography>
                <Typography variant='h5' component='h3' className={classes.h3}>
                    Verantwortlichkeit für journalistisch-redaktionelle Inhalte i.S.d. § 18 Abs. 1 MStV:
                </Typography>
                <Typography variant='body1' component='p' className={classes.p}>
                    René Schweimler<br />
                    Goethestraße 13a<br />
                    30169 Hannover<br /><br />
                    Telefon: 0511 36699-99<br />
                    Telefax: 0511 36699-98<br />
                    E-Mail: <a href="mailto:redaktion@fahrgastfernsehen.city">redaktion@fahrgastfernsehen.city</a>
                </Typography>
            </Box>
        );
    }
}

ImprintPage.propTypes = {
    userLang: PropTypes.string,
    documentTitle: PropTypes.string,
};

export default function _ImprintPage(props) {
    const classes = useStyles();
    const documentTitle = L.t('imprintPageTitle', props.userLang) + ' - ' +
        L.t('appTitle', props.userLang);
    const { trackPageView, } = useMatomo();
    React.useEffect(() => {
        trackPageView({ documentTitle, });
    }, [trackPageView, documentTitle]);
    return (
        <ImprintPage
            {...props}
            classes={classes}
            documentTitle={documentTitle}
        />
    );
}
