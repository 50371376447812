import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

export default class InvisibleImg extends PureComponent {
    render() {
        const { alt } = this.props;
        const src = 'data:image/svg+xml,' +
            '%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%3E%3C/svg%3E';
        return (
            <img
                src={src}
                alt={alt}
                width={0}
                height={0}
            />
        );
    }
}

InvisibleImg.prototypes = {
    alt: PropTypes.string.isRequired,
};
