import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';

import { useMatomo } from '@datapunt/matomo-tracker-react'

const useStyles = makeStyles((theme) => ({
    audio: {
        width: '100%',
        display: 'block',
        '&::-webkit-media-controls': {
            backgroundColor: '#ffffff',
        },
        '&::-webkit-media-controls-panel': {
            backgroundColor: '#ffffff',
        },
    },
    buttonBase: {
        display: 'block',
    },
}));

class Audioplayer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            hasStarted: false,
        }
        this.onPlay = this.onPlay.bind(this);
    }

    onPlay() {
        const { trackAudioStart } = this.props;
        const { hasStarted } = this.state;
        if (!hasStarted) {
            this.setState({ hasStarted: true });
            trackAudioStart();
        }
    }

    render() {
        const { url, altText, classes, onFocus, onBlur } = this.props;
        return (
            <ButtonBase
                component='div'
                className={classes.buttonBase}
                onFocusVisible={onFocus}
                onBlur={onBlur}
                tabIndex={-1}
            >
                <audio
                    className={classes.audio}
                    autoPlay={false}
                    controls={true}
                    controlsList='nodownload'
                    preload='metadata'
                    src={url}
                    onPlay={this.onPlay}
                >
                    {altText}
                </audio>
            </ButtonBase>
        );
    }
}

Audioplayer.prototypes = {
    url: PropTypes.string,
    altText: PropTypes.string,
    userLang: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    trackAudioStart: PropTypes.func.isRequired,
    // manager: PropTypes.object,
    // sliderItem: PropTypes.number,
};

export default function _Audioplayer(props) {
    const classes = useStyles();
    const { trackEvent } = useMatomo();
    const _onAudioStart = () => {
        trackEvent({
            category: 'audio',
            action: 'play',
            name: props.url,
        });
        return true;
    };
    return (
        <Audioplayer
            {...props}
            classes={classes}
            trackAudioStart={_onAudioStart}
        />
    );
}
