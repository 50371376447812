import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';

const useStylesByProps = (props) => {
    const color = props.color || '#ffffff';
    const backgroundColor = props.backgroundColor || '#338C15';

    return makeStyles((theme) => ({
        button: {
            borderRadius: 6,
            color: color,
            backgroundColor: backgroundColor,
            border: '2px solid ' + backgroundColor,
            boxShadow: '0px 6px 7px -4px rgba(0,0,0,0.2), 0px 11px 15px 1px rgba(0,0,0,0.14), 0px 4px 20px 3px rgba(0,0,0,0.12)',
            fontWeight: 900,
            fontSize: 19,
            textTransform: 'none',
            letterSpacing: 0.5,
            transition: '0.2s ease',
            '&:hover': {
                backgroundColor: backgroundColor,
                filter: 'brightness(120%)',
            },
            '&:active': {
                backgroundColor: backgroundColor,
                filter: 'brightness(120%)',
            },
            '&:focus': {
                backgroundColor: backgroundColor,
                filter: 'brightness(120%)',
            },
        },
        buttonInverted: {
            borderRadius: 6,
            color: backgroundColor,
            backgroundColor: color,
            border: '2px solid ' + backgroundColor,
            boxShadow: '0px 6px 7px -4px rgba(0,0,0,0.2), 0px 11px 15px 1px rgba(0,0,0,0.14), 0px 4px 20px 3px rgba(0,0,0,0.12)',
            fontWeight: 900,
            fontSize: 19,
            textTransform: 'none',
            letterSpacing: 0.5,
            transition: '0.2s ease',
            '&:hover': {
                color: color,
                backgroundColor: backgroundColor,
            },
            '&:active': {
                color: color,
                backgroundColor: backgroundColor,
            },
            '&:focus': {
                color: color,
                backgroundColor: backgroundColor,
            },
        },
        label: {
            paddingBottom: '0.2em',
        },
    }));
};


class SolvedButton extends PureComponent {
    render() {
        const { classes, content, onClick, inverted, disabled } = this.props;
        let variant = inverted ? 'outlined' : 'contained'
        let myStyle = {};
        if(disabled) {
            variant = 'contained';
            myStyle = {
                borderColor: 'rgba(0, 0, 0, 0.1)',
                color: 'rgb(255, 255, 255)',
                backgroundColor: 'rgb(204, 204, 204)',
            };
        }
        return (
            <Button
                style={myStyle}
                variant={variant}
                fullWidth={true}
                component='label'

                classes={{
                    root: inverted ? classes.buttonInverted : classes.button,
                    label: classes.label,
                }}
                disabled={disabled}
                onClick={onClick}
            >
                <div>
                    {content}
                </div>
            </Button>
        );
    }
}

SolvedButton.propTypes = {
    content: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    inverted: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default function _SolvedButton(props) {
    const useStyles = useStylesByProps(props);
    const classes = useStyles();
    return <SolvedButton {...props} classes={classes} />;
}
