import React, {PureComponent} from 'react';
import {Route, Switch as RouterSwitch} from 'react-router-dom';
import PropTypes from "prop-types";

import Container from '@material-ui/core/Container';
import {makeStyles} from "@material-ui/core/styles";

import TeaserPage from "./TeaserPage";
import EntryPage from './EntryPage';
import AboutUsPage from './AboutUsPage';
import ArchivePage from './ArchivePage';
import ImprintPage from './ImprintPage';
import DataprotectionPage from './DataprotectionPage';
import A11yPage from './A11yPage';
import SettingsPage from './SettingsPage';
import OptInResultPage from './page/OptInResultPage';
import VoucherResultPage from './page/VoucherResultPage';
import {addListener, isShowWifiBox, removeListener} from './WifiBoxAppChannel';
import Config from './config/ui_settings.json';
import getViewData from "./data/ViewReader";

const useStylesWebApp = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: 56 + 48 + 20,
            paddingBottom: 56 + 20,
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 64 + 48 + 20,
            paddingBottom: 64 + 20,
        },
        //backgroundColor: theme.palette.background.default,
    }
}));

const useStylesEmbedded = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: 20,
            paddingBottom: 20,
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 20,
            paddingBottom: 20,
        },
        backgroundColor: theme.palette.background.default,
    }
}));

class PageContent extends PureComponent {
    constructor(props) {
        super(props);
        let wifiBoxInfo = isShowWifiBox();
        //console.log("constructor, wifiBoxInfo", wifiBoxInfo)
        if (wifiBoxInfo) {
            this.state = {tabsMeta: null, showWifiLogin: true};
        } else {
            this.state = {tabsMeta: null, showWifiLogin: this.props.showWifiLogin};
        }
        this.onDataReceived = this._onDataReceived.bind(this);
        //console.log("constructor", this.state)
    }

    componentDidMount() {
        let wifiBoxInfo = isShowWifiBox();
        if (wifiBoxInfo) {
            this.setState({showWifiLogin: true});
            //console.log("componentDidMount true", this.state)
        } else {
            this.setState({showWifiLogin: this.props.showWifiLogin});
            //console.log("componentDidMount false", this.state)
        }
        this.load();

        addListener(this)
    }

    componentWillUnmount() {
        removeListener(this)
    }

    load() {
        const { appServerPort, previewToken, previewDate } = this.props;
        const lang = 'de/meta';
        getViewData('tabs', lang, this.onDataReceived, appServerPort,
            previewToken, previewDate).catch((error) => {
            this.setState({ error: error })
        });
    }

    _onDataReceived(data) {
        console.log("tabs.meta", data)
        this.setState({ tabsMeta: data, error: null })
    }

    showWifiBox() {
        //console.log("PageContent.showWifiBox");
        this.setState({showWifiLogin: true})
    }

    hideWifiBox() {
        //console.log("PageContent.hideWifiBox");
        this.setState({showWifiLogin: false})
    }


    render() {
        //console.log("render", this.state)

        const {
            appEmbedded, classes, classesEmbedded,
            previewToken, previewDate, appServerPort,
            userLang, updateUserLang, videoQuality, videoQualityCallback,
            deviceSize, fullscreenVideo, fullscreenVideoCallback, textOnly,
            setTextOnly, showAnimation, stopAnimation,
        } = this.props;

        const {
            tabsMeta,
        } = this.state;

        console.log("render tabsMeta", this.state)

        const showWifiLogin = this.state.showWifiLogin;

        return (
            <Container
                classes={appEmbedded ? classesEmbedded : classes}
                maxWidth={Config.containerMaxWidth}
                component='main'
            >
                <RouterSwitch>
                    <Route
                        key='start'
                        path='/'
                        exact>
                        <TeaserPage
                            name="start"
                            showWifiLogin={showWifiLogin}
                            appServerPort={appServerPort}
                            previewToken={previewToken}
                            previewDate={previewDate}
                            userLang={userLang}
                            updateUserLang={updateUserLang}
                            deviceSize={deviceSize}
                            textOnly={textOnly}
                        />
                    </Route>
                    <Route
                        key='level'
                        path={[
                            '/heute/:level',
                            '/today/:level',
                            '/line/:level',
                            '/linie/:level‘,',
                            '/preview/:level',
                            '/vorschau/:level',
                            '/aktion/:level',
                            '/actions/:level',
                            '/specials/:level',
                            '/gewinne/:level',
                            '/prizes/:level',
                            '/deal/:level'
                        ]
                        }
                        render={(routing) => (
                            <TeaserPage routing={routing}
                                        name="level"
                                        tabsMeta={tabsMeta}
                                        showWifiLogin={showWifiLogin}
                                        appServerPort={appServerPort}
                                        previewToken={previewToken}
                                        previewDate={previewDate}
                                        userLang={userLang}
                                        updateUserLang={updateUserLang}
                                        deviceSize={deviceSize}
                                        textOnly={textOnly}
                            />
                        )}/>
                    <Route
                        key='line'
                        path={['/line', '/linie',]}>
                        <TeaserPage
                            name="line"
                            showWifiLogin={showWifiLogin}
                            appServerPort={appServerPort}
                            previewToken={previewToken}
                            previewDate={previewDate}
                            userLang={userLang}
                            updateUserLang={updateUserLang}
                            deviceSize={deviceSize}
                            textOnly={textOnly}
                        />
                    </Route>
                    <Route
                        key='events'
                        path={['/preview', '/vorschau',]}>
                        <TeaserPage
                            name="events"
                            showWifiLogin={showWifiLogin}
                            appServerPort={appServerPort}
                            previewToken={previewToken}
                            previewDate={previewDate}
                            userLang={userLang}
                            updateUserLang={updateUserLang}
                            deviceSize={deviceSize}
                            textOnly={textOnly}
                        />
                    </Route>
                    <Route
                        key='prizes'
                        path={['/aktion', '/actions', '/specials', '/gewinne', '/prizes']}>
                        <TeaserPage
                            name="prizes"
                            showWifiLogin={showWifiLogin}
                            appServerPort={appServerPort}
                            previewToken={previewToken}
                            previewDate={previewDate}
                            userLang={userLang}
                            updateUserLang={updateUserLang}
                            deviceSize={deviceSize}
                            textOnly={textOnly}
                        />
                    </Route>
                    <Route
                        key='deals'
                        path={['/deal']}>
                        <TeaserPage
                            name="deals"
                            showWifiLogin={showWifiLogin}
                            appServerPort={appServerPort}
                            previewToken={previewToken}
                            previewDate={previewDate}
                            userLang={userLang}
                            updateUserLang={updateUserLang}
                            deviceSize={deviceSize}
                            textOnly={textOnly}
                        />
                    </Route>
                    <Route
                        key='entry'
                        path={['/entry/:id', '/beitrag/:id',]}
                        render={(routing) => (
                            <EntryPage routing={routing}
                                       appServerPort={appServerPort}
                                       previewToken={previewToken}
                                       previewDate={previewDate}
                                       userLang={userLang}
                                       videoQuality={videoQuality}
                                       videoQualityCallback={videoQualityCallback}
                                       deviceSize={deviceSize}
                                       fullscreenVideo={fullscreenVideo}
                                       fullscreenVideoCallback={fullscreenVideoCallback}
                                       textOnly={textOnly}
                                       showAnimation={showAnimation}
                                       stopAnimation={stopAnimation}
                            />
                        )}
                    />
                    <Route
                        key='aboutus'
                        path={['/ueber-uns', '/about-us',]}
                    >
                        <AboutUsPage
                            previewToken={previewToken}
                            previewDate={previewDate}
                            userLang={userLang}
                            deviceSize={deviceSize}
                            textOnly={textOnly}
                        />
                    </Route>
                    <Route
                        key='archive'
                        path={['/archive', '/archiv']}
                    >
                        <ArchivePage
                            name="archive"
                            userLang={userLang}
                            deviceSize={deviceSize}
                            appServerPort={appServerPort}
                            textOnly={textOnly}
                        />
                    </Route>
                    <Route
                        key='archiveSearch'
                        path={['/archivesearch', '/archivsuche']}
                    >
                        <ArchivePage
                            name="archivesearch"
                            userLang={userLang}
                            deviceSize={deviceSize}
                            appServerPort={appServerPort}
                            textOnly={textOnly}
                        />
                    </Route>
                    <Route
                        key='imprint'
                        path={['/imprint', '/impressum',]}>
                        <ImprintPage
                            userLang={userLang}
                            textOnly={textOnly}
                        />
                    </Route>
                    <Route
                        key='dataprotection'
                        path={['/dataprotection', '/datenschutz',]}>
                        <DataprotectionPage
                            userLang={userLang}
                            textOnly={textOnly}
                        />
                    </Route>
                    <Route
                        key='a11y'
                        path={['/accessibility', '/barrierefreiheit',]}>
                        <A11yPage
                            userLang={userLang}
                            textOnly={textOnly}
                        />
                    </Route>
                    <Route
                        key='settings'
                        path={['/einstellungen', '/settings',]}>
                        <SettingsPage
                            userLang={userLang}
                            textOnly={textOnly}
                            setTextOnly={setTextOnly}
                        />
                    </Route>
                    <Route
                        key='optinResult'
                        path={['/optin-ok']}>
                        <OptInResultPage
                            userLang={userLang}
                            textOnly={textOnly}
                            success={true}
                        />
                    </Route>
                    <Route
                        key='optinResultFailed'
                        path={['/optin-failed']}>
                        <OptInResultPage
                            userLang={userLang}
                            textOnly={textOnly}
                            success={false}
                        />
                    </Route>
                    <Route
                        key='qrResult'
                        path={['/qr/:id?/:action?/:voucher?']}>
                        <VoucherResultPage
                            userLang={userLang}
                            textOnly={textOnly}
                        />
                    </Route>
                </RouterSwitch>
            </Container>
        );
    }
}

PageContent.propTypes = {
    appServerPort: PropTypes.number,
    appEmbedded: PropTypes.bool,
    showWifiLogin: PropTypes.bool,
    classes: PropTypes.object,
    classesEmbedded: PropTypes.object,
    previewToken: PropTypes.string,
    previewDate: PropTypes.string,
    userLang: PropTypes.string,
    updateUserLang: PropTypes.func,
    videoQuality: PropTypes.number,
    videoQualityCallback: PropTypes.func,
    deviceSize: PropTypes.string,
    fullscreenVideo: PropTypes.bool,
    fullscreenVideoCallback: PropTypes.func,
    textOnly: PropTypes.bool,
    setTextOnly: PropTypes.func.isRequired,
    showAnimation: PropTypes.func.isRequired,
    stopAnimation: PropTypes.func.isRequired,

};

export default function _PageContent(props) {
    const classes = useStylesWebApp();
    const classesEmbedded = useStylesEmbedded();
    return (
        <PageContent
            {...props}
            classes={classes}
            classesEmbedded={classesEmbedded}
        />
    );
}
