import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Button from '@material-ui/core/Button';

import L from '../util/L10n';
import CookieAccess from '../util/CookieAccess';

const useStyles = makeStyles((theme) => ({
    snackbarRoot: {
        left: 0,
        right: 0,
        backgroundColor: '#02a5ce',
        [theme.breakpoints.up('sm')]: {
            transform: 'none',
        },
    },
    snackbarAnchor: {
        bottom: 0,
    },
    alertRoot: {
        borderRadius: 0,
    },
    alertFilled: {
        color: '#ffffff',
        backgroundColor: '#02a5ce',
    },
    text: {
        hyphens: 'auto'
    },
    buttons: {
        textAlign: 'right',
        paddingTop: 16,
    },
    button: {
        color: '#e5f3f9',
        fontWeight: 600,
        marginLeft: 16,
    },
}));

class CookieConsentBanner extends PureComponent {
    render() {
        const {
            classes, userLang, acceptTrackingConsent, rejectTrackingConsent,
        } = this.props;
        return (
            <Snackbar
                classes={{
                    root: classes.snackbarRoot,
                    anchorOriginBottomCenter: classes.snackbarAnchor,
                }}
                open={!CookieAccess.hasMatomoCookie()}
                onClose={(event, reason) => {
                    // Ignore any click other than buttons.
                }}
            >
                <Alert
                    classes={{
                        root: classes.alertRoot,
                        filledInfo: classes.alertFilled,
                    }}
                    elevation={0}
                    variant="filled"
                    severity="info"
                >
                    <AlertTitle>
                        {L.t('cookieBannerTitle', userLang)}
                    </AlertTitle>
                    <div
                        className={classes.text}
                        dangerouslySetInnerHTML={{
                            __html: L.t('cookieBannerText', userLang),
                        }}
                    />
                    <div className={classes.buttons}>
                        <Button
                            className={classes.button}
                            size="small"
                            onClick={rejectTrackingConsent}
                            tabIndex={1}
                        >
                            {L.t('cookieBannerReject', userLang)}
                        </Button>
                        <Button
                            className={classes.button}
                            size="small"
                            onClick={acceptTrackingConsent}
                            tabIndex={2}
                        >
                            {L.t('cookieBannerAccept', userLang)}
                        </Button>
                    </div>
                </Alert>
            </Snackbar >
        );
    }
}

CookieConsentBanner.propTypes = {
    userLang: PropTypes.string,
    acceptTrackingConsent: PropTypes.func.isRequired,
    rejectTrackingConsent: PropTypes.func.isRequired,
};

export default function _CookieConsentBanner(props) {
    const classes = useStyles();
    return <CookieConsentBanner {...props} classes={classes} />;
}
