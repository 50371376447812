import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';

const useStylesByProps = (props) => {
    const color = props.color || '#ffffff';
    const backgroundColor = props.backgroundColor || '#66991A';

    return makeStyles((theme) => ({
        button: {
            borderRadius: 6,
            color: color,
            backgroundColor: backgroundColor,
            border: '2px solid ' + backgroundColor,
            boxShadow: '0px 6px 7px -4px rgba(0,0,0,0.2), 0px 11px 15px 1px rgba(0,0,0,0.14), 0px 4px 20px 3px rgba(0,0,0,0.12)',
            fontWeight: 900,
            fontSize: 19,
            textTransform: 'none',
            letterSpacing: 0.5,
            transition: '0.2s ease',
            '&:hover': {
                backgroundColor: backgroundColor,
                filter: 'brightness(120%)',
            },
            '&:active': {
                backgroundColor: backgroundColor,
                filter: 'brightness(120%)',
            },
            '&:focus': {
                backgroundColor: backgroundColor,
                filter: 'brightness(120%)',
            },
        },
        buttonInverted: {
            borderRadius: 6,
            color: backgroundColor,
            backgroundColor: color,
            border: '2px solid ' + backgroundColor,
            boxShadow: '0px 6px 7px -4px rgba(0,0,0,0.2), 0px 11px 15px 1px rgba(0,0,0,0.14), 0px 4px 20px 3px rgba(0,0,0,0.12)',
            fontWeight: 900,
            fontSize: 19,
            textTransform: 'none',
            letterSpacing: 0.5,
            transition: '0.2s ease',
            '&:hover': {
                color: color,
                backgroundColor: backgroundColor,
            },
            '&:active': {
                color: color,
                backgroundColor: backgroundColor,
            },
            '&:focus': {
                color: color,
                backgroundColor: backgroundColor,
            },
        },
        label: {
            paddingBottom: '0.2em',
        },
    }));
};

class ColorButton extends PureComponent {
    render() {
        const { classes, content, url, onClick, inverted, disabled,
        } = this.props;
        let target = url ? '_blank' : undefined;
        if(url && url.startsWith("/")) {
            target = '_self';
        }
        const rel = url ? 'noopener noreferrer' : undefined;
        return (
            <Button
                variant={inverted ? 'outlined' : 'contained'}
                fullWidth={true}
                // disableElevation={true}
                href={url}
                target={target}
                rel={rel}
                onClick={onClick}
                disabled={disabled}
                classes={{
                    root: inverted ? classes.buttonInverted : classes.button,
                    label: classes.label,
                }}
            >
                {content}
            </Button>
        );
    }
}

ColorButton.propTypes = {
    content: PropTypes.string.isRequired,
    url: PropTypes.string,
    onClick: PropTypes.func,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    inverted: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default function _ColorButton(props) {
    const useStyles = useStylesByProps(props);
    const classes = useStyles();
    return <ColorButton {...props} classes={classes} />;
}
