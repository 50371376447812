import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import PopupState, { bindTrigger, bindMenu, } from 'material-ui-popup-state'

const useStyles = makeStyles((theme) => ({
    settingsIcon: {
        fontSize: 'medium',
    },
    menuItem: {
        fontFamily: 'DinforPBR',
        fontWeight: 500,
        fontSize: 14,
        color: 'rgba(255,255,255,0.6)',
        height: 48,
        opacity: 0.72,
        borderTop: '1px solid rgba(255,255,255,0.32)',
        '&:hover': {
            color: '#ffffff',
        },
        '&:focus-visible': {
            color: '#ffe900',
        },
    },
    selectedMenuItem: {
        fontFamily: 'DinforPBR',
        fontWeight: 500,
        fontSize: 14,
        borderTop: '1px solid rgba(255,255,255,0.32)',
        color: '#ffffff',
        height: 48,
        opacity: 0.72,
        '&:focus-visible': {
            color: '#ffe900',
        },
    },
    menuPaper: {
        borderRadius: 4,
        backgroundColor: 'rgba(0,0,0,0.72)',
        border: '1px solid rgba(255,255,255,0.32)',
    },
    menuList: {
        padding: 0,
    },
    button: {
        filter: 'brightness(70%)',
        '&:hover': {
            filter: 'brightness(100%)',
        },
        '&:focus': {
            filter: 'brightness(100%)',
        },
    },
}));

class QualityButton extends PureComponent {
    render() {
        const {
            classes, levels, videoQuality, qualityCallback, visible,
        } = this.props;
        const buttonClassName = 'video-react-button video-react-control ' +
            classes.button;
        if (!visible) {
            return null;
        }
        return (
            <PopupState
                variant="popover"
                popupId="quality-popup-menu"
            >
                {(popupState) => {
                    const menuItems = levels.map((lvl) => {
                        return (
                            <MenuItem
                                key={'quality-menu-' + lvl.width}
                                className={
                                    videoQuality === lvl.width ?
                                        classes.selectedMenuItem :
                                        classes.menuItem
                                }
                                onClick={() => {
                                    qualityCallback(lvl.width);
                                    popupState.close();
                                }}
                            >{lvl.label}</MenuItem>
                        );
                    });
                    return (
                        <React.Fragment>
                            <button
                                variant="contained"
                                className={buttonClassName}
                                type='button'
                                {...bindTrigger(popupState)}
                            >
                                <SettingsIcon
                                    className={classes.settingsIcon} />
                            </button>
                            <Menu
                                classes={{
                                    paper: classes.menuPaper,
                                    list: classes.menuList,
                                }}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                {...bindMenu(popupState)}
                            >
                                {menuItems}
                            </Menu>
                        </React.Fragment>
                    );
                }}
            </PopupState>
        );
    }
}

QualityButton.propTypes = {
    levels: PropTypes.array.isRequired,
    videoQuality: PropTypes.number,
    qualityCallback: PropTypes.func.isRequired,
    visible: PropTypes.bool,
};

export default function _QualityButton(props) {
    const classes = useStyles();
    return <QualityButton {...props} classes={classes} />;
}
