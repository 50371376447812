export default class AltText {
    constructor() {
        this.text = '';
    }

    append(add) {
        if (add && add.length > 0) {
            this.text = (this.text.length > 0 ? this.text + ' ' : '') + add;
            let last = add.substring(add.length - 1);
            if (last.match(/^["”'’]$/g)) {
                last = add.substring(add.length - 2, add.length - 1);
            }
            if (last.match(/^[^.:!?,;]$/g)) {
                this.text += '.';
            }
        }
    }

    toString() {
        return this.text;
    }
}
