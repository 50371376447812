import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import WifiIcon from '@material-ui/icons/Wifi';

import { useMatomo } from '@datapunt/matomo-tracker-react'

import L from "../util/L10n";
import MaybeLink from "../util/MaybeLink";
import wifiConfig from '../config/wifi.json';

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: '8px',
        transform: 'translateZ(0)',
    },
    cardContent: {
        padding: '0px',
        // TODO: padding bottom overridden twice (!)
        // by MuiCardContent-root:last-child
        backgroundColor: '#66991a',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    icon: {
        fontSize: '2.8rem',
        color: '#ffffff',
        fontWeight: 'bold',
        padding: '12px 10px 6px',
    },
    text: {
        padding: '6px 10px',
    },
    title: {
        color: '#ffffff',
        fontWeight: 'bold',
    },
    subtitle: {
        color: '#ffffff',
    },
    link: {
        textDecoration: 'none',
        display: 'block',
        '&:focus-visible': {
            outline: '6px solid #888888',
            borderRadius: 6,
        },
    },
}));

class WifiGridItem extends PureComponent {
    _onClickWifiBox() {
        if (window.FgfChannel) {
            window.FgfChannel.postMessage("onWifiBoxClicked:");
        }
    }

    render() {
        const { classes, externalTracking, } = this.props;
        const userLang = undefined;
        return (
            <Grid item xs={12}>
                <MaybeLink
                    onClick={window.FgfChannel ? this._onClickWifiBox : null}
                    externalLink={wifiConfig.loginPageUrl}
                    target='_self'
                    className={classes.link}
                    externalTracking={externalTracking}
                >
                    <Card className={classes.card} elevation={11}>
                        <CardContent className={classes.cardContent}>
                            <div className={classes.row}>
                                <Box component='div'>
                                    <WifiIcon className={classes.icon} />
                                </Box>
                                <Box
                                    component='div'
                                    className={classes.text}
                                >
                                    <Typography variant='h5' component='h2'
                                        className={classes.title}
                                        aria-label={L.t('wifiGridItemTitle_ariaLabel',
                                            userLang)}
                                    >
                                        {L.t('wifiGridItemTitle_text',
                                            userLang)}
                                    </Typography>
                                    <Typography variant='subtitle1' component='span'
                                        className={classes.subtitle}>
                                        {L.t('wifiGridItemSubtitle_text',
                                            userLang)}
                                    </Typography>
                                </Box>
                            </div>
                        </CardContent>
                    </Card>
                </MaybeLink>
            </Grid >
        );
    }
}

WifiGridItem.propTypes = {
    userLang: PropTypes.string,
};

export default function _WifiGridItem(props) {
    const classes = useStyles();
    const { trackEvent } = useMatomo();
    const _onClickWeb = () => {
        trackEvent({
            category: 'wifi',
            action: 'click',
            name: 'banner',
        });
        return true;
    };
    return <WifiGridItem
        {...props}
        classes={classes}
        externalTracking={_onClickWeb}
    />;
}
