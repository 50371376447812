import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Carousel from './Carousel';

const useStyles = makeStyles((theme) => ({
}));

class ImageSlider extends PureComponent {
    render() {
        const {
            cards, autoPlay, entryPage, userLang, manager, keyValue,
            fullscreenVideo, duplicates, maxElements, isMediaSlider, bgUrl,
        } = this.props;
        if (cards.length >= 1) {
            return (
                <Carousel
                    autoPlay={autoPlay}
                    entryPage={entryPage}
                    userLang={userLang}
                    manager={manager}
                    key={keyValue}
                    fullscreenVideo={fullscreenVideo}
                    duplicates={duplicates}
                    isMediaSlider={isMediaSlider}
                    bgUrl={bgUrl}
                >
                    {cards.slice(0, maxElements)}
                </Carousel>
            );
        } else {
            return null;
        }
    }
}

ImageSlider.propTypes = {
    classes: PropTypes.object,
    cards: PropTypes.array.isRequired,
    autoPlay: PropTypes.bool,
    entryPage: PropTypes.bool,
    userLang: PropTypes.string,
    manager: PropTypes.object,
    keyValue: PropTypes.string,
    fullscreenVideo: PropTypes.bool,
    maxElements: PropTypes.number,
    isMediaSlider: PropTypes.bool,
    bgUrl: PropTypes.string,
}

ImageSlider.defaultProps = {
    maxElements: 6,
    isMediaSlider: false,
}

export default function _ImageSlider(props) {
    const classes = useStyles();
    const reduce = useMediaQuery('@media (prefers-reduced-motion: reduce)');
    const autoPlay = !!props.autoPlay && !reduce;
    return <ImageSlider {...props} classes={classes} autoPlay={autoPlay} />;
}
