import React from 'react'
import './styles.css';

export default function ScrambledDraggable({width, character, position, onDropped, observable}) {

    function onDrag(event) {
        event.preventDefault();
        /*setState({
            //draggedTask: todo
        });*/
    }

    function onDragStart(event) {
        if(observable.getIsSolved()) return;
        let element = event.target;
        element.classList.add('hide');
        event.dataTransfer.setData("json", JSON.stringify({character: character, position: position}));
        observable.clearDroppedPosition();
    }

    function onDragEnd(event) {
        if(observable.getIsSolved()) return;
        let element = event.target;
        element.classList.remove('hide');
        let dropped = observable.getDroppedPosition() === position;

        if (onDropped) onDropped(position, dropped);
        observable.clearDroppedPosition();
    }

    var paddingTop = width / 12;
    if(width < 30) {
        paddingTop = width / 9;
    }

    return (
        <div
            id={position}
            className={"draggable"}
            style={{
                width: '100%',
                height: '100%',
                borderRadius: '20%'

            }}
            draggable
            onDrag={(event) => onDrag(event)}
            onDragStart={(event) => onDragStart(event)}
            onDragEnd={(event) => onDragEnd(event)}
        >
            <div
                style={{
                    fontSize: width/1.5,
                    fontWeight: 800,
                    textAlign: 'center',
                    paddingTop: paddingTop,
                    borderRadius: '20%',
                    color: 'white',
                }}
            >
                {character}
            </div>
        </div>
    )
}