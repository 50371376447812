import React from 'react';
import './fonts.css';
import './App.css';
import Main from './Main'
import DatePickerSetup from './preview/DatePickerSetup';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';

import config from './config/api.json';

function App() {
  const instance = createInstance({
    urlBase: config.matomoUrlBase,
    siteId: config.matomoSiteId,
    enableLinkTracking: false,
    configurations: {
      requireConsent: true,
    },
    //userId: 'UID76903202', // optional, default value: `undefined`.
    // trackerUrl: 'https://LINK.TO.DOMAIN/tracking.php', // optional, default value: `${urlBase}matomo.php`
    // srcUrl: 'https://LINK.TO.DOMAIN/tracking.js', // optional, default value: `${urlBase}matomo.js`
    // disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
    heartBeat: { // optional, enabled by default
      active: true, // optional, default value: true
      seconds: 10 // optional, default value: `15
    },
    //linkTracking: false, // optional, default value: true
    //configurations: { // optional, default value: {}
    //  // any valid matomo configuration, all below are optional
    //  disableCookies: true,
    //  setSecureCookie: true,
    //  setRequestMethod: 'POST'
    //}
  })

  return (
    <MatomoProvider value={instance}>
      <DatePickerSetup>
        <Main />
      </DatePickerSetup>
    </MatomoProvider>
  );
}

export default App;
