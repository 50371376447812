export default class AudioRecorderController {
    constructor() {
        this.resetCallback = null;
    }

    reset() {
        if(this.resetCallback) {
            this.resetCallback();
        }
    }

    setResetCallback(callback) {
        this.resetCallback = callback;
    }
}