import React, {PureComponent} from 'react';
import PropTypes from "prop-types";

import {makeStyles} from "@material-ui/core/styles";

import ScrambledWord from "./scrambledword/ScrambledWord";

const useStyles = makeStyles((theme) => ({
    scrambledWordCard: {},
    title: {
        color: '#000000',
        marginBottom: 6,
    },
    divider: {
        height: 2,
        backgroundColor: '#f3f1f3',
        marginBottom: 16,
    },
    card: {
        borderRadius: '8px',
        transform: 'translateZ(0)',
        padding: 20,
        backgroundColor: '#f3f1f3',
    },
    cardContent: {},
    quote: {
        '& strong': {
            fontWeight: 500,
        },
        '& em': {
            fontStyle: 'italic',
        },
        '& s': {
            textDecoration: 'line-through',
        },
        '& u': {
            textDecoration: 'underline',
        },
        '& sup': {
            verticalAlign: 'super',
            fontSize: 'smaller',
        },
        '& sub': {
            verticalAlign: 'sub',
            fontSize: 'smaller',
        },
        '& p': {
            margin: 0,
        },
    },


}));

class ScrambledWordCard extends PureComponent {
    render() {
        const {classes, data, userLang, showAnimation, stopAnimation} = this.props;

        return (
            <div className={classes.scrambledWordCard}>

                <ScrambledWord userLang={userLang}
                               scrambledWord={data.shaked.toUpperCase()}
                               word={data.solved.toUpperCase()}
                               info={data.info}
                               buttonColor={data.buttonColor}
                               buttonStyle={data.buttonStyle}
                               buttonText={data.buttonText}
                               messageCorrect={data.messageCorrect}
                               messageWrong={data.messageWrong}
                               showAnimation={showAnimation}
                               stopAnimation={stopAnimation}>
                </ScrambledWord>
            </div>
        );
    }
}

ScrambledWordCard.propTypes = {
    data: PropTypes.object.isRequired,
    userLang: PropTypes.string,
    showAnimation: PropTypes.func.isRequired,
    stopAnimation: PropTypes.func.isRequired,

};

export default function _ScrambledWordCard(props) {
    const classes = useStyles();
    return <ScrambledWordCard {...props} classes={classes}/>;
}
