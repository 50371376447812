export default class Observable {
    constructor() {
        this.observers = [];
        this.droppedPosition = null;
        this.results = [];
        this.complete = false;
        this.correctSolution = false;
        this.completeCallback = null;
        this.isSolved = false;
    }

    setIsSolved(value) {
        this.isSolved =  value;
    }

    getIsSolved() {
        return this.isSolved;
    }

    setDropPosition(position) {
        this.droppedPosition =  position;
    }

    getDroppedPosition() {
        return this.droppedPosition;
    }

    clearDroppedPosition() {
        this.droppedPosition = null;
    }

    setResultLength(length) {
        if(this.results.length === 0) {
            this.results = Array(length).fill(null);
        }
    }

    setResult(index, result) {

        if(this.results.length === 0) return;
        this.results[index] = result;

        let isComplete = true;
        let isCorrectSolution = true;

        for(let i = 0; i < this.results.length; i++) {
            isComplete = isComplete && this.results[i] !== null;
            isCorrectSolution = isCorrectSolution && this.results[i] === true;
        }
        this.correctSolution = isCorrectSolution;
        if(isComplete !== this.complete) {
            this.complete = isComplete;
            if(this.completeCallback !== null) {
                this.completeCallback(this.complete);
            }
        }
    }

    setCompleteCallback(callback) {
        this.completeCallback = callback;
    }

    getCorrectSolution() {
        return this.correctSolution;
    }

    subscribe(func) {
        this.observers.push(func);
    }

    unsubscribe(func) {
        this.observers = this.observers.filter((observer) => observer !== func);
    }

    notify(data) {
        this.observers.forEach((observer) => observer(data));
    }
}

