import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import VideoCardContent from './VideoCardContent';

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: '8px',
        transform: 'translateZ(0)',
    },
    cardFullscreen: {
        borderRadius: '8px',
    },
    cardContent: {
        padding: 0,
        backgroundColor: '#000000',
    },
}));

class VideoCard extends PureComponent {
    render() {
        const {
            classes, data, videoQuality, videoQualityCallback,
            fullscreenVideo, fullscreenVideoCallback, userLang,
        } = this.props;
        return (
            <Card
                className={fullscreenVideo ?
                    classes.cardFullscreen : classes.card}
                elevation={11}
            >
                <CardContent className={classes.cardContent}>
                    <VideoCardContent
                        data={data}
                        videoQuality={videoQuality}
                        videoQualityCallback={videoQualityCallback}
                        fullscreenVideo={fullscreenVideo}
                        fullscreenVideoCallback={fullscreenVideoCallback}
                        playsInline={true}
                        userLang={userLang}
                    />
                </CardContent>
            </Card>
        );
    }
}

VideoCard.propTypes = {
    data: PropTypes.object.isRequired,
    videoQuality: PropTypes.number,
    videoQualityCallback: PropTypes.func,
    fullscreenVideo: PropTypes.bool,
    fullscreenVideoCallback: PropTypes.func,
    userLang: PropTypes.string,
};

export default function _VideoCard(props) {
    const classes = useStyles();
    return <VideoCard {...props} classes={classes} />;
}
