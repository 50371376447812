import axios from 'axios';

import config from '../config/api.json';

async function getInfo(path, onDataReceived, minCallTime) {
    const url = config.api_base + config.action_path + path;
    let start = Date.now();
    const response = await axios.get(url, {
        transformResponse: [(data) => {
            return data
        }],
    });
    if (response && 200 === response.status && response.data) {
        let duration = Date.now() - start;
        if (minCallTime && duration < minCallTime) {
            setTimeout(() =>{onDataReceived(JSON.parse(response.data));}, minCallTime - duration);
        } else {
            onDataReceived(JSON.parse(response.data));
        }
    } else {
        throw new Error('request failed', response);
    }
}

export async function getTokenInfo(entryId, actionId, onDataReceived) {
    const path = 'token/' + entryId + '/' + actionId;
    await getInfo(path, onDataReceived, 1000);
}

export async function getActionInfo(entryId, actionId, onDataReceived) {
    const path = 'info/' + entryId + '/' + actionId;
    await getInfo(path, onDataReceived);
}

export async function getVoucherInfo(entryId, actionId, password,
                                     onDataReceived) {
    const path = 'extinfo/' + entryId + '/' + actionId + '/' + password;
    await getInfo(path, onDataReceived);
}

export async function setVoucherUsed(entryId, actionId, voucher, password,
                                     onDataReceived) {
    const path = 'qrcode/' + entryId + '/' + actionId + '/' + password +
        '/' + voucher;
    await getInfo(path, onDataReceived);
}
