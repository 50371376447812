import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import DetectLocale from './DetectLocale';

// TODO: Use of this component causes warning UNSAFE_componentWillMount.
// Should fix this, maybe not a purecomponent?
export default class AppHelmet extends PureComponent {
    render() {
        const { documentTitle, userLang, darkMode } = this.props;
        const lang = userLang ? userLang : DetectLocale.getUserLanguage();
        const colorScheme = darkMode ? 'dark' : 'light';
        return (
            <Helmet>
                <html lang={lang} />
                <title>{documentTitle}</title>
                <meta name='color-scheme' content={colorScheme} />
            </Helmet>
        );
    }
}

AppHelmet.propTypes = {
    documentTitle: PropTypes.string,
    userLang: PropTypes.string,
    darkMode: PropTypes.bool,
};

AppHelmet.defaultProps = {
    documentTitle: 'documentTitle',
}
