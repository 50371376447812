import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Config from '../config/ui_settings.json';

export default class EntryPageLoading extends PureComponent {
    render() {
        const { message } = this.props;
        return (
            <Grid
                container
                direction='row'
                justify='center'
                alignItems='center'
                spacing={Config.gridSpacing}
            >
                <Grid item xs={12} md={6}>
                    <Box>
                        <Typography variant='subtitle1' component='span'>
                            {message}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        );
    }
}

EntryPageLoading.propTypes = {
    message: PropTypes.string,
};
