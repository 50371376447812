import React, {Fragment, PureComponent, useState} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch as RouterSwitch, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import {createMuiTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import {KeyboardDateTimePicker} from '@material-ui/pickers';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {useMatomo} from '@datapunt/matomo-tracker-react';

import L from './util/L10n';
import DetectLocale from './util/DetectLocale';
import AppHelmet from './util/AppHelmet';
import PageContent from './PageContent';
import TopAppBar from './components/TopAppBar';
import BottomTabBar from './components/BottomTabBar';
import Footer from './components/Footer';
import CookieConsentBanner from './components/CookieConsentBanner';
import MainMenu from './MainMenu';
import previewConfig from './config/preview.json';
import Config from './config/ui_settings.json';
import GlobalAnimation from "./GlobalAnimation";
import SurveyResultPage from "./page/SurveyResultPage";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    previewForm: {
        margin: '10px',
        textAlign: 'center',
        verticalAlign: 'middle',
    },
    textField: {
        margin: '8px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    iframe: {
        margin: '0 auto',
        display: 'block',
    },
    footerWrapper: {
        position: 'relative',
        minHeight: '100vh',
        backgroundImage:
            'url(' + process.env.PUBLIC_URL + '/images/background-01.png)',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        [theme.breakpoints.down('sm')]: {
            backgroundPosition: '0px 104px',
            backgroundSize: '1920px auto',
        },
        [theme.breakpoints.up('sm')]: {
            backgroundPosition: '0px 112px',
            backgroundSize: '1920px auto',
        },
        [theme.breakpoints.up('xl')]: {
            backgroundSize: '100% 768px',
        },
    },
}));

const prefersDarkMode =
    window.matchMedia('(prefers-color-scheme: dark)').matches;

const themeConfig = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            sd: 720,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    palette: {
        primary: {
            main: '#00c4ff',
            contrastText: '#777777',
        },
        secondary: {
            light: '#0066ff',
            main: '#0044ff',
            // dark: will be calculated from palette.secondary.main,
            contrastText: '#ffcc00',
        },
        background: {
            default: '#ffffff',
        },
        type: prefersDarkMode ? 'dark' : 'light',
    },
    typography: {
        fontFamily: [
            'DinforPBR',
        ].join(','),
    },
    props: {
        MuiTypography: {
            variantMapping: {
                h6: 'h1',
            },
        },
    },
    overrides: {
        MuiCardContent: {
            root: {
                padding: '0px',
                '&:last-child': {
                    paddingBottom: '0px',
                },
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: '#1bb2d7',
            },
        },
        MuiContainer: {
            maxWidthSm: {
                '@media (min-width: 600px)': {
                    maxWidth: '552px', // 600 - gutter padding
                },
                '@media (min-width: 720px)': {
                    maxWidth: '648px', // 720 * .9
                },
                '@media (min-width: 960px)': {
                    maxWidth: '864px', // 960 * .9
                },
                '@media (min-width: 1280px)': {
                    maxWidth: '1152px', // 1280 * .9
                },
                '@media (min-width: 1920px)': {
                    maxWidth: '1456px', // 720 * 2 + padding
                },
            },
        },
        MuiListItemText: {
            primary: {
                fontWeight: 500,
            },
        },
        MuiTabs: {
            indicator: {
                backgroundColor: '#28a8ca',
            },
        },
        MuiTab: {
            textColorInherit: {
                color: '#828282',
                '&$selected': {
                    color: '#28a8ca',
                },
            },
        },
    },
};

const appBarThemeConfig = {
    ...themeConfig,
    palette: {
        primary: {
            main: '#EEEEEE',
            contrastText: '#777777',
        },
        ...themeConfig.palette.secondary,
    },
};

const useDarkMode = () => {
    const [theme, setTheme] = useState(themeConfig);
    const toggleDarkMode = (mode) => {
        const updatedTheme = {
            ...theme,
            palette: {
                ...theme.palette,
                type: mode ? 'dark' : 'light',
            }
        }
        setTheme(updatedTheme);
    }
    return [theme, toggleDarkMode];
}

let previewDevicesMap = new Map();
previewConfig.devices.forEach((device) => {
    previewDevicesMap.set(device.label, device);
});

class Main extends PureComponent {

    constructor(props) {
        super(props);
        let previewDate = props.previewDate;
        if (!previewDate) {
            const date = new Date();
            const now = Date.now() - 60000 * date.getTimezoneOffset();
            previewDate = new Date(now).toISOString().substr(0, 16);
        } else {
            const spaceAt = previewDate.indexOf(' ');
            if (spaceAt > 0) {
                previewDate = previewDate.substr(0, spaceAt) + 'T' +
                    previewDate.substr(spaceAt + 1);
            }
        }
        this.state = {
            showAnimation: false,
            animationData: null,
            loopAnimation: false,
            showDrawer: false,
            darkMode: props.prefersDarkMode,
            previewDate: previewDate,
            previewWidth:
            previewDevicesMap.get(previewConfig.defaultDevice).width,
            previewHeight:
            previewDevicesMap.get(previewConfig.defaultDevice).height,
            previewDevice: previewConfig.defaultDevice,
            userLang: props.userLang,
            videoQuality: 1280,
            fullscreenVideo: false,
            textOnly: false,
        };
        this.showDrawer = this._showDrawer.bind(this);
        this.hideDrawer = this._hideDrawer.bind(this);
        this.toggleDarkMode = this._toggleDarkMode.bind(this);
        this.setTextOnly = this._setTextOnly.bind(this);
        this.updatePreviewDate = this._updatePreviewDate.bind(this);
        this.updatePreviewDevice = this._updatePreviewDevice.bind(this);
        this.updateUserLang = this._updateUserLang.bind(this);
        this.updateVideoQuality = this._updateVideoQuality.bind(this);
        this.updateFullscreenVideo = this._updateFullscreenVideo.bind(this);
        this.startAnimation = this._startAnimation.bind(this);
        this.stopAnimation = this._stopAnimation.bind(this);

    }

    _toggleDrawer(event, show) {
        if (event && event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift' ||
                event.key === 'Enter')) {
            return;
        }

        this.setState({showDrawer: show});
    }

    _showDrawer(event) {
        this._toggleDrawer(event, true);
    };

    _hideDrawer(event) {
        this._toggleDrawer(event, false);
    };

    _toggleDarkMode() {
        const newMode = !this.state.darkMode;
        this.props.switchDarkmode(newMode);
        this.setState({darkMode: newMode,});
    }

    _setTextOnly(textOnly) {
        this.setState({textOnly});
    }

    _updatePreviewDate(newPreviewDate) {
        if (newPreviewDate !== '' &&
            newPreviewDate !== this.state.previewDate) {
            this.setState({previewDate: newPreviewDate,})
        }
    }

    _updatePreviewDevice(newPreviewDevice) {
        if (newPreviewDevice !== this.state.previewDevice) {
            const newState = {
                previewDevice: newPreviewDevice,
                previewWidth: previewDevicesMap.get(newPreviewDevice).width,
                previewHeight: previewDevicesMap.get(newPreviewDevice).height,
            };
            this.setState(newState);
        }
    }

    _updateUserLang(newUserLang) {
        const {location, userLangEvent,} = this.props;
        const params = new URLSearchParams(location.search);
        params.set('lang', newUserLang);
        userLangEvent(newUserLang);
        const search = params.toString();
        window.location.assign(location.pathname + '?' + search);
    }

    _updateVideoQuality(newVideoQuality) {
        if (this.state.videoQuality !== newVideoQuality) {
            this.setState({videoQuality: newVideoQuality,});
        }
    }

    _updateFullscreenVideo(newFullscreenVideo) {
        if (this.state.fullscreenVideo !== newFullscreenVideo) {
            this.setState({fullscreenVideo: newFullscreenVideo,});
        }
    }

    componentDidMount() {
        this.props.switchDarkmode(this.state.darkMode);
    }

    render() {
        return (
            <RouterSwitch>
                <Route path='/wp-preview'>
                    {this.renderFramed()}
                </Route>
                <Route>
                    {this.renderMain()}
                </Route>
            </RouterSwitch>
        );
    }

    pad(number) {
        if (number < 10)
            return '0' + number;
        else
            return number;
    }

    getIsoDateString() {
        const date = this.state.previewDate;
        if ('string' === typeof date) {
            return date;
        } else {
            return date.getFullYear() + '-' +
                this.pad(1 + date.getMonth()) + '-' +
                this.pad(date.getDate()) + 'T' +
                this.pad(date.getHours()) + ':' +
                this.pad(date.getMinutes());
        }
    }

    renderFramed() {
        const {location, previewToken, classes,} = this.props;
        const iframeName = 'content-preview';
        const iframeTitle = 'Content Preview';
        let iframeSrc = location.pathname.indexOf('/wp-preview') === 0 ?
            location.pathname.substr('/wp-preview'.length) : undefined;
        if (iframeSrc) {
            if (iframeSrc === '') {
                iframeSrc = '/';
            }
            const params = new URLSearchParams(location.search);
            params.set('date', this.getIsoDateString());
            iframeSrc += '?' + params.toString();
        }
        if (iframeSrc && iframeSrc.length > 0 && previewToken) {
            const previewDeviceOptions = previewConfig.devices.map((device) => {
                const name = device.name + ' (' + device.width + 'x' +
                    device.height + ')';
                return (
                    <option value={device.label} key={device.label}>
                        {name}
                    </option>
                );
            });
            return (
                <Container
                    disableGutters={true}
                    maxWidth={Config.previewContainerMaxWidth}
                >
                    <form className={this.props.classes.previewForm}>
                        <KeyboardDateTimePicker
                            id='preview-date'
                            className={this.props.classes.textField}
                            value={this.state.previewDate}
                            onChange={(value) => {
                                const dateTime = value;
                                if (dateTime) {
                                    this.updatePreviewDate(dateTime);
                                }
                            }}
                            label={L.t('previewDateTimeLabel',
                                this.state.userLang)}
                            showTodayButton={true}
                            todayLabel={L.t('previewDateTodayLabel',
                                this.state.userLang)}
                            okLabel={L.t('previewDateOkLabel',
                                this.state.userLang)}
                            cancelLabel={L.t('previewDateCancelLabel',
                                this.state.userLang)}
                            format={L.t('previewDateFormat',
                                this.state.userLang)}
                            invalidDateMessage={L.t('previewDateInvalidLabel',
                                this.state.userLang)}
                            minDateMessage={L.t('previewDateMaxLabel',
                                this.state.userLang)}
                            maxDateMessage={L.t('previewDateMinLabel',
                                this.state.userLang)}
                            maxDate='2099-12-31'
                            autoOk={true}
                            ampm={false}
                        />
                        <FormControl className={classes.formControl}>
                            <InputLabel shrink htmlFor='preview-device'>
                                {L.t('previewDeviceLabel',
                                    this.state.userLang)}
                            </InputLabel>
                            <NativeSelect
                                id='preview-device'
                                value={this.state.previewDevice}
                                name='previewDevice'
                                onChange={(event) => {
                                    this.updatePreviewDevice(event.target.value);
                                }}
                            >
                                {previewDeviceOptions}
                            </NativeSelect>
                        </FormControl>
                    </form>

                    <iframe className={this.props.classes.iframe}
                            width={this.state.previewWidth}
                            height={this.state.previewHeight}
                            src={iframeSrc}
                            name={iframeName}
                            title={iframeTitle}
                    />
                </Container>
            );
        } else {
            const to = {
                pathname: '/',
                search: location.search,
            };
            return (
                <Redirect to={to}/>
            );
        }
    }

    _startAnimation(animation, loop) {
        this.setState({showAnimation: true, animationData: animation, loopAnimation: loop})
    }

    _stopAnimation() {
        this.setState({showAnimation: false, animationData: null, loopAnimation: false})
    }

    renderMain() {
        const {
            theme, appEmbedded, showWifiLogin, appBarTheme, previewToken,
            previewDate, appServerPort, classes, deviceSize,
            acceptTrackingConsent, rejectTrackingConsent, scrollableTabs,
        } = this.props;
        const {
            showDrawer, darkMode, videoQuality, fullscreenVideo,
            textOnly,
        } = this.state;
        const userLang = 'de';
        document.body.style.backgroundColor = theme.palette.background.default;
        // Disable bottom tab bar.
        let ga = null;
        if (this.state.showAnimation) ga =
            <GlobalAnimation onStop={this.stopAnimation} animationData={this.state.animationData}
                             loopAnimation={this.state.loopAnimation}/>

        return (
            <RouterSwitch>
                <Route
                    key='surveyResult'
                    path={['/surveyresult/:entryId/:surveyId']}>
                    <SurveyResultPage
                        appServerPort={appServerPort}
                        userLang={userLang}

                    />
                </Route>
                <Route>
                    <Fragment>
                        <AppHelmet
                            documentTitle={L.t('appTitle', userLang)}
                            userLang={userLang}
                            darkMode={darkMode}
                        />
                        <ThemeProvider theme={appBarTheme}>
                            <TopAppBar
                                onMenuButtonClick={this.showDrawer}
                                appEmbedded={appEmbedded}
                                showWifiLogin={showWifiLogin}
                                scrollableTabs={scrollableTabs}
                                userLang={userLang}
                                previewToken={previewToken}
                                previewDate={previewDate}
                            />
                        </ThemeProvider>
                        <ThemeProvider theme={theme}>
                            <div className={classes.footerWrapper}>
                                <RouterSwitch>
                                    <Route path={['/entry', '/beitrag',]}/>
                                    <Route>
                                        <MainMenu
                                            anchor='left'
                                            open={showDrawer}
                                            onClose={this.hideDrawer}
                                            onOpen={this.showDrawer}
                                            toggleDarkMode={this.toggleDarkMode}
                                            darkMode={darkMode}
                                            setTextOnly={this.setTextOnly}
                                            textOnly={textOnly}
                                            appEmbedded={appEmbedded}
                                            userLang={userLang}
                                        />
                                    </Route>
                                </RouterSwitch>
                                <PageContent
                                    appServerPort={appServerPort}
                                    appEmbedded={appEmbedded}
                                    showWifiLogin={showWifiLogin}
                                    previewToken={previewToken}
                                    previewDate={previewDate}
                                    userLang={userLang}
                                    updateUserLang={this.updateUserLang}
                                    videoQuality={videoQuality}
                                    videoQualityCallback={this.updateVideoQuality}
                                    fullscreenVideo={fullscreenVideo}
                                    fullscreenVideoCallback={this.updateFullscreenVideo}
                                    deviceSize={deviceSize}
                                    textOnly={textOnly}
                                    setTextOnly={this.setTextOnly}
                                    showAnimation={this.startAnimation}
                                    stopAnimation={this.stopAnimation}

                                />
                                <BottomTabBar
                                    appEmbedded={true || appEmbedded}
                                    userLang={userLang}
                                />
                                {ga}
                                <Footer
                                    userLang={userLang}
                                    updateUserLang={this.updateUserLang}
                                />
                                <CookieConsentBanner
                                    userLang={userLang}
                                    acceptTrackingConsent={acceptTrackingConsent}
                                    rejectTrackingConsent={rejectTrackingConsent}
                                />
                            </div>
                        </ThemeProvider>
                    </Fragment>
                </Route>
            </RouterSwitch>
        );
    }
}

Main.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    appBarTheme: PropTypes.object,
    prefersDarkMode: PropTypes.bool,
    switchDarkmode: PropTypes.func,
    appServerPort: PropTypes.number,
    appEmbedded: PropTypes.bool,
    showWifiLogin: PropTypes.bool,
    previewToken: PropTypes.string,
    previewDate: PropTypes.string,
    userLang: PropTypes.string,
    deviceSize: PropTypes.string,
    scrollableTabs: PropTypes.bool,
}

const MainContent = withRouter((props) => {
    const params = new URLSearchParams(props.location.search);
    let appServerPort;
    let appEmbedded = false;
    if (params.has('app')) {
        appEmbedded = true;
        let portString = params.get('app');
        let portNumber = parseInt(portString);
        if (portNumber && portNumber >= 0) {
            appServerPort = portNumber;
        }
    }
    const showWifiLogin = params.has('login') && params.get('login') === 'true';
    let prefersDarkMode = props.prefersDarkMode;
    if (params.has('dark')) {
        if (params.get('dark') === 'on') {
            prefersDarkMode = true;
        } else if (params.get('dark') === 'off') {
            prefersDarkMode = false;
        }
    }
    // Disable dark mode.
    prefersDarkMode = false;
    const previewToken = params.has('token') ? params.get('token') : undefined;
    const previewDate = params.has('date') ? params.get('date') : undefined;
    let userLang = null;
    if (params.has('lang')) {
        const lang = params.get('lang');
        if (DetectLocale.isLanguageSupported(lang)) {
            userLang = lang;
        }
    }

    const {enableLinkTracking, trackEvent, pushInstruction} = useMatomo();
    enableLinkTracking();

    const _userLangEvent = (newLang) => {
        trackEvent({
            category: 'lang',
            action: 'changeTo',
            name: newLang,
        });
        return true;
    };

    const acceptTrackingConsent = () => {
        pushInstruction('rememberConsentGiven'); // , 9480);
        window.location.assign(window.location.pathname +
            window.location.search);
    };

    const rejectTrackingConsent = () => {
        pushInstruction('forgetConsentGiven'); // , 9480);
        window.location.assign(window.location.pathname +
            window.location.search);
    };

    return <Main
        {...props}
        appEmbedded={appEmbedded}
        appServerPort={appServerPort}
        showWifiLogin={showWifiLogin}
        prefersDarkMode={prefersDarkMode}
        previewToken={previewToken}
        previewDate={previewDate}
        userLang={userLang}
        userLangEvent={_userLangEvent}
        acceptTrackingConsent={acceptTrackingConsent}
        rejectTrackingConsent={rejectTrackingConsent}
    />;
});

export default function _Main(props) {
    const classes = useStyles();
    const [newThemeConfig, toggleDarkMode] = useDarkMode();
    const setDarkMode = (mode) => {
        toggleDarkMode(mode);
    };
    const theme = createMuiTheme(newThemeConfig);
    const appBarTheme = createMuiTheme(appBarThemeConfig);
    const isTablet = useMediaQuery(theme.breakpoints.up('sm'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    let deviceSize = 'smartphone';
    if (isDesktop) {
        deviceSize = 'desktop';
    } else if (isTablet) {
        deviceSize = 'tablet';
    }
    const scrollableTabs = !useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Router>
            <MainContent
                {...props}
                classes={classes}
                theme={theme}
                appBarTheme={appBarTheme}
                prefersDarkMode={prefersDarkMode}
                switchDarkmode={setDarkMode}
                deviceSize={deviceSize}
                scrollableTabs={scrollableTabs}
            />
        </Router>
    );
}
