import React, {PureComponent} from 'react';
import PropTypes from "prop-types";

import {makeStyles} from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {withRouter} from "react-router-dom";
import ExternalContent from "./externalcontent/ExternalContent";

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: 8,
        transform: 'translateZ(0)',
    },
    focusedCard: {
        borderRadius: 8,
        transform: 'translateZ(0)',
        outline: '6px solid #888888',
    },
    textOnlyCard: {
        borderRadius: 0,
        transform: 'translateZ(0)',
        outline: '1px solid #888888',
    },
    cardContent: {
        padding: 0,
    },
}));

class ExternalContentCard extends PureComponent {
    constructor(props) {
        super(props);
        const focused = false;
        this.state = { focused };
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    onFocus() {
        const focused = true;
        this.setState({ focused });
    }

    onBlur() {
        const focused = false;
        this.setState({ focused });
    }

    render() {
        const {
            classes, data, userLang, textOnly,
        } = this.props;
        const {focused} = this.state;
        if (data.display) {
            return (
                <Card
                    className={focused ? classes.focusedCard :
                        (textOnly ? classes.textOnlyCard : classes.card)}
                    elevation={textOnly ? 0 : 11}
                >
                    <CardContent className={classes.cardContent}>
                        <ExternalContent
                            userLang={userLang}
                            extType={data.extType}
                            display={data.display}
                        >
                        </ExternalContent>
                    </CardContent>
                </Card>
            );
        } else {
            return (
                    <CardContent className={classes.cardContent}>
                        <ExternalContent
                            userLang={userLang}
                            extType={data.extType}
                        >
                        </ExternalContent>
                    </CardContent>
            );
        }
    }

}

ExternalContentCard.propTypes = {
    data: PropTypes.object,
    parentEntry:PropTypes.object,
    userLang: PropTypes.string,
};


export default withRouter((props) => {
    const classes = useStyles();
    return <ExternalContentCard {...props} classes={classes} />;
});

