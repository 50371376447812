import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import { useMatomo } from '@datapunt/matomo-tracker-react'

import L from "../util/L10n";
import EntryUrl from '../util/EntryUrl';
import InvisibleImg from '../a11y/InvisibleImg';

const useStyles = makeStyles((theme) => ({
    iconButton: {
        padding: 0,
        marginLeft: -10,
    },
    whatsappIcon: {
        fontSize: 48,
        transform: 'scale(0.643)',
        color: '#ffffff',
        backgroundColor: '#25de66',
        borderRadius: 8,
        padding: 4,
    },
}));

class ShareWhatsappButton extends PureComponent {
    render() {
        const { classes, userLang, entry, trackShare } = this.props;
        const url = EntryUrl.getUrl(entry);
        const params = { url };
        const text = L.t('shareWhatsappText', userLang, params);
        const href = 'https://wa.me/?text=' + encodeURIComponent(text);
        const altText = L.t('shareWhatsappAlt', userLang);
        return (
            <IconButton
                className={classes.iconButton}
                component='a'
                href={href}
                target='_blank'
                rel='noopener'
                onClick={() => {
                    trackShare(url);
                }}
            >
                <WhatsAppIcon
                    className={classes.whatsappIcon}
                />
                <InvisibleImg
                    alt={altText}
                />
            </IconButton>
        );
    }
}

ShareWhatsappButton.propTypes = {
    classes: PropTypes.object,
    entry: PropTypes.object,
    trackShare: PropTypes.func,
    userLang: PropTypes.string,
};

export default function _ShareWhatsappButton(props) {
    const classes = useStyles();
    const { trackEvent } = useMatomo();
    const onShare = (url) => {
        trackEvent({
            category: 'share',
            action: 'whatsapp',
            name: url,
        });
        return true;
    };
    return (
        <ShareWhatsappButton
            {...props}
            classes={classes}
            trackShare={onShare}
        />
    );
}
