import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";

import Grid from "@material-ui/core/Grid";

export default class GridItem extends PureComponent {
    getKey(prefix) {
        const { keyValue } = this.props;
        return keyValue ? prefix + keyValue : undefined;
    }

    render() {
        const { children, twoColumnLayout } = this.props;
        const single =
            <Grid
                item
                xs={12}
                key={this.getKey('g')}
            >
                {children}
            </Grid>;
        const double =
            <Fragment
                key={this.getKey('f')}
            >
                <Grid
                    item
                    xs={3}
                    key={this.getKey('gl')}
                />
                <Grid
                    item
                    xs={6}
                    key={this.getKey('gc')}
                >
                    {children}
                </Grid>
                <Grid
                    item
                    xs={3}
                    key={this.getKey('gr')}
                />
            </Fragment>
        return twoColumnLayout ? double : single;
    }
}

GridItem.propTypes = {
    keyValue: PropTypes.string,
    twoColumnLayout: PropTypes.bool,
}
