import React, { PureComponent, Fragment } from "react";
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useMatomo } from '@datapunt/matomo-tracker-react';

import ImageCard from "./cards/ImageCard";
import getViewData from "./data/ViewReader";
import headViewData from "./data/ViewReaderWithHeadRequest";
import L from "./util/L10n";
import Columns from './teaserlayout/Columns';
import WifiGridItem from './components/WifiGridItem';
import PageTitleItem from './components/PageTitleItem';
import CarouselImageItem from './carousel/CarouselImageItem';
import CarouselBannerItem from './carousel/CarouselBannerItem';
import ImageCardContent from './cards/ImageCardContent';
import BannerCardContent from './cards/BannerCardContent';
import CarouselManager from './carousel/CarouselManager';
import SliderBuilder from './teaserlayout/SliderBuilder';
import SectionSliderBuilder from './teaserlayout/SectionSliderBuilder';
import BannerSliderBuilder from "./teaserlayout/BannerSliderBuilder";
import FullBuilder from './teaserlayout/FullBuilder';
import SmallBuilder from './teaserlayout/SmallBuilder';
import BannerBuilder from './teaserlayout/BannerBuilder';
import SectionTitle from './teaserlayout/SectionTitle';
import SectionBuilder from "./teaserlayout/SectionBuilder";
import PageHelmet from './util/PageHelmet';
import ImageResizerUrl from './util/ImageResizerUrl';

import Config from './config/ui_settings.json';
import CanonicalUrl from "./util/CanonicalUrl";
import SurveyCard from "./cards/SurveyCard";

const useStyles = makeStyles((theme) => ({
}));

class TeaserPage extends PureComponent {
    constructor(props) {
        super(props);
        const historyState = props.location.state || {};
        const { lastModified } = historyState;
        let { data } = historyState;
        if(data && !(typeof data[Symbol.iterator] === 'function')) {
            data = null;
        }
        let level;
        if(props.routing) {
            level = props.routing.match.params.level;
        }

        this.state = {
            level: level,
            error: null,
            data: data || null,
            lastModified: lastModified || null,
            userLang: props.userLang,
        };
        this.onDataReceived = this._onDataReceived.bind(this);
    }

    componentDidMount() {
        try {
            window.scrollTo(0, 0);
        } catch (e) {
            // Ignore.
        }
        this.load();
    }

    componentDidUpdate() {
        if (this.state.userLang !== this.props.userLang) {
            this.setState({ userLang: this.props.userLang, });
            this.load();
        }
    }

    load() {
        const {
            name, appServerPort, previewToken, previewDate,
        } = this.props;
        const { level, data, lastModified } = this.state;
        const lang = 'de';
        let path = name;
        if(level) {
            path = level;
        }
        if (!data) {
            getViewData(path, lang, this.onDataReceived,
                appServerPort, previewToken,
                previewDate).catch((error) => {
                    //console.log(error)
                    this.setState({ error: error })
                });
        } else {
            headViewData(path, lang, this.onDataReceived,
                appServerPort, previewToken,
                previewDate, lastModified).catch((error) => {
                    //console.log(error)
                    this.setState({ error: error })
                });
        }
    }

    _onDataReceived(data, lastModified) {
        const { history, location } = this.props;
        const state = location.state || {};
        state.data = data;
        state.lastModified = lastModified;
        history.replace({ state });
        this.setState({ data: data, lastModified: lastModified, error: null })
    }

    render() {
        const { userLang, } = this.props;
        if (this.state.error) {
            return (
                <Grid container direction='row' justify='center' alignItems='center' spacing={Config.gridSpacing}>
                    {this.buildHeader()}
                    <Grid item xs={12}>
                        <Box>
                            <Typography variant='subtitle1' component='span'>
                                {L.t('msgTeaserPageFailed', userLang)}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            );
        } else if (this.state.data) {
            return (
                <Grid container direction='row' justify='flex-start' alignItems='flex-start' spacing={Config.gridSpacing}>
                    {this.buildHeader()}
                    {this.renderContent()}
                </Grid>
            );
        } else {
            return (
                <Grid container direction='row' justify='center' alignItems='center' spacing={Config.gridSpacing}>
                    {this.buildHeader()}
                    <Grid item xs={12}>
                        <Box>
                            <Typography variant='subtitle1' component='span'>
                                {L.t('msgTeaserPageLoading', userLang)}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            );
        }
    }

    renderContent() {
        const { twoColumnLayout, textOnly } = this.props;
        const columns = this.buildPositions(this.state.data);
        if (twoColumnLayout && !textOnly) {
            return columns.getDouble();
        } else {
            return columns.getSingle();
        }
    }

    buildHeader() {
        const { showWifiLogin, name, previewToken, previewDate,
            userLang, updateUserLang, documentTitle, } = this.props;
        const {
            description, canonicalUrl, ogImage, ogLocale, ogType,levelTitle, searchable,
        } = this.buildMetaData();
        const wifiElement = showWifiLogin ?
            <WifiGridItem userLang={userLang} /> : null;


        return (
            <Fragment>
                <PageHelmet
                    documentTitle={documentTitle}
                    metaDescription={description}
                    canonicalUrl={canonicalUrl}
                    ogTitle={documentTitle}
                    ogDescription={description}
                    ogUrl={canonicalUrl}
                    ogImage={ogImage}
                    ogLocale={ogLocale}
                    ogType={ogType}
                    noIndex={!searchable}
                />
                {wifiElement}
                <PageTitleItem
                    name={name}
                    level ={levelTitle}
                    previewToken={previewToken}
                    previewDate={previewDate}
                    userLang={userLang}
                    updateUserLang={updateUserLang}
                />
            </Fragment>
        );
    }

    buildMetaData() {
        const { name, userLang } = this.props;
        const { level } = this.state;
        let description = L.t(name + 'TeaserPageDescription', userLang);
        const paths = {
            'start': '/',
            'line': '/linie',
            'events': '/vorschau',
            'prizes': '/aktion',
            'deals': '/deal',
        };
        let canonicalUrl;
        let levelTitle;
        let searchable = true;
        if(name === "level" && this.props.routing) {
            canonicalUrl = this.props.routing.location.pathname;
            if(level && this.props.tabsMeta && this.props.tabsMeta[level]) {
                levelTitle =  this.props.tabsMeta[level].title;
                description = this.props.tabsMeta[level].description;
                searchable  = this.props.tabsMeta[level].searchable;
            }
        } else {
            canonicalUrl = CanonicalUrl.get(paths[name]);
        }
        let ogImage = undefined;
        if (this.state.data) {
            let found = false;
            for (let position of this.state.data) {
                if (position.entries) {
                    for (let entry of position.entries) {
                        for (let kachel of entry.kacheln) {
                            if (!found && "uestra/altkachel" === kachel.type &&
                                'big' === kachel.teaser && kachel.mediaURL) {
                                ogImage = kachel.mediaURL;
                                found = true;
                            }
                        }
                    }
                }
            }
        }
        // TODO The content locale is fixed to German, therefore, the locale
        // if fixed here, too.
        const ogLocale = 'de_DE';
        const ogType = 'website';
        return { description, canonicalUrl, ogImage, ogLocale, ogType, levelTitle, searchable };
    }

    buildPositions(positions) {
        const {
            textOnly, sliderMaxElements, sectionSliderMaxElements,
        } = this.props;
        const columns = new Columns();
        let pos = 0;
        for (let position of positions) {
            if (position.type) {
                if ("slider" === position.type && position.entries) {
                    if (textOnly) {
                        columns.addFull(this.buildFull(position.entries,
                            pos, 'big', sliderMaxElements));
                    } else {
                        columns.addSlider(this.buildSlider(position.entries,
                            pos, sliderMaxElements));
                    }
                } else if ("full" === position.type && position.entries) {
                    columns.addFull(this.buildFull(position.entries,
                        pos, 'big', 0));
                } else if ("small" === position.type && position.entries) {
                    if (textOnly) {
                        columns.addFull(this.buildFull(position.entries,
                            pos, 'big', 0));
                    } else {
                        columns.addSmall(this.buildSmall(position.entries,
                            pos), position.entries.length);
                    }
                } else if ("banner" === position.type && position.entries) {
                    if (textOnly) {
                        columns.addFull(this.buildFull(position.entries,
                            pos, 'big', 0));
                    } else {
                        columns.addBanner(this.buildBanner(position.entries,
                            pos, 'banner'));
                    }
                } else if ("rubrik" === position.type && position.entries) {
                    columns.addSection(this.buildSection(position.entries,
                        pos, position.title, 0));
                } else if ("rublikslider" === position.type &&
                    position.entries) {
                    if (textOnly) {
                        columns.addSection(this.buildSection(position.entries,
                            pos, position.title, sectionSliderMaxElements));
                    } else {
                        columns.addSectionSlider(this.buildSectionSlider(
                            position.entries, pos, position.title,
                            sectionSliderMaxElements));
                    }
                } else if (position.type === "bslider" && position.entries) {
                    if (textOnly) {
                        columns.addFull(this.buildFull(position.entries,
                            pos, 'big', sliderMaxElements));
                    } else {
                        columns.addSlider(this.buildBannerSlider(
                            position.entries, pos, sliderMaxElements,
                            position.bgurl
                        ));
                    }
                } else {
                    // console.warn('Unhandled position type', position.type,
                    //     'with', (position.entries ? position.entries.length :
                    //         'no'), 'entries');
                    // console.warn('pos', pos);
                    // console.warn('position', position);
                }
            }
            pos++;
        }
        columns.finish();
        return columns;
    }

    getSurvey(entry) {
            for (let kachel of entry.kacheln) {
                if("uestra/survey" === kachel.type) {
                    return kachel
                }
        }
        return null;
    }

    buildCards(entries, pos, teaserType, levelIncrement, maxCards) {
        const { userLang, deviceSize, textOnly } = this.props;
        let cards = [];
        if(maxCards === 0) {
            for (let entry of entries) {
                let survey = this.getSurvey(entry);
                if (survey && survey.onStartpage) {
                    cards.push(<SurveyCard
                        key={'gridpos' + pos + 'card' + 0 + userLang}
                        parentEntry={entry}
                        data={survey}
                    />);
                    return cards;
                }
            }
        }




        let cardno = 0;
        for (let entry of entries) {
            const externalLink = entry.external_link;
            let found = false;
            for (let kachel of entry.kacheln) {
                if (!found && "uestra/altkachel" === kachel.type &&
                    teaserType && teaserType === kachel.teaser &&
                    kachel.mediaURL &&
                    (maxCards === 0 || cardno < maxCards)) {
                    found = true;
                    cards.push(
                        <ImageCard
                            key={'gridpos' + pos + 'card' + cardno + userLang}
                            data={kachel}
                            parentEntry={entry}
                            externalLink={externalLink}
                            imageType={teaserType}
                            deviceSize={deviceSize}
                            textOnly={textOnly}
                            textOnlyHeadLevelIncrement={1 + levelIncrement}
                        />
                    );
                    cardno++;
                }
            }
        }
        return cards;
    }

    buildSlider(entries, pos, maxElements) {
        const cardsMgr = this.buildSliderCards(entries, pos, 'big');
        return SliderBuilder.build(cardsMgr[0], pos, this.props.userLang,
            cardsMgr[1], cardsMgr[2], maxElements);
    }

    buildSectionSlider(entries, pos, title, maxElements) {
        const sectionTitle = <SectionTitle title={title} />;
        const cardsMgr = this.buildSliderCards(entries, pos, 'big');
        return SectionSliderBuilder.build(cardsMgr[0], pos, this.props.userLang,
            cardsMgr[1], sectionTitle, cardsMgr[2], maxElements);
    }

    buildBannerSlider(entries, pos, maxElements, bgUrl) {
        const { deviceSize } = this.props;
        const resizedBgUrl = bgUrl ?
            ImageResizerUrl.getResizeURL(bgUrl, 'big', deviceSize) : null;
        const cardsMgr = this.buildBannerSliderCards(entries, pos,
            resizedBgUrl);
        return BannerSliderBuilder.build(cardsMgr[0], pos, this.props.userLang,
            cardsMgr[1], cardsMgr[2], maxElements, resizedBgUrl);
    }

    buildSliderCards(entries, pos, teaserType) {
        const { userLang, deviceSize, } = this.props;
        let kacheln = [];
        for (let entry of entries) {
            const externalLink = entry.external_link;
            let found = false;
            for (let kachel of entry.kacheln) {
                if (!found && "uestra/altkachel" === kachel.type &&
                    teaserType && teaserType === kachel.teaser &&
                    kachel.mediaURL) {
                    found = true;
                    const entity = {
                        data: kachel,
                        parentEntry: entry,
                        externalLink: externalLink,
                    }
                    kacheln.push(entity);
                }
            }
        }
        const cards = [];
        const mgr = new CarouselManager();
        const duplicates = [];
        let cardno = 0;
        if (kacheln.length === 1) {
            const kachel = kacheln[0];
            cards.push(
                <ImageCardContent
                    key={'gridpos' + pos + 'card' + cardno + userLang}
                    data={kachel.data}
                    parentEntry={kachel.parentEntry}
                    externalLink={kachel.externalLink}
                    imageType={teaserType}
                    deviceSize={deviceSize}
                    manager={mgr}
                />
            );
        } else {
            for (let kachel of kacheln) {
                cards.push(
                    <CarouselImageItem
                        key={'gridpos' + pos + 'card' + cardno + userLang}
                        data={kachel.data}
                        manager={mgr}
                        sliderItem={cardno}
                        parentEntry={kachel.parentEntry}
                        externalLink={kachel.externalLink}
                        imageType={teaserType}
                        deviceSize={deviceSize}
                    />
                );
                duplicates.push(
                    <CarouselImageItem
                        key={'gridpos' + pos + 'dupe' + cardno + userLang}
                        data={kachel.data}
                        manager={mgr}
                        sliderItem={cardno}
                        parentEntry={kachel.parentEntry}
                        externalLink={kachel.externalLink}
                        imageType={teaserType}
                        deviceSize={deviceSize}
                        duplicate={true}
                    />
                );
                cardno++;
            }
        }
        return [cards, mgr, duplicates.slice(0, 2)];
    }

    buildBannerSliderCards(entries, pos, bgUrl) {
        const { userLang, deviceSize, } = this.props;
        let kacheln = [];
        for (let entry of entries) {
            const externalLink = entry.external_link;
            let found = false;
            for (let kachel of entry.kacheln) {
                if (!found && "uestra/altkachel" === kachel.type &&
                    kachel.teaser === 'banner' &&
                    kachel.mediaURL) {
                    found = true;
                    const entity = {
                        data: kachel,
                        parentEntry: entry,
                        externalLink: externalLink,
                    }
                    kacheln.push(entity);
                }
            }
        }
        const cards = [];
        const mgr = new CarouselManager();
        const duplicates = [];
        let cardno = 0;
        if (kacheln.length === 1) {
            const kachel = kacheln[0];
            cards.push(
                <BannerCardContent
                    key={'gridpos' + pos + 'card' + cardno + userLang}
                    data={kachel.data}
                    parentEntry={kachel.parentEntry}
                    externalLink={kachel.externalLink}
                    deviceSize={deviceSize}
                    manager={mgr}
                />
            );
        } else {
            for (let kachel of kacheln) {
                cards.push(
                    <CarouselBannerItem
                        key={'gridpos' + pos + 'card' + cardno + userLang}
                        data={kachel.data}
                        manager={mgr}
                        sliderItem={cardno}
                        parentEntry={kachel.parentEntry}
                        externalLink={kachel.externalLink}
                        // imageType='banner'
                        bgUrl={bgUrl}
                        deviceSize={deviceSize}
                    />
                );
                duplicates.push(
                    <CarouselBannerItem
                        key={'gridpos' + pos + 'dupe' + cardno + userLang}
                        data={kachel.data}
                        manager={mgr}
                        sliderItem={cardno}
                        parentEntry={kachel.parentEntry}
                        externalLink={kachel.externalLink}
                        // imageType='banner'
                        bgUrl={bgUrl}
                        deviceSize={deviceSize}
                        duplicate={true}
                    />
                );
                cardno++;
            }
        }
        return [cards, mgr, duplicates.slice(0, 2)];

    }

    buildFull(entries, pos, teaserType, maxCards) {
        const { userLang } = this.props;
        const cards = this.buildCards(entries, pos, teaserType, 0, maxCards);
        return FullBuilder.build(cards, pos, userLang);
    }

    buildSmall(entries, pos) {
        const { userLang } = this.props;
        const cards = this.buildCards(entries, pos, 'small', 0, 0);
        return SmallBuilder.build(cards, pos, userLang);
    }

    buildBanner(entries, pos, teaserType) {
        const { userLang } = this.props;
        const cards = this.buildCards(entries, pos, teaserType, 0, 0);
        return BannerBuilder.build(cards, pos, userLang);
    }

    buildSection(entries, pos, title, maxCards) {
        const { userLang } = this.props;
        const sectionTitle = <SectionTitle title={title} />;
        const cards = this.buildCards(entries, pos, 'big', 1, maxCards);
        return SectionBuilder.build(cards, pos, sectionTitle, userLang);
    }
}

TeaserPage.propTypes = {
    classes: PropTypes.object,
    name: PropTypes.string.isRequired,
    twoColumnLayout: PropTypes.bool,
    showWifiLogin: PropTypes.bool,
    appServerPort: PropTypes.number,
    previewToken: PropTypes.string,
    previewDate: PropTypes.string,
    userLang: PropTypes.string,
    updateUserLang: PropTypes.func,
    deviceSize: PropTypes.string,
    documentTitle: PropTypes.string,
    textOnly: PropTypes.bool,
    sliderMaxElements: PropTypes.number,
    sectionSliderMaxElements: PropTypes.number,
    routing: PropTypes.object,
    tabsMeta: PropTypes.object,
}

TeaserPage.defaultProps = {
    sliderMaxElements: 10,
    sectionSliderMaxElements: 10,
}

export default withRouter((props) => {
    const classes = useStyles();
    const theme = useTheme();
    const isLandscape = useMediaQuery('@media (orientation: landscape)');
    const isWideScreen =
        useMediaQuery(theme.breakpoints.up(Config.teaserWidescreenMinWidth));
    const twoColumnLayout = isLandscape || isWideScreen;
    let documentTitle = L.t(props.name + 'TeaserPageTitle', props.userLang) +
        ' - ' + L.t('appTitle', props.userLang);
    var level;
    if(props.routing) {
        level = props.routing.match.params.level;
    }
    if(level && props.tabsMeta && props.tabsMeta[level]  ) {
        documentTitle =  props.tabsMeta[level].title +
            ' - ' + L.t('appTitle', props.userLang)
    }
    const { trackPageView, } = useMatomo();
    React.useEffect(() => {
        trackPageView({ documentTitle, });
    }, [trackPageView, documentTitle]);
    return <TeaserPage
        {...props}
        classes={classes}
        twoColumnLayout={twoColumnLayout}
        documentTitle={documentTitle}
    />;
});
