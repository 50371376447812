import React from "react";
import ScrambledSource from "./ScrambledSource";
import ScrambledTarget from "./ScrambledTarget";
//import L from "../util/L10n";
import useResizeObserver from '@react-hook/resize-observer'

import Observable from "./Observable";

import './DragDropTouch.js';
import lottieJson from "./scrambeld_result.json";
import SolvedButton from "./SolvedButton";
import Typography from "@material-ui/core/Typography";

function useSize(target) {
    const [size, setSize] = React.useState();

    React.useLayoutEffect(() => {
        target && setSize(target.getBoundingClientRect());
    }, [target]);

    // Where the magic happens
    useResizeObserver(target, (entry) => setSize(entry.contentRect));
    return size;
}


export default function ScrambledWord({
                                          classes,
                                          userLang,
                                          scrambledWord,
                                          word,
                                          info,
                                          messageCorrect,
                                          messageWrong,
                                          buttonColor,
                                          buttonStyle,
                                          buttonText,
                                          showAnimation,
                                      }) {

    const [target, setTarget] = React.useState();
    const [observable, setObservable] = React.useState(new Observable());
    const [complete, setComplete] = React.useState(false);
    const size = useSize(target);
    const [message, setMessage] = React.useState(null);



    React.useEffect(() => {
        function onCompleteChanged(isComplete) {
                setComplete(isComplete);
                if(!isComplete) {
                    setMessage(null);
                }
        }

        observable.setCompleteCallback(onCompleteChanged)
        return () => {
            observable.setCompleteCallback(null);
        }
    }, [observable]);

    function onButtonClick() {
        if (observable.getCorrectSolution()) {
            showAnimation(lottieJson, false);
            setMessage(messageCorrect);
            observable.setIsSolved(true);
        } else {
            setMessage(messageWrong);
        }
    }


    let width = 0;
    if (size) {
        width = size.width;
    }

    let msg = null;
    let button = <div style={{marginTop: '48px', marginBottom: '16px'}}><SolvedButton content={buttonText}
                                                                                      backgroundColor={buttonColor}
                                                                                      inverted={'inverse' === buttonStyle}
                                                                                      disabled={!complete}
                                                                                      onClick={onButtonClick}></SolvedButton>
    </div>;
    let source = <ScrambledSource cWidth={width} text={scrambledWord} observable={observable}></ScrambledSource>;
    let infoText = <div style={{marginTop: '32px', marginBottom: '8px'}}><Typography align='center' variant='h6'
                                                                                     component='p'>{info}</Typography>
    </div>;
    let space = null;

    if (message !== null) {
        msg = <div style={{marginBottom: '16px'}}>
            <Typography
                align='center'
                variant='h6'
                component='p'
            >
                {message}
            </Typography>
        </div>;
        if (observable.getCorrectSolution()) {
            button = null;
            source = null;
            infoText = null;
            space = <div style={{marginTop: '54px'}}></div>

        }
    }

    return (
        <div ref={setTarget}>
            {infoText}
            {source}
            {space}
            <ScrambledTarget cWidth={width} text={word} observable={observable}></ScrambledTarget>
            {msg}
            {button}
        </div>
    );
}
