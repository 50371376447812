import React, {PureComponent} from 'react';
import {useParams, withRouter} from 'react-router-dom';
import PropTypes from "prop-types";

import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from "@material-ui/core/Typography";

import PageHelmet from '../util/PageHelmet';
import getViewData from "../data/ViewReader";
import html2canvas from "html2canvas";
import Survey from "../cards/survey/Survey";

const useStyles = makeStyles({
    h2: {
        marginBlockStart: '0.83em',
        marginBlockEnd: '0.83em',
    },
    p: {
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        textAlign: 'justify',
        hyphens: 'auto',
        maxWidth: 500,
    },
    pok: {
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        textAlign: 'justify',
        hyphens: 'auto',
        maxWidth: 500,
        backgroundColor: '#338C15',
        color: '#ffffff',
        fontWeight: 500,
        padding: 10,
    },
    button: {
        margin: '20px 0px',
        maxWidth: 500,
    },
});


class SurveyResultPage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            lastModified: null,
            error: null,
            twoColumnLayout: props.twoColumnLayout,
            layoutLocked: false,
        }
        this.onDataReceived = this._onDataReceived.bind(this);
        this.onSaveTile = this._onSaveTile.bind(this);

    }

    componentDidMount() {
        try {
            window.scrollTo(0, 0);
        } catch (e) {
            // Ignore.
        }
        this.load();
    }

    _onDataReceived(data, lastModified) {
        const state = {};
        state.data = data;
        state.lastModified = lastModified;
        let kachel;
        if (data) {
            for (let k of data.kacheln) {
                if ('uestra/survey' === k.type) {
                    kachel = k;
                }
            }
        }
        this.setState({data: data, lastModified: lastModified, kachel: kachel, error: null})
    }

    load() {
        const {appServerPort} = this.props;
        // Immer laden
        getViewData(this.props.entryId, null, this.onDataReceived,
            appServerPort, null).catch((error) => {
            console.warn('SurveyResultPage.load', error);
            this.setState({error: error,});
        })

    }

    async _onSaveTile() {
        const {surveyId, entryId} = this.props;
        let canvas = await html2canvas(document.getElementById("tile"));
        let image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
        var a = document.createElement('a');
        a.href = image;
        a.download = surveyId + "_" + entryId + ".png";
        document.body.appendChild(a);
        a.click();
    }


    render() {
        const {surveyId, entryId, classes, location} = this.props;
        const {data, kachel} = this.state;
        if (data && kachel) {
            return (
                <Box>
                    <PageHelmet documentTitle={"Umfrage Ergebnis"}/>
                    <div id='tile' style={{width: 1024}}>
                        <Survey
                            location={location}
                            parentEntry={data}
                            surveyId={kachel.id}
                            question={kachel.question}
                            answer_1={kachel.answer1}
                            answer_2={kachel.answer2}
                            answer_3={kachel.answer3}
                            answer_4={kachel.answer4}
                            color={data.backgroundColor ? data.backgroundColor : "#338C15"}
                            isResult={true}
                        >
                        </Survey>
                    </div>
                    <a onClick={this.onSaveTile} href="#" style={{color: "black"}}>Kachel speichern</a>

                </Box>
            );
        }
        return (
            <Box>
                <PageHelmet documentTitle={"Umfrage Ergebnis"}/>
                <Typography variant='h4' component='h2' className={classes.h2}>
                    {entryId} {surveyId}
                </Typography>

            </Box>

        )

    }
}

SurveyResultPage.propTypes = {
    userLang: PropTypes.string,
    appServerPort: PropTypes.number,
    entryId: PropTypes.string,
    surveyId: PropTypes.string,
};


export default withRouter((props) => {
    const classes = useStyles();
    const {entryId, surveyId} = useParams();

    return <SurveyResultPage {...props}
                             classes={classes}
                             entryId={entryId}
                             surveyId={surveyId}
    />;
});


