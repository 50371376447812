import React from "react";
//import L from "../util/L10n";
import useResizeObserver from '@react-hook/resize-observer'
import DangerouslySetHtmlContent from '../../util/DangerouslySetHtmlContent'

function useSize(target) {
    const [size, setSize] = React.useState();

    React.useLayoutEffect(() => {
        target && setSize(target.getBoundingClientRect());
    }, [target]);

    // Where the magic happens
    useResizeObserver(target, (entry) => setSize(entry.contentRect));
    return size;
}


export default function ExternalContent({
                                            extType, display
                                            /*classes,
                                            userLang,*/

                                        }) {

    const [target, setTarget] = React.useState();


    let myStyle = {

        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column'
    };

    let html = "<div>Hallo " + extType + "</div>";

    let dpaEM24LiveCenter = `
        <div id="dpaEM24LiveCenter_2" style="overflow: hidden">
        <div id="dpaEM24LiveCenter">       
        <div 
           data-app='dpa-sportslive' 
            data-embed-type='iframe-dyn' 
            data-competition-id='fb_mem'
            data-language='de' 
            data-standalone='livecenter' 
            data-customer='nicht-zugeordnet' 
            data-mobile='false'
            data-src="https://embed.dpa-sportslive.com/customers/european-championchip/aws/men/2024/dpa-shop/html/index.html" 
            data-path='https://embed.dpa-sportslive.com/fb_mem/'>
        </div>
        </div>
        </div>
        <script type='text/javascript' src='https://core.dpa-infocom.net/js/dpa.js'></script>
    `;
    if (display) {
        dpaEM24LiveCenter = dpaEM24LiveCenter + `
        <script>
        function _sizeChanged() {
            let h = document.getElementById('dpaEM24LiveCenter').offsetHeight;
            document.getElementById('dpaEM24LiveCenter_2').style.height = "" + h-6 + "px";
            
        }
        new ResizeObserver(_sizeChanged).observe(document.getElementById('dpaEM24LiveCenter'))
        </script>  
        `;
    }

    let dpaEM24Ergebnisse = `
        <div id="dpaEM24Ergebnisse_2" style="overflow: hidden">
        <div id="dpaEM24Ergebnisse">
        <div 
            data-app='dpa-sportslive' 
            data-embed-type='iframe-dyn' 
            data-competition-id='fb_mem' 
            data-language='de' 
            data-standalone='conference' 
            data-alignment='horizontal' 
            data-widget-header='false' 
            data-customer='nicht-zugeordnet' 
            data-mobile='false'
            data-src=https://embed.dpa-sportslive.com/customers/european-championchip/aws/men/2024/dpa-shop/html/index.html 
            data-path='https://embed.dpa-sportslive.com/fb_mem/'>
        </div>
        </div>
        </div>
        <script type='text/javascript' src='https://core.dpa-infocom.net/js/dpa.js'></script>
    `;
    if (display) {
        dpaEM24Ergebnisse = dpaEM24Ergebnisse + `
        <script>
        function _sizeChanged() {
            let h = document.getElementById('dpaEM24Ergebnisse').offsetHeight;
            document.getElementById('dpaEM24Ergebnisse_2').style.height = "" + h-6 + "px";
            
        }
        new ResizeObserver(_sizeChanged).observe(document.getElementById('dpaEM24Ergebnisse'))
        </script>  
        `;
    }

    let dpaEM24Spielplan = `
        <div id="dpaEM24Spielplan_2" style="overflow: hidden">
        <div id="dpaEM24Spielplan">
        <div 
        data-app='dpa-sportslive' 
        data-embed-type='iframe-dyn' 
        data-competition-id='fb_mem'
        data-language='de' 
        data-standalone='schedule' 
        data-widget-header='false' 
        data-mobile='false'
        data-customer='nicht-zugeordnet' 
        data-src="https://embed.dpa-sportslive.com/customers/european-championchip/aws/men/2024/dpa-shop/html/index.html"
        data-path='https://embed.dpa-sportslive.com/fb_mem/'>
    </div>
    </div>
    </div>
    <script type='text/javascript' src='https://core.dpa-infocom.net/js/dpa.js'></script>  
    
    `;


    if (display) {
        dpaEM24Spielplan = dpaEM24Spielplan + `
        <script>
        function _sizeChanged() {
            let h = document.getElementById('dpaEM24Spielplan').offsetHeight;
            document.getElementById('dpaEM24Spielplan_2').style.height = "" + h-6 + "px";
            
        }
        new ResizeObserver(_sizeChanged).observe(document.getElementById('dpaEM24Spielplan'))
        </script>  
        `;
    }


    // Start Olympia24
    let dpaOlympia24LiveCenter = `
    <div id="dpaOlympia24LiveCenter_2" style="overflow: hidden">
    <div id="dpaOlympia24LiveCenter">
    <div 
    data-app='dpa-sportslive' 
    data-embed-type='iframe-dyn' 
    data-competition-id='oly_so'
    data-language='de' 
    data-standalone='livecenter' 
    data-country='GER' 
    data-mobile='false'
    data-customer='nicht-zugeordnet' 
    data-src="https://embed.dpa-sportslive.com/customers/olympics/aws/2024/dpa-shop/html/index.html" 
    data-path='https://embed.dpa-sportslive.com/oly_so/'>
    </div>   
    </div>
    </div>
    <script type='text/javascript' src='https://core.dpa-infocom.net/js/dpa.js'></script>
    `;
    if (display) {
        dpaOlympia24LiveCenter = dpaOlympia24LiveCenter + `
        <script>
        function _sizeChanged() {
            let h = document.getElementById('dpaOlympia24LiveCenter').offsetHeight;
            document.getElementById('dpaOlympia24LiveCenter_2').style.height = "" + h-6 + "px";
            
        }
        new ResizeObserver(_sizeChanged).observe(document.getElementById('dpaOlympia24LiveCenter'))
        </script>  
        `;
    }
    //--
    let dpaOlympia24Medaillenspiegel = `
    <div id="dpaOlympia24Medaillenspiegel_2" style="overflow: hidden">
    <div id="dpaOlympia24Medaillenspiegel">
    <div 
    data-app='dpa-sportslive' 
    data-embed-type='iframe-dyn' 
    data-competition-id='oly_so'
    data-language='de' 
    data-standalone='medalsTable' 
    data-country='GER' 
    data-mobile='false'
    data-customer='nicht-zugeordnet' 
    data-src="https://embed.dpa-sportslive.com/customers/olympics/aws/2024/dpa-shop/html/index.html" 
    data-path='https://embed.dpa-sportslive.com/oly_so/'>
    </div>
    </div>
    </div>
    <script type='text/javascript' src='https://core.dpa-infocom.net/js/dpa.js'></script>
    `;
    if (display) {
        dpaOlympia24Medaillenspiegel = dpaOlympia24Medaillenspiegel + `
        <script>
        function _sizeChanged() {
            let h = document.getElementById('dpaOlympia24Medaillenspiegel').offsetHeight;
            document.getElementById('dpaOlympia24Medaillenspiegel_2').style.height = "" + h-6 + "px";
            
        }
        new ResizeObserver(_sizeChanged).observe(document.getElementById('dpaOlympia24Medaillenspiegel'))
        </script>  
        `;
    }
    //--
    let dpaOlympia24Bildergalerie = `
    <div id="dpaOlympia24Bildergalerie_2" style="overflow: hidden">
    <div id="dpaOlympia24Bildergalerie">
    <div 
    data-app='dpa-sportslive' 
    data-embed-type='iframe-dyn' 
    data-competition-id='oly_so'
    data-language='de' 
    data-standalone='gallery' 
    data-country='GER' 
    data-mobile='false'
    data-customer='nicht-zugeordnet' 
    data-src="https://embed.dpa-sportslive.com/customers/olympics/aws/2024/dpa-shop/html/index.html" 
    data-path='https://embed.dpa-sportslive.com/oly_so/'>
    </div>
    </div>
    </div>
    <script type='text/javascript' src='https://core.dpa-infocom.net/js/dpa.js'></script>
    `;
    if (display) {
        dpaOlympia24Bildergalerie = dpaOlympia24Bildergalerie + `
        <script>
        function _sizeChanged() {
            let h = document.getElementById('dpaOlympia24Bildergalerie').offsetHeight;
            document.getElementById('dpaOlympia24Bildergalerie_2').style.height = "" + h-6 + "px";
            
        }
        new ResizeObserver(_sizeChanged).observe(document.getElementById('dpaOlympia24Bildergalerie'))
        </script>  
        `;
    }

    // End Olympia24


    if (extType === 1) {
        html = dpaEM24LiveCenter;
    } else if (extType === 2) {
        html = dpaEM24Ergebnisse;
    } else if (extType === 3) {
        html = dpaEM24Spielplan;
    } else if (extType === 4) {
        html = dpaOlympia24LiveCenter;
    } else if (extType === 5) {
        html = dpaOlympia24Medaillenspiegel;
    } else if (extType === 6) {
        html = dpaOlympia24Bildergalerie;
    }
    return (
        <div key={"externalContent_"} ref={setTarget} style={myStyle}>
            <DangerouslySetHtmlContent html={html}/>
        </div>
    );
}
