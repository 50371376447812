import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import {
    makeStyles, createMuiTheme, ThemeProvider,
} from "@material-ui/core/styles";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import DateRangeRoundedIcon from '@material-ui/icons/DateRangeRounded';

import DateUtil from '../util/DateUtil';
import L from '../util/L10n';

const useStyles = makeStyles((theme) => ({
    select: {
        fontFamily: 'DinforPBR',
        borderRadius: 6,
    },
    formControl: {
        width: '100%',
    },
    box: {
        borderRadius: 6,
        backgroundColor: '#ffffff',
    },
    menuItem: {
        color: '#9d9d9d',
        backgroundColor: '#ffffff',
        borderBottom: '1px solid #d8d8d8',
        '&:last-child': {
            borderBottom: 'none',
        },
        height: 48,
    },
    menuItemSelected: {
        color: '#000000',
    },
    itemContent: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        fontSize: 14,
        fontWeight: 500,
    },
    icon: {
        marginLeft: 4,
        marginRight: 16,
    },
    iconSpacer: {
        width: 44,
    },
}));

class DateSelect extends PureComponent {
    render() {
        const { classes, onChange, date, userLang } = this.props;
        const dates = [];
        const today = DateUtil.daysAgo(0, userLang);
        dates.push({
            value: today.value,
            label: L.t('archiveDateTodayText', userLang),
        });
        for (let i = 1; i < 30; i++) {
            dates.push(DateUtil.daysAgo(i, userLang))
        }
        const options = dates.map((x, index) => {
            let itemContent =
                <div className={classes.itemContent}>
                    <div className={classes.iconSpacer} />
                    <time dateTime={x.value}>
                        {x.label}
                    </time>
                </div>;
            //if (x.value === date) {
            if (index === 0) {
                itemContent =
                    <div className={classes.itemContent}>
                        <DateRangeRoundedIcon
                            className={classes.icon}
                        />
                        <time dateTime={x.value}>
                            {x.label}
                        </time>
                    </div>;
            }
            return (
                <MenuItem
                    classes={{
                        root: classes.menuItem,
                        selected: classes.menuItemSelected,
                    }}
                    value={x.value}
                    key={x.value}
                >
                    {itemContent}
                </MenuItem>
            );
        });
        return (
            <ThemeProvider
            theme={(theme) => createMuiTheme({
                ...theme,
                overrides: {
                    MuiListItem: {
                        root: {
                            '&$selected': {
                                backgroundColor: '#ffffff',
                            },
                        },
                    },
                    MuiOutlinedInput: {
                        notchedOutline: {
                            border: 'none',
                        },
                        input: {
                            padding: '10px 14px',
                        },
                    },
                },
                //.MuiOutlinedInput-input-649
            })}
            >
                <Box
                    className={classes.box}
                    boxShadow={6}
                >
                    <FormControl className={classes.formControl}>
                        <Select
                            className={classes.select}
                            value={date}
                            onChange={onChange}
                            variant='outlined'
                        >
                            {options}
                        </Select>
                    </FormControl>
                </Box>
            </ThemeProvider>
        );
    }
}

DateSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    userLang: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    date: PropTypes.string.isRequired,
}

export default function _DateSelect(props) {
    const classes = useStyles();

    return <DateSelect {...props} classes={classes} />;
}
