import React, { PureComponent, Fragment } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import { useMatomo } from '@datapunt/matomo-tracker-react'

import L from "../util/L10n";
import EntryUrl from '../util/EntryUrl';
import InvisibleImg from '../a11y/InvisibleImg';

const useStyles = makeStyles((theme) => ({
    iconButton: {
        padding: 0,
        marginLeft: -10,
    },
    linkIcon: {
        fontSize: 48,
        transform: 'scale(0.643)',
        color: '#ffffff',
        backgroundColor: '#9d9d9d',
        borderRadius: 8,
        padding: 4,
    },
}));

class ShareClipboardButton extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            copied: false,
        };
        this.closeSnackbar = this.closeSnackbar.bind(this);
    }

    closeSnackbar(evt, reason) {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ copied: false });
    }

    render() {
        const { classes, userLang, entry, trackShare } = this.props;
        const { copied } = this.state;
        const url = EntryUrl.getUrl(entry);
        const altText = L.t('shareClipboardAlt', userLang);
        const onClick = () => {
            if (navigator.clipboard) {
                navigator.clipboard.writeText(url).then(() => {
                    this.setState({ copied: true });
                    trackShare(url);
                })
            } else {
                const textArea = document.createElement('textarea');
                textArea.value = url;
                textArea.style.top = 0;
                textArea.style.left = 0;
                textArea.style.position = 'fixed';
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                try {
                    if (document.execCommand('copy')) {
                        this.setState({ copied: true });
                        trackShare(url);
                    }
                } catch (e) {
                    // uable to copy, ignore.
                }
                document.body.removeChild(textArea);
            }
        };
        return (
            <Fragment>
                <IconButton
                    className={classes.iconButton}
                    onClick={onClick}
                >
                    <LinkIcon
                        className={classes.linkIcon}
                    />
                    <InvisibleImg
                        alt={altText}
                    />
                </IconButton>
                <Snackbar
                    open={copied}
                    autoHideDuration={4000}
                    onClose={this.closeSnackbar}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert
                        onClose={this.closeSnackbar}
                        severity='success'
                        elevation={6}
                        variant='filled'
                    >
                        {L.t('shareClipboardSuccess', userLang)}
                    </Alert>
                </Snackbar>
            </Fragment>
        );
    }
}

ShareClipboardButton.propTypes = {
    classes: PropTypes.object,
    entry: PropTypes.object,
    trackShare: PropTypes.func,
    userLang: PropTypes.string,
};

export default function _ShareClipboardButton(props) {
    const classes = useStyles();
    const { trackEvent } = useMatomo();
    const onShare = (url) => {
        trackEvent({
            category: 'share',
            action: 'clipboard',
            name: url,
        });
        return true;
    };
    return (
        <ShareClipboardButton
            {...props}
            classes={classes}
            trackShare={onShare}
        />
    );
}
