import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    smallSmall1: {
        [theme.breakpoints.up('sm')]: {
            marginTop: -4,
        },
    },
    smallBanner1: {
        [theme.breakpoints.up('sm')]: {
            marginTop: -5.88,
        },
        [theme.breakpoints.up('sd')]: {
            marginTop: -4.47,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: -1.33,
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 2.87,
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: 7.31,
        },
    },
    smallBanner2: {
        [theme.breakpoints.up('sm')]: {
            marginTop: -5.88,
        },
        [theme.breakpoints.up('sd')]: {
            marginTop: -4.47,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: -1.33,
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 2.87,
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: 7.31,
        },
    },
    smallFiller1: {
        [theme.breakpoints.up('sm')]: {
            marginTop: 42.87,
        },
        [theme.breakpoints.up('sd')]: {
            marginTop: 53.28,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 76.67,
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 107.87,
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: 140.81,
        },
    },
    bannerSmall0: {
        [theme.breakpoints.up('sm')]: {
            marginBottom: -5.88,
        },
        [theme.breakpoints.up('sd')]: {
            marginBottom: -4.47,
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: -1.33,
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: 2.87,
        },
        [theme.breakpoints.up('xl')]: {
            marginBottom: 7.31,
        },
    },
    bannerSmall1: {
        [theme.breakpoints.up('sm')]: {
            marginBottom: -5.88,
        },
        [theme.breakpoints.up('sd')]: {
            marginBottom: -4.47,
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: -1.33,
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: 2.87,
        },
        [theme.breakpoints.up('xl')]: {
            marginBottom: 7.31,
        },
    },
    fourBanner1: {
        [theme.breakpoints.up('sm')]: {
            marginTop: -6.51,
        },
        [theme.breakpoints.up('sd')]: {
            marginTop: -4.63,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: -0.44,
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 5.16,
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: 11.08,
        },
    },
    fourBanner2: {
        [theme.breakpoints.up('sm')]: {
            marginTop: -6.51,
        },
        [theme.breakpoints.up('sd')]: {
            marginTop: -4.63,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: -0.44,
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 5.16,
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: 11.08,
        },
    },
    fourBanner3: {
        [theme.breakpoints.up('sm')]: {
            marginTop: -6.51,
        },
        [theme.breakpoints.up('sd')]: {
            marginTop: -4.63,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: -0.44,
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 5.16,
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: 11.08,
        },
    },
    threeBanner1: {
        [theme.breakpoints.up('sm')]: {
            marginTop: -5.88,
        },
        [theme.breakpoints.up('sd')]: {
            marginTop: -4.47,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: -1.33,
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 2.87,
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: 7.31,
        },
    },
    threeBanner2: {
        [theme.breakpoints.up('sm')]: {
            marginTop: -5.88,
        },
        [theme.breakpoints.up('sd')]: {
            marginTop: -4.47,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: -1.33,
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 2.87,
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: 7.31,
        },
    },
    twoBanner1: {
        [theme.breakpoints.up('sm')]: {
            marginTop: -5.88,
        },
        [theme.breakpoints.up('sd')]: {
            marginTop: -4.47,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: -1.33,
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 2.87,
        },
        [theme.breakpoints.up('xl')]: {
            marginTop: 7.31,
        },
    },
}));

class AdaptiveGridItem extends PureComponent {

    render() {
        const { children, classes, variant, itemNo, keyValue, } = this.props;
        return (
            <Grid item xs={12}
                key={keyValue}
                className={classes[variant + itemNo]}
            >
                {children}
            </Grid>
        );
    }
}

AdaptiveGridItem.prototypes = {
    variant: PropTypes.string.isRequired,
    itemNo: PropTypes.number.isRequired,
    keyValue: PropTypes.string,
};

export default function _AdaptiveGridItem(props) {
    const classes = useStyles();
    return <AdaptiveGridItem {...props} classes={classes} />;
}
