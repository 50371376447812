
export function addListener(listener) {
    if(!window._fgf_channel) {
        window._fgf_channel = {};
    }
    if(!window._fgf_channel._showWifiBox) {
        window._fgf_channel._showWifiBox = __showWifiBox;
    }
    if(!window._fgf_channel._hideWifiBox) {
        window._fgf_channel._hideWifiBox = __hideWifiBox;
    }
    if(!window._fgf_channel._callbacks) {
        window._fgf_channel._callbacks = [];
    }
    window._fgf_channel._callbacks.push(listener);
}

export function removeListener(listener) {
    if (window._fgf_channel && window._fgf_channel._callbacks) {
        let pos = window._fgf_channel._callbacks.indexOf(listener);
        if (pos >= 0) {
            window._fgf_channel._callbacks.splice(pos, 1);
        }
    }
}

export function isShowWifiBox() {
    return window._fgf_channel && window._fgf_channel._showBox;
}

function __showWifiBox() {
    window._fgf_channel._showBox = true;
    //console.log("WifiBoxAppChannel: showWifiBox")
    window._fgf_channel._callbacks.forEach((callback) => {
        //console.log("WifiBoxAppChannel, callback: showWifiBox")
        callback.showWifiBox();
    })
}

function __hideWifiBox() {
    window._fgf_channel._showBox = false;
    window._fgf_channel._callbacks.forEach((callback) => {
        callback.hideWifiBox();
    })
}
