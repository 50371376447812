import React, { PureComponent } from 'react';

import Grid from "@material-ui/core/Grid";

class FillBox extends PureComponent {
    render() {
        return (
            <Grid item xs={6} />
        );
    }
}

export default function _FillBox(props) {
    return <FillBox {...props} />
}
