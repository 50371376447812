import React, { PureComponent } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from "prop-types";

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from "@material-ui/core/Typography";

import { useMatomo } from '@datapunt/matomo-tracker-react';

import { getVoucherInfo, setVoucherUsed } from '../data/ActionReader';
import L from '../util/L10n';
import CookieAccess from '../util/CookieAccess';
import PageHelmet from '../util/PageHelmet';
import ColorButton from '../forms/ColorButton';
import VoucherInfo from '../components/VoucherInfo';
import VoucherPasswordDialog from '../forms/VoucherPasswordDialog';

const useStyles = makeStyles({
    h2: {
        marginBlockStart: '0.83em',
        marginBlockEnd: '0.83em',
    },
    p: {
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        textAlign: 'justify',
        hyphens: 'auto',
        maxWidth: 500,
    },
    pok: {
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        textAlign: 'justify',
        hyphens: 'auto',
        maxWidth: 500,
        backgroundColor: '#338C15',
        color: '#ffffff',
        fontWeight: 500,
        padding: 10,
    },
    button: {
        margin: '20px 0px',
        maxWidth: 500,
    },
});

const statsButtonColor = '#66991A';
const voucherButtonColor = '#e66000';

class VoucherResultPage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            submitDisabled: false,
            passwordDialogOpen: false,
            passwordValue: '',
            infoData: null,
            usedData: null,
        };
        this.submitInfo = this._submitInfo.bind(this);
        this.handleInfo = this._handleInfo.bind(this);
        this.submitUsed = this._submitUsed.bind(this);
        this.handleUsed = this._handleUsed.bind(this);
        this.cancelPasswordDialog = this._cancelPasswordDialog.bind(this);
        this.confirmInfoPasswordDialog =
            this._confirmInfoPasswordDialog.bind(this);
        this.confirmUsedPasswordDialog =
            this._confirmUsedPasswordDialog.bind(this);
        this.updatePasswordValue = this._updatePasswordValue.bind(this);
    }

    _submitInfo() {
        this._disableSumbit(true);
        const { id, action } = this.props;
        const key = id + '_' + action;
        const password = CookieAccess.getPasswordCookie(key);
        if (password) {
            const sith = this;
            this.setState({ passwordValue: '' }, () => {
                sith._obtainInfo(password);
            });
        } else {
            this.setState({ passwordDialogOpen: true, passwordValue: '' });
        }
    }

    _obtainInfo(password) {
        const { id, action } = this.props;
        getVoucherInfo(
            id, action, password, this.handleInfo
        ).catch((error) => {
            this.handleInfo({ state: 'failed', error });
        });
    }

    _handleInfo(infoData) {
        const { id, action } = this.props;
        const key = id + '_' + action;
        if (infoData && infoData.state === 'success' &&
            infoData.info && infoData.info.voucherEndDate) {
            const { passwordValue } = this.state;
            if (passwordValue) {
                CookieAccess.setPasswordCookie(key, passwordValue);
            }
        }
        this.setState({ infoData }, () => {
            this._disableSumbit(false);
        });
    }

    _submitUsed() {
        this._disableSumbit(true);
        const { id, action } = this.props;
        const key = id + '_' + action;
        const password = CookieAccess.getPasswordCookie(key);
        if (password) {
            const sith = this;
            this.setState({ passwordValue: '' }, () => {
                sith._obtainUsed(password);
            });
        } else {
            this.setState({ passwordDialogOpen: true, passwordValue: '' });
        }
    }

    _obtainUsed(password) {
        const { id, action, voucher } = this.props;
        setVoucherUsed(
            id, action, voucher, password, this.handleUsed
        ).catch((error) => {
            this.handleUsed({ state: 'failed', error });
        });
    }

    _handleUsed(usedData) {
        const { id, action } = this.props;
        const key = id + '_' + action;
        if (usedData && usedData.state === 'success') {
            const { passwordValue } = this.state;
            if (passwordValue) {
                CookieAccess.setPasswordCookie(key, passwordValue);
            }
        } else if (usedData && usedData.state === 'error' &&
            usedData.errormessage === 'WRONG PASSWORD') {
            CookieAccess.deletePasswordCookie(key);
        }
        this.setState({ usedData }, () => {
            this._disableSumbit(false);
        })
    }

    _disableSumbit(disabled) {
        this.setState({ submitDisabled: disabled });
    }

    _cancelPasswordDialog() {
        this.setState({ passwordDialogOpen: false, submitDisabled: false });
    }

    _confirmInfoPasswordDialog() {
        this.setState({ passwordDialogOpen: false });
        this._obtainInfo(this.state.passwordValue || '');
    }

    _confirmUsedPasswordDialog() {
        this.setState({ passwordDialogOpen: false });
        this._obtainUsed(this.state.passwordValue || '');
    }

    _updatePasswordValue(value) {
        this.setState({ passwordValue: value });
    }

    render() {
        const { classes, userLang, id, action, voucher } = this.props;
        const { infoData, usedData, passwordDialogOpen } = this.state;
        let passwordDialog = null;
        let onConfirm = null;
        let title = L.t('qrDefaultTitle', userLang);
        let message = null;
        let info = null;
        let button = null;
        if (infoData) {
            onConfirm = this.confirmInfoPasswordDialog;
            title = L.t('qrStatsTitle', userLang);
            info =
                <VoucherInfo
                    action={action}
                    info={infoData}
                    userLang={userLang}
                />;
            button =
                <div className={classes.button}>
                    <ColorButton
                        content={L.t('qrStatsButton', userLang)}
                        backgroundColor={statsButtonColor}
                        onClick={this.submitInfo}
                        disabled={this.state.submitDisabled}
                    />
                </div>;
        } else if (usedData) {
            onConfirm = this.confirmUsedPasswordDialog;
            title = L.t('qrVoucherTitle', userLang);
            if (usedData.state === 'success') {
                message =
                    <Typography
                        variant='body1'
                        component='p'
                        className={classes.pok}>
                        {L.t('qrVoucherSuccessText', userLang)}
                    </Typography>;
            }
            info =
                <VoucherInfo
                    action={action}
                    info={usedData}
                    userLang={userLang}
                />;
            if (usedData.state !== 'success') {
                button =
                    <div className={classes.button}>
                        <ColorButton
                            content={L.t('qrVoucherButtonRetry', userLang)}
                            backgroundColor={voucherButtonColor}
                            onClick={this.submitUsed}
                            disabled={this.state.submitDisabled}
                        />
                    </div>;
            }
        } else if (id && action) {
            onConfirm = voucher ?
                this.confirmUsedPasswordDialog : this.confirmInfoPasswordDialog;
            title = L.t(voucher ? 'qrVoucherTitle' : 'qrStatsTitle', userLang);
            message =
                <Typography variant='body1' component='p' className={classes.p}>
                    {L.t(voucher ? 'qrVoucherText' : 'qrStatsText', userLang)}
                </Typography>;
            button =
                <div className={classes.button}>
                    <ColorButton
                        content={L.t(voucher ?
                            'qrVoucherButton' : 'qrStatsButton', userLang)}
                        backgroundColor={voucher ?
                            voucherButtonColor : statsButtonColor}
                        onClick={voucher ? this.submitUsed : this.submitInfo}
                        disabled={this.state.submitDisabled}
                    />
                </div>;
        } else {
            message =
                <Typography variant='body1' component='p' className={classes.p}>
                    {L.t('qrDefaultText', userLang)}
                </Typography>;
        }
        if (onConfirm) {
            passwordDialog =
                <VoucherPasswordDialog
                    label='voucher-password'
                    title={L.t('qrPasswordTtitle', userLang)}
                    text={L.t('qrPasswordText', userLang)}
                    inputLabel={L.t('qrPasswordInputLabel', userLang)}
                    confirm={L.t('qrPasswordOkLabel', userLang)}
                    cancel={L.t('qrPasswordCancelLabel', userLang)}
                    open={passwordDialogOpen}
                    onConfirm={onConfirm}
                    onCancel={this.cancelPasswordDialog}
                    onChange={this.updatePasswordValue}
                />;
        }
        return (
            <Box>
                <PageHelmet documentTitle={title} />
                {passwordDialog}
                <Typography variant='h4' component='h2' className={classes.h2}>
                    {title}
                </Typography>
                {message}
                {info}
                {button}
            </Box>
        );
    }
}

VoucherResultPage.propTypes = {
    userLang: PropTypes.string,
    id: PropTypes.string,
    action: PropTypes.string,
    voucher: PropTypes.string,
};

export default function _VoucherResultPage(props) {
    const classes = useStyles();
    const { id, action, voucher } = useParams();
    let documentTitleLabel = 'qrDefaultTitle';
    if (id && action) {
        documentTitleLabel = voucher ? 'qrVoucherTitle' : 'qrStatsTitle';
    }
    let documentTitle = L.t(documentTitleLabel, props.userLang);
    const { trackPageView } = useMatomo();
    React.useEffect(() => {
        trackPageView({ documentTitle });
    }, [trackPageView, documentTitle]);
    return (
        <VoucherResultPage
            {...props}
            classes={classes}
            id={id}
            action={action}
            voucher={voucher}
        />
    );
}
