import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import InvisibleImg from '../a11y/InvisibleImg';

const useStyles = makeStyles((theme) => ({
    paper: {
        borderRadius: 6,
        display: 'flex',
        alignItems: 'center',
        width: '100 %',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },

}));

class SearchInput extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || '',
        };
        this.onInputChange = this._onInputChange.bind(this);
    }

    _onInputChange(event) {
        const { value } = this.state;
        const newValue = event.target.value;
        if (value !== newValue) {
            this.setState({ value: newValue });
        }
    }

    render() {
        const {
            classes, label, ariaLabel, submitLabel, onSubmit, lockLayout,
        } = this.props;
        const { value } = this.state;
        const onFocus = () => {
            lockLayout(true);
        };
        return (
            <Paper
                component='form'
                className={classes.paper}
                elevation={11}
                onSubmit={(event) => {
                    event.preventDefault();
                    onSubmit(value);
                }}
            >
                <InputBase
                    className={classes.input}
                    placeholder={label}
                    inputProps={{ 'aria-label': ariaLabel }}
                    onChange={this.onInputChange}
                    onFocus={onFocus}
                    value={value}
                />
                <IconButton
                    // type="submit"
                    className={classes.iconButton}
                    onClick={() => {
                        onSubmit(value);
                    }}
                >
                    <SearchIcon />
                    <InvisibleImg alt={submitLabel} />
                </IconButton>

            </Paper>
        );
    }
}

SearchInput.propTypes = {
    classes: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    ariaLabel: PropTypes.string.isRequired,
    submitLabel: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    value: PropTypes.string,
    lockLayout: PropTypes.func,
};

export default function _SearchInput(props) {
    const classes = useStyles();
    return <SearchInput {...props} classes={classes} />;
}
