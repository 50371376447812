import React, { PureComponent, Fragment } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import InvisibleImg from '../a11y/InvisibleImg';

const useStyles = makeStyles((theme) => ({
    imageGrid: {
        marginTop: 12,
        marginBottom: 12,
    },
    imageGridItem: {
    },
    imageDiv: {
        position: 'relative',
    },
    image: {
        display: 'block',
        maxWidth: '100%',
    },
    iconButton: {
        position: 'absolute',
        top: -6,
        right: -6,
    },
    checkbutton: {
        position: 'absolute',
        top: 6,
        right: 6,
    },
    icon: {
        fontSize: 36,
        borderRadius: 16,
        color: '#ffffff',
        backgroundColor: 'rgba(0,0,0,0.15)',
    },
    text: {
        color: '#f44336',
        marginTop: 12,
        marginBottom: 12,
    },
}));

class ImagePreview extends PureComponent {
    render() {
        const { images, imageMessages, classes, onDeleteImage, isVideoUpload } = this.props;
        const imageElements = [];
        for (let index = 0; index < images.length; index += 1) {
            const image = images[index];
            const buttonElement = image.submitted ?
                <div className={classes.checkbutton}>
                    <CheckCircleOutlineIcon className={classes.icon} />
                </div> :
                <IconButton
                    className={classes.iconButton}
                    onClick={() => {
                        onDeleteImage(index);
                    }}
                >
                    <HighlightOffIcon className={classes.icon} />
                    <InvisibleImg alt={'TODO'} />
                </IconButton>
                ;
            if(isVideoUpload) {
                imageElements.push(
                    <Grid
                        key={image.filename + '-image-' + index}
                        className={classes.imageGridItem}
                        item
                        xs={12}
                        sm={4}
                    >
                        <div className={classes.imageDiv}>
                            <video
                                className={classes.image}
                                alt={image.filename}
                            >
                                <source
                                    src={image.data}
                                    type="video/mp4"
                                />
                            </video>
                            {buttonElement}
                        </div>
                    </Grid>
                );
            } else {
                imageElements.push(
                    <Grid
                        key={image.filename + '-image-' + index}
                        className={classes.imageGridItem}
                        item
                        xs={6}
                        sm={4}
                    >
                        <div className={classes.imageDiv}>
                            <img
                                className={classes.image}
                                src={image.data}
                                alt={image.filename}
                            />
                            {buttonElement}
                        </div>
                    </Grid>
                );

            }
        }
        const imageElement = imageElements.length > 0 ?
            <Grid
                className={classes.imageGrid}
                container
                alignItems='flex-start'
                spacing={2}
            >
                {imageElements}
            </Grid> : null;
        const textElements = [];
        for (let imageMessage of imageMessages) {
            textElements.push(
                <Typography
                    key={imageMessage}
                    variant='body1'
                    component='div'
                    classes={{ root: classes.text, }}
                >
                    {imageMessage}
                </Typography>
            );
        }
        return (
            <Fragment>
                {imageElement}
                {textElements}
            </Fragment>
        );
    }
}

ImagePreview.propTypes = {
    images: PropTypes.arrayOf(PropTypes.object).isRequired,
    imageMessages: PropTypes.arrayOf(PropTypes.string).isRequired,
    onDeleteImage: PropTypes.func.isRequired,
    isVideoUpload: PropTypes.bool,
};

export default function _ImagePreview(props) {
    const classes = useStyles();
    return <ImagePreview {...props} classes={classes} />;
}
