import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from '@material-ui/core/styles';

import VideoCardContent from '../cards/VideoCardContent';

const useStyles = makeStyles((theme) => ({
    item: {
        float: 'left',
        position: 'relative',
        transitionProperty: 'transform',
        backgroundColor: '#000000',
    },
}));

class CarouselVideoItem extends PureComponent {
    render() {
        const {
            classes, data, manager, sliderItem,
            videoQuality, videoQualityCallback,
            fullscreenVideo, fullscreenVideoCallback,
            duplicate, userLang,
        } = this.props;
        return (
            <div className={classes.item}>
                <VideoCardContent
                    data={data}
                    manager={manager}
                    sliderItem={sliderItem}
                    videoQuality={videoQuality}
                    videoQualityCallback={videoQualityCallback}
                    fullscreenVideo={fullscreenVideo}
                    fullscreenVideoCallback={fullscreenVideoCallback}
                    playsInline={false}
                    duplicate={duplicate}
                    userLang={userLang}
                />
            </div>
        );
    }
}

CarouselVideoItem.propTypes = {
    data: PropTypes.object.isRequired,
    manager: PropTypes.object,
    sliderItem: PropTypes.number,
    videoQuality: PropTypes.number,
    videoQualityCallback: PropTypes.func,
    fullscreenVideo: PropTypes.bool,
    fullscreenVideoCallback: PropTypes.func,
    duplicate: PropTypes.bool,
    userLang: PropTypes.string,
};

export default function _CarouselVideoItem(props) {
    const classes = useStyles();
    return <CarouselVideoItem {...props} classes={classes} />;
}
