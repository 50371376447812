import React from "react";

import Grid from "@material-ui/core/Grid";

import ImageSlider from '../carousel/ImageSlider';

export default class BannerSliderBuilder {
    static build(cards, pos, userLang, manager, duplicates, maxElements,
        bgUrl) {
        const single =
            <Grid item xs={12} key={'gridpos' + pos + 'single' + userLang}>
                <ImageSlider
                    cards={cards}
                    duplicates={duplicates}
                    autoPlay={true}
                    userLang={userLang}
                    manager={manager}
                    key={'gridposslider' + pos + 'single' + userLang}
                    keyValue={'gridpossliderca' + pos + 'single' + userLang}
                    maxElements={maxElements}
                    bgUrl={bgUrl}
                    />
            </Grid>;
        const double =
        <Grid item xs={6} key={'gridpos' + pos + 'double' + userLang}>
                <ImageSlider
                    cards={cards}
                    duplicates={duplicates}
                    autoPlay={true}
                    userLang={userLang}
                    manager={manager}
                    key={'gridposslider' + pos + 'double' + userLang}
                    keyValue={'gridpossliderca' + pos + 'double' + userLang}
                    maxElements={maxElements}
                    bgUrl={bgUrl}
                />
            </Grid>;
        return [single, double];
    }
}
