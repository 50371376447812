export default class Columns {
    constructor() {
        this.arraySingle = [];
        this.arrayDouble = [];
    }

    addSlider(slider) {
        this.addToBoth(slider[0], slider[1]);
    }

    addFull(item) {
        this.addToBoth(item[0], item[1]);
    }

    finish() {
    }

    getSingle() {
        return this.arraySingle;
    }

    getDouble() {
        return this.arrayDouble;
    }

    // end of interface.


    addToBoth(itemSingle, itemDouble) {
        this.addToSingle(itemSingle);
        this.addToDouble(itemDouble);
    }

    addToSingle(item) {
        this.arraySingle.push(item);
    }

    addToDouble(item) {
        this.arrayDouble.push(item);
    }
}
