import React, { PureComponent } from 'react';
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';

import ShareFacebookButton from './ShareFacebookButton';
import ShareTwitterButton from './ShareTwitterButton';
import ShareWhatsappButton from './ShareWhatsappButton';
import ShareEmailButton from './ShareEmailButton';
import ShareClipboardButton from './ShareClipboardButton';
import L from "../util/L10n";

const useStyles = makeStyles((theme) => ({
    title: {
        color: '#000000',
        marginBottom: 3,
    },
    divider: {
        height: 2,
        backgroundColor: '#f3f1f3',
        marginBottom: 8,
    },
}));

class ShareCard extends PureComponent {
    render() {
        const { classes, userLang, entry } = this.props;
        const title = L.t('shareTitle', userLang);
        return (
            <div>
                <Typography
                    variant='subtitle2'
                    component='h2'
                    classes={{ root: classes.title }}
                >
                    {title}
                </Typography>
                <Divider
                    classes={{ root: classes.divider }}
                />
                <ShareFacebookButton
                    entry={entry}
                />
                <ShareTwitterButton
                    entry={entry}
                />
                <ShareWhatsappButton
                    entry={entry}
                />
                <ShareEmailButton
                    entry={entry}
                />
                <ShareClipboardButton
                    entry={entry}
                />
            </div>
        );
    }
}

ShareCard.propTypes = {
    userLang: PropTypes.string,
    entry: PropTypes.object,
};

export default function _ShareCard(props) {
    const classes = useStyles();
    return <ShareCard {...props} classes={classes} />;
}
